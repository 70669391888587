import Password from "../../input/Password";
import { useEffect, useRef } from "react";

const PasswordTemplate = ({ updateFields, passwordData, confirmData, disabled, passwordError, confirmError, required, setError, t }) => {
    const charactersRef = useRef(null);
    const lowercaseRef = useRef(null);
    const uppercaseRef = useRef(null);
    const specialRef = useRef(null);
    const numberRef = useRef(null);
    const mostCharactersRef = useRef(null);
    const handleChecker = ( value ) => {
        reachMostCharacters(value);
        hasMinimumCharacters(value);
        hasUppercaseCharacter(value);
        hasLowercaseCharacter(value);
        hasNumber(value);
        hasSpecialCharacter(value);
    }


    useEffect(() => {
        handleChecker(passwordData)
    }, [passwordData])
    function hasMinimumCharacters(value) {
        if (value && value.length >= 8) {
            charactersRef.current.classList.add('active')
        } else {
            charactersRef.current.classList.remove('active')
        }
    }

    function reachMostCharacters(value) {
        if (value && value.length > 20) {
            mostCharactersRef.current.classList.add('warning')
        } else {
            if (value && value.length >= 1) {
                mostCharactersRef.current.classList.add('active')
            }
            mostCharactersRef.current.classList.remove('warning')

        }
    }

    function hasUppercaseCharacter(value) {
        if (value && /[A-Z]/.test(value)) {
            uppercaseRef.current.classList.add('active')
        } else {
            uppercaseRef.current.classList.remove('active')
        }
    }

    function hasLowercaseCharacter(value) {
        if (value && /[a-z]/.test(value)) {
            lowercaseRef.current.classList.add('active')
        } else {
            lowercaseRef.current.classList.remove('active')
        }
    }

    function hasNumber(value) {
        if (value && /[0-9]/.test(value)) {
            numberRef.current.classList.add('active')
        } else {
            numberRef.current.classList.remove('active')
        }
    }

    function hasSpecialCharacter(value) {
        if (value && /[!@#$%^&*]/.test(value)) {
            specialRef.current.classList.add('active')
        } else {
            specialRef.current.classList.remove('active')
        }
    }

    const checker = [
        {
            title: "At least 8 characters",
            ref: charactersRef,
        },
        {
            title: "At most 20 characters",
            ref: mostCharactersRef,
        },
        {
            title: "Uppercase character",
            ref: uppercaseRef,
        },
        {
            title: "Lowercase character",
            ref: lowercaseRef,
        },
        {
            title: "Number (0-9)",
            ref: numberRef,
        },
        {
            title: "Special character !@#$%^&*()",
            ref: specialRef,
        },

    ]
    return (
        <>
            <Password name='password' inputLabel='Password' action={handleChecker} updateFields={updateFields} data={passwordData} disabled={disabled} error={passwordError} required={required} setError={setError} t={t}/>
            {!disabled &&
                <>
                    <div className='flex-col md:flex-row password-checker flex flex-wrap'>
                        {checker && checker.map((data, index) => {
                            return (
                                <div className="flex gap-x-sm items-center md:w-1/2 mb-x-sm" key={index}>
                                    <span className="checker-light bg-200 rounded-full w-sm h-sm block" ref={data.ref}></span>
                                    <span className="checker-title caption1 text-caption block mb-0">{t(data.title)}</span>
                                </div>
                            )
                        })}
                    </div>
                    <Password name='password2' inputLabel='Confirm Password' updateFields={updateFields} data={confirmData} disabled={disabled} error={confirmError} required={required} setError={setError} t={t}/>
                </>
            }
        </>
    )
}

export default PasswordTemplate;