import React, { useEffect, useRef, useState } from "react";
import CheckboxCateSelect from "../input/portal/CheckboxCateSelect";
import MySwitch from "../input/Switch";
import WhiteInput from "../input/portal/WhiteInput";
import WhiteStatueSelect from "../input/portal/WhiteStatueSelect";
import CateSelect2 from "../input/portal/CateSelect2";
import TuneIcon from "@mui/icons-material/Tune";
import SearchBy from "../search/SearchBy";
import { WhiteChips } from "../tags/Chips";
import { SearchButton, AdvanceButton } from "../button/SingleButton";
import SubmitAndFetch from "../function/SubmitAndFetch";
import { useTranslation } from "react-i18next";

const searchByOptions = [
  {
    value: 1,
    label: "Assessment No.",
  },
  {
    value: 2,
    label: "Tenement Details",
  },
  {
    value: 3,
    label: "% Change in RV",
  },
];

const AssessmentTenementRVSearch = ({ props, optionsList, cateId }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [showAdvance, setShowAdvance] = useState(false);
  const [searchBy, setSearchBy] = useState(null);
  const [filtersData, setFiltersData] = useState({});
  const occupationOption = props
    ? props?.filterOption?.statusOccupationList
    : [];
  const physicalOption = props ? props?.filterOption?.statusPhysicalList : [];
  const { postForm, loading } = SubmitAndFetch({ lng: language, t });
  const searchData = props?.searchData;

  const inputProps = (name, placeholder) => ({
    name,
    placeholder,
    data: searchData ? searchData[name] : "",
    updateFields: props?.updateFields,
    t,
    setError: props?.setError,
  });

  // useEffect(() => {
  //   if (props && props?.setSearchData === "function") {
  //     props?.setSearchData(searchProposalData);
  //   }
  // }, [searchProposalData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form_data = new FormData(event.target);
    const formObject = Object.fromEntries(form_data.entries());
    const filteredObject = Object.fromEntries(
      Object.entries(formObject).filter(
        ([key, value]) =>
          ![
            "property_category_id",
            "search_by",
            "status",
            "is_include_sub_category",
            "category_id",
          ].includes(key)
      )
    );
    setFiltersData((prevState) => ({ ...prevState, ...filteredObject }));
    props?.setIsFetching(true);
  };

  const handleReset = () => {
    setSearchBy(null);
    props?.setSearchData((prevState) => ({
      ...prevState,
      assessment_no: "",
      tenement_building: "",
      tenement_street: "",
      tenement_street_no: "",
      tenement_block: "",
      tenement_floor: "",
      change_rv_min: "",
      change_rv_max: "",
    }));
    setFiltersData({});
    setShowAdvance(false);
    props?.setIsFetching(true);
  };

  const checkShowFilter = () => {
    const haveData = Object.values(filtersData).some((value) => value !== "");
    if (
      haveData ||
      searchData?.status_occupation ||
      searchData?.status_physical
    )
      return true;
  };

  const IncludesSubCate = () => {
    return (
      <div className="default_input flex gap-md w-full items-center justify-between min-w-[330px]">
        <span className="label1 text-body">Includes Sub-categories</span>
        <MySwitch
          checked={props?.searchData.is_include_sub_category}
          name="is_include_sub_category"
          action={() => props?.setIsFetching(true)}
          updateFields={props?.updateFields}
        />
      </div>
    );
  };

  const assessmentNo = (action = null, search_data = null) => {
    return (
      <div className="flex flex-col md:flex-row items-center gap-x-sm">
        <WhiteInput {...inputProps("assessment_no", "Assessment No.")} />
        <SearchButton
          t={t}
          action={action}
          search_data={search_data}
          reset={handleReset}
        />
      </div>
    );
  };

  const tenementDeails = (action = null, search_data = null) => {
    return (
      <>
        <div className="flex flex-col md:flex-row gap-x-sm">
          <div className="flex-1 w-full">
            <WhiteInput
              {...inputProps("tenement_building", "Development/Building Name")}
            />
          </div>
          <div className="flex-1 w-full">
            <WhiteInput
              {...inputProps("tenement_street", "AStreet Name/Village Name")}
            />
          </div>
          <div className="flex-1 w-full">
            <WhiteInput {...inputProps("tenement_street_no", "Street No.")} />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-x-sm">
          <div className="flex-1 w-full">
            <WhiteInput
              {...inputProps("tenement_block", "Block Name/ID/No.")}
            />
          </div>
          <div className="flex-1 w-full">
            <WhiteInput {...inputProps("tenement_floor", "Floor")} />
          </div>
          <SearchButton
            t={t}
            action={action}
            search_data={search_data}
            reset={handleReset}
          />
        </div>
      </>
    );
  };
  const changeInRV = (action = null, search_data = null) => {
    return (
      <>
        <div className="flex flex-col md:flex-row gap-x-sm items-center">
          <div className="flex-1 w-full">
            <WhiteInput
              {...inputProps("change_rv_min", "Minimum Value")}
              haveicon="%"
              type="number"
              max="100"
            />
          </div>
          <span className="body2 text-body">to</span>
          <div className="flex-1 w-full">
            <WhiteInput
              {...inputProps("change_rv_max", "Maximum Value")}
              haveicon="%"
              type="number"
              max="100"
            />
          </div>
          <SearchButton
            t={t}
            action={action}
            search_data={search_data}
            reset={handleReset}
          />
        </div>
      </>
    );
  };

  const searchByList = (resetData) => {
    return (
      <SearchBy
        t={t}
        setSearchBy={setSearchBy}
        searchByOptions={searchByOptions}
        data={searchBy}
        resetData={resetData}
        title="You can search the properties by entering the assessment no., tenement description, or the percentage change in their rateable values (RV)"
      />
    );
  };

  const allPropertiseTools = () => {
    return (
      <form
        className="flex flex-col gap-x-big"
        onSubmit={handleSubmit}
        id="search-form"
      >
        <div className="flex gap-sm w-full">
          <div className="flex-1">
            <CheckboxCateSelect
              name="categories"
              options={props?.filterOption.categoryList}
              props={props}
            />
          </div>
          <div className="flex-1">
            <WhiteStatueSelect props={props} upAndLowMenu={true} />
          </div>
          <div className="w-fit">{IncludesSubCate()}</div>
        </div>
        <div className="flex gap-x-sm items-center w-full">
          {searchByList()}
        </div>
        {searchBy === 1 && assessmentNo()}
        {searchBy === 2 && tenementDeails()}
        {searchBy === 3 && changeInRV()}
        {checkShowFilter() ? selectedFilter() : null}
      </form>
    );
  };

  const selectedFilter = () => {
    return (
      <div className="flex flex-wrap gap-sm caption1 text-body items-center">
        <span className="label1 text-body">Selected filters: </span>
        {searchData?.status_occupation && (
          <WhiteChips
            dataKey="status_occupation"
            label={
              occupationOption.find(
                (option) => option.id === searchData.status_occupation
              )?.[`name${props?.lang}`]
            }
            data={searchData}
            setState={props?.setSearchData}
            updateFields={props?.updateFields}
            action={() => props?.setIsFetching(true)}
          />
        )}

        {searchData?.status_physical && (
          <WhiteChips
            dataKey="status_physical"
            label={
              physicalOption.find(
                (option) => option.id === searchData.status_physical
              )?.[`name${props?.lang}`]
            }
            data={searchData}
            setState={props?.setSearchData}
            updateFields={props?.updateFields}
            action={() => props?.setIsFetching(true)}
          />
        )}

        {Object.keys(filtersData).map((key, index) => {
          if (
            key !== "is_include_sub_category" &&
            key !== "categories" &&
            filtersData[key]
          ) {
            return (
              <WhiteChips
                dataKey={key}
                label={filtersData[key]}
                data={filtersData}
                setState={setFiltersData}
                updateFields={props?.updateFields}
                action={() => props?.setIsFetching(true)}
              />
            );
          }
        })}
      </div>
    );
  };

  const popertiseTools = () => {
    return (
      <form
        className="flex flex-col gap-x-big"
        onSubmit={handleSubmit}
        id="search-form"
      >
        <CateSelect2
          cateId={cateId}
          categories_options={props?.filterOption?.categoryList || []}
          changeCateAction={props?.changeCateAction}
        />
        <div className="flex-col md:flex-row flex gap-sm w-full">
          <div className="w-full md:w-2/4">
            <WhiteStatueSelect props={props} upAndLowMenu={true} />
          </div>
          <div className="flex-1">{IncludesSubCate()}</div>
          <div className="md:w-fit">
            <AdvanceButton t={t} setShowAdvance={setShowAdvance} />
          </div>
        </div>
        {showAdvance && (
          <>
            <div className="flex gap-x-sm items-center w-full">
              {searchByList()}
            </div>
            {searchBy === 1 && assessmentNo()}
            {searchBy === 2 && tenementDeails()}
            {searchBy === 3 && changeInRV()}
          </>
        )}
        {checkShowFilter() ? selectedFilter() : null}
      </form>
    );
  };

  const proposalSubmissionSearchBy = (action, search_data) => {
    return (
      <>
        <div className="flex gap-x-sm items-center w-full">
          {searchByList(props?.setSearchData)}
        </div>
        {searchBy === 1 && assessmentNo(action, search_data)}
        {searchBy === 2 && tenementDeails(action, search_data)}
        {searchBy === 3 && changeInRV(action, search_data)}
      </>
    );
  };

  return {
    allPropertiseTools,
    popertiseTools,
    proposalSubmissionSearchBy,
  };
};

export default AssessmentTenementRVSearch;
