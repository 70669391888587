import { useState } from "react";

const VerifyDisableChangeFunction = () => {
    const [emailVerifyActive, setEmailVerifyActive] = useState(false);
    const [mobileVerifyActive, setMobileVerifyActive] = useState(false);
    const [altEmailVerifyActive, setAltEmailVerifyActive] = useState(false);

    const handleEmailVerifyActive = (value) => {
        if (value.trim().length) {
            setEmailVerifyActive(true);
        } else {
            setEmailVerifyActive(false);
        }
    }
    const handleAltEmailVerifyActive = (value) => {
        if (value.trim().length) {
            setAltEmailVerifyActive(true);
        } else {
            setAltEmailVerifyActive(false);
        }
    }
    const handleMobileVerifyActive = (value) => {
        if (value.trim().length) {
            setMobileVerifyActive(true);
        } else {
            setMobileVerifyActive(false);
        }
    }

    return{
        handleEmailVerifyActive,
        handleMobileVerifyActive,
        handleAltEmailVerifyActive,
        emailVerifyActive,
        mobileVerifyActive,
        altEmailVerifyActive,
    }
}

export default VerifyDisableChangeFunction;