import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import changeStepForms from "../../components/step/ChangeStepForms";
import StepOne from "../../components/proposal-submission-form/stepOne";
import StepTwo from "../../components/proposal-submission-form/stepTwo";
import { ScrollToError } from "../../assets/js/global";
import StepFormTemplate from "../../components/form/StepFormTemplate";
import SubmissionTemplate from "../../components/proposal-submission-form/Template";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import LoadingUI from "../../components/loading/LoadingUI";
import Breadcrumb from "../../components/header/Breadcrumb";
import SmallFunction from "../../components/function/SmallFunction";
import useDocumentTitle from "../../hooks/useDocument";
const stepsLabel = ["Property Selection", "Capacity and Proposal Grounds"];

const ProposalSubmissionPage = ({ lng }) => {
  const { t } = useTranslation();
  const { userLoading } = useUserLoginContext();
  const [selectCate, setSelectCate] = useState("");
  const { sweetFailAlert } = SmallFunction();
  const [selectUploadCate, setSelectUploadCate] = useState("");
  const [uploadedFile, setUploadFile] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [stepTwoData, setStepTwoData] = useState({});
  const [stepTwoOptions, setStepTwoOptions] = useState({});
  const [error, setError] = useState([]);
  const [searchData, setSearchData] = useState([]);
  useDocumentTitle("Proposal Submission")

  useEffect(() => {
    if (error) {
      ScrollToError();
    }
  }, [error]);

  const createFormData = () => {
    if (currentStepIndex === 0) {
      return { category_id: selectCate };
    }
    return { category_id: selectCate, ...stepTwoData };
  };

  const failAction = (resData) => {
    if (resData.code === 202) {
      if (resData?.data?.invalidPropertyList?.length) {
        const assessmentNoList = resData?.data?.invalidPropertyList?.map(
          (item) => item.assessmentNo
        );
        const message = `The following assessment numbers are invalid: <br/> <br/>${assessmentNoList.join(
          ", <br/>"
        )}.<br/><br/>${resData.message}`;
        const failData = {
          message: message,
        };
        sweetFailAlert(failData, t, 100000);
      }
    } else {
      if (resData?.errors) {
        setError(resData?.errors);
      } else {
        sweetFailAlert(resData, t);
      }
    }
  };

  function updateFields(fields, setState) {
    setState((prev) => ({ ...prev, ...fields }));
  }
  const updateUploadData = (fields) => updateFields(fields, setUploadFile);
  const updateStepTwoFormData = (fields) =>
    updateFields(fields, setStepTwoData);

  const stateProps = {
    selectCate,
    setSelectCate,
    setSelectUploadCate,
    selectUploadCate,
    searchData,
    setError,
    error,
    setSearchData,
    rowData,
    setRowData,
  };

  const inputProp = (name, label) => ({
    t,
    data: stepTwoData ? stepTwoData[name] : "",
    updateFields: updateStepTwoFormData,
    error: error ? error[name] : "",
    setError,
    name,
    inputLabel: label,
  });

  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    changeStepForms([
      <StepOne
        key="one"
        updateFields={updateUploadData}
        error={error}
        setError={setError}
        lng={lng}
        stateProps={stateProps}
        uploadedFile={uploadedFile}
        t={t}
      />,
      <StepTwo
        key="two"
        inputProp={inputProp}
        stepTwoOptions={stepTwoOptions}
        data={stepTwoData}
        setError={setError}
        updateStepTwoFormData={updateStepTwoFormData}
        t={t}
      />,
    ]);

  if (userLoading) return <LoadingUI />;
  return (
    <>
      <Breadcrumb lng={lng} />
      <SubmissionTemplate
        subtitle="Please noted that each proposal can submit 2,000 properties only. Each submission should use one capacity with the same proposal grounds, proposal alteration and occupation particulars for all selected properties."
        title={stepsLabel[currentStepIndex]}
        currentStepIndex={currentStepIndex}
        steps={steps}
        Headline="Proposal for Alteration of Valuation List or Government Rent Roll (FORM e-R20A)"
      >
        <StepFormTemplate
          setError={setError}
          formData={createFormData()}
          step={step}
          isLastStep={isLastStep}
          back={back}
          next={next}
          currentStepIndex={currentStepIndex}
          isFirstStep={isFirstStep}
          api_name="proposal/submit"
          back_path="/"
          type="proposal-submission"
          lng={lng}
          redirect_path="/transaction-history"
          updateStep2Fields={updateStepTwoFormData}
          successAction={setStepTwoOptions}
          failAction={failAction}
          disabledBtn={
            !rowData ||
            rowData?.list?.length === 0 ||
            rowData?.length === 0 ||
            Object.keys(rowData).length === 0
          }
          appendFile={currentStepIndex === 1 ? ["auth_letter"] : []}
        />
      </SubmissionTemplate>
    </>
  );
};
export default ProposalSubmissionPage;
