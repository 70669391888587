import React from "react";
import StatusTag from "../tags/StatusTag";
import RoleTag from "../tags/RoleTag";
const UserIconData = ({
  children,
  userData,
  showStatus,
  lang,
  loginDetails,
  icon,
  t,
}) => {
  let details = [];

  if (loginDetails) {
    details = [
      {
        label: "Last Login Time",
        data: userData?.last_login_time,
        borderTop: true,
      },
      {
        label: "Created Time",
        data: userData?.created_at,
        borderTop: true,
      },
    ];
  }

  return (
    <div className="bg-white flex gap-x-big flex-col profile-field w-full rounded-big">
      <div className="flex gap-x-big items-center">
        <div id="icon">
          {icon && <img src={icon} alt="icon" width={96} height={96} />}
        </div>
        {Object.entries(userData).length ? (
          <div className="flex flex-col gap-x-sm items-center md:items-start">
            <span className="subtitle text-title">{userData?.username}</span>
            <div className="flex gap-sm">
              <RoleTag
                role={userData?.accountType?.[`label${lang}`]}
                label={userData?.accountType?.[`label${lang}`]}
                color={userData?.accountType?.color}
              />
              {showStatus
                ? userData?.status && (
                    <StatusTag
                      status={userData?.status.value}
                      label={userData?.status[`label${lang}`]}
                      color={userData?.status.color}
                    />
                  )
                : null}
              {details &&
                details.map((item, index) => {
                  return (
                    <div
                      className={`flex items-center gap-big py-md justify-between md:justify-start max-w-full ${
                        item.borderTop
                          ? "border-t border-neutral-100 border-dashed"
                          : ""
                      }`}
                      key={index}
                    >
                      <div className="w-xx-huge md:w-240px body2 text-title">
                        {item.label}
                      </div>
                      <div className="text-body label1 break-words whitespace-normal text-end">
                        {item.data ?? "--"}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : null}
      </div>
      {children ? (
        <div className="flex flex-col gap-xx-sm">{children}</div>
      ) : null}
    </div>
  );
};

export default UserIconData;
