import PropertyTemplate from "../../components/property/Template";
import { useTranslation } from "react-i18next";
import WhiteSelect from "../../components/input/portal/WhiteSelect";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import { useState } from "react";
import {useUserLoginContext} from "../../context/UserLoginProvider";
import AssessmentAndTRNSearch from "../../components/search/AssessmentAndTRNSearch";
import React, { useEffect, useMemo } from "react";
import DataTable from "../../components/table/DataTable";
import { TableDownloadButton, PrimaryButton } from "../../components/button/SingleButton";
import JSZip from 'jszip';
import LoadingUI from "../../components/loading/LoadingUI";
import Breadcrumb from "../../components/header/Breadcrumb";
import useDocumentTitle from "../../hooks/useDocument";

const MyDocumentPage = ({ lng }) => {
    const [searchLoading, setSearchLoadingLoading] = useState(false);
    const [rows, setRows]  = useState(false);
    const [searchBy, setSearchBy] = useState(1);
    const [data,setData] = useState({});
    const [tableLoading, setTableLoading] = useState(true);
    const { t } = useTranslation();
    const { userLoading } = useUserLoginContext();
    const { postForm, loading } = SubmitAndFetch({lng, t});
    const [rowSelection, setRowSelection] = useState([]);
    useDocumentTitle("My Documents");

    const updateFields = (fields) => setData(prev => ({ ...prev, ...fields }));
    const handleSubmit = (event) => {
        event.preventDefault();
    }

    const handleSearchDocumentType = (value) => {

    }

    const handleBulkDownload = async () => {
        const selectedRows = Object.keys(rowSelection).map(Number);
        const selectedPaths = rows
            .filter(row => selectedRows.includes(row.id))
            .map(row => row.file);

        const zip = new JSZip();

        await Promise.all(
            selectedPaths.map(async (fileData, index) => {
                const response = await fetch(fileData.path);
                const blob = await response.blob();
                const fileName = fileData.name;

                zip.file(fileName, blob);
            })
        );

        const zipBlob = await zip.generateAsync({ type: 'blob' });

        const downloadLink = URL.createObjectURL(zipBlob);

        const link = document.createElement('a');
        link.href = downloadLink;
        link.download = 'my_documents.zip';
        link.click();

        URL.revokeObjectURL(downloadLink);
    };

    const cancelAction = () => {
        setData({});
    }

    const documentType = [
        {
            value: 99,
            label: 'All Document',
        },
        {
            value: 1,
            label: 'e-Acknowledgement',
        },
        {
            value: 2,
            label: 'e-Statement',
        },
        {
            value: 3,
            label: 'e-Notice',
        },
    ]

    const documentMenu = () => {
        const haveSelectData = Object.entries(rowSelection).length > 0;
        return (
            <div className='w-fit flex gap-sm'>
                <div className="w-[312px]">
                    <WhiteSelect name='type' action={handleSearchDocumentType} options={documentType} defaultValue={99} disabled={haveSelectData} />
                </div>
                {haveSelectData &&
                    <PrimaryButton t={t} label='Download All' action={handleBulkDownload} />
                }
            </div>
        )
    }

    const getTableData = () => {
        setTableLoading(true);
        // setRows( [
        //     {
        //         id: 1,
        //         subject: "Submission of e-XXX",
        //         created_at: '2024/01/01 09:41:45',
        //         file: {
        //             name: 'test.png',
        //             path: 'http://localhost:3000/_next/static/media/w3c_logo.5797cd67.png'
        //         }
        //     },
        //     {
        //         id: 2,
        //         subject: "Submission of e-XXX",
        //         created_at: '2024/01/01 09:41:45',
        //         file: {
        //             name: 'joanna.png',
        //             path: 'http://localhost:3000/_next/static/media/w3c_logo.5797cd67.png'
        //         }
        //     },
        // ]);

        setTableLoading(false);
    }

    useEffect(() => {
        getTableData();
    }, [])


    const columns = useMemo(
        () => [
            {
                accessorKey: 'created_at',
                header: 'Date',
                size: 300,
                grow: false,
                
            },
            {
                accessorKey: 'subject',
                header: 'Subject',
                minSize: 704,
                enableSorting: false,
                grow: true,
            },
            {
                accessorKey: 'button',
                header: '',
                size: 160,
                enableSorting: false,
                grow: false,
                Cell: ({ cell, row }) => (
                    <div className="flex justify-center items-center w-full">
                        <TableDownloadButton t={t} href={row.original.file.path} />
                    </div>
                ),
            },
        ], [rows]
    );

    if (userLoading) return <LoadingUI/>;

    return (
        <>
            <Breadcrumb lng={lng}/>
            <PropertyTemplate
                lng={lng}
                title="My Documents"
                button_bar={documentMenu}
            >
                <form className="flex-col flex gap-x-big" onSubmit={handleSubmit}>
                    <AssessmentAndTRNSearch searchBy={searchBy} t={t} loading={loading} setSearchBy={setSearchBy} data={data} updateFields={updateFields} cancelAction={cancelAction}/>
                    <DataTable columns={columns} data={rows} border={false} loading={tableLoading} enableSelection={true} setRowSelection={setRowSelection} rowSelection={rowSelection} selectAll={true}/>
                </form>

            </PropertyTemplate>
        </>
    )
}

export default MyDocumentPage;