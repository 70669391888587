import {Link, useLocation} from "react-router-dom";
import { ReactComponent as MyProfileSVG } from "../../assets/images/profile/my_profile.svg";
import { ReactComponent as PersonalisationSVG } from "../../assets/images/profile/Personalisation.svg";
import { ReactComponent as CorporateAccountsSVG } from "../../assets/images/profile/Corporate_Accounts.svg";
import { ReactComponent as AppointmentsSVG } from "../../assets/images/profile/Appointments.svg";
import USER_TYPE from "../../data/user_type.json";

const ProfileMenu = ({ lng, account_type, t }) => {
    const location = useLocation();
    let menuList = [];
    if (account_type === USER_TYPE.INDIVIDUAL) {
        menuList = [
            { img: <MyProfileSVG width={24} />, label: 'My Profile', link: '/my-profile' },
            { img: <PersonalisationSVG width={24} />, label: 'Personalisation', link: '/personalisation' }
        ];
    } else {
        menuList = [
            { img: <MyProfileSVG width={24} />, label: 'My Profile', link: '/my-profile' },
            account_type === USER_TYPE.CORPORATE ? {
                img: <CorporateAccountsSVG width={24} />,
                label: 'Corporate Accounts',
                link: '/corporate-accounts-management'
            } : null,
            { img: <AppointmentsSVG width={24} />, label: 'Appointments', link: '/appointment-management' },
            { img: <PersonalisationSVG width={24} />, label: 'Personalisation', link: '/personalisation' }
        ];
    }

    return (
        <div className="p-sm bg-surface-card h-full rounded-md hidden xl:block xl:min-w-[280px] overflow-hidden sticky top-[100px]">
            <ul className="flex flex-col label1 text-600">
                {menuList && menuList.map((item, index) => {
                    if (!item) return null;
                    const matchPath = location.pathname.includes('/' + lng + item.link);
                    return (
                        <li className="profile-menu " key={index}>
                            <Link to={`/${lng}${item.link}`}
                                  className={`p-big hover:bg-surface-secondary rounded-md flex gap-sm items-center ${matchPath ? 'text-surface-primary active' : ''} `}>
                                <span>{item.img}</span>
                                <span>{t(item.label)}</span>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default ProfileMenu;
