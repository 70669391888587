import { createContext, useContext, useState } from "react";

const ShowPopupContext = createContext();

const ShowPopupProvider = ({ children }) => {
    const [showPopup, setShowPopup] = useState(false);

    return <ShowPopupContext.Provider value={ [showPopup, setShowPopup] }>
        { children }
    </ShowPopupContext.Provider>
}

export const useShowPopupContext = () => {
    return useContext(ShowPopupContext);
}

export default ShowPopupProvider;