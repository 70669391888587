import CloseIcon from '@mui/icons-material/Close';
import image01 from '../../assets/images/home/image_01.png'
import { useCookies } from 'react-cookie'
import { useTranslation } from "react-i18next";
import { useShowPopupContext } from "../../context/ShowPopupProvider";
import { BlueForwardLink } from "../button/SingleButton";

const Popup = ({ lng }) => {
    const [showPopup, setShowPopup] = useShowPopupContext();
    const [, setCookie] = useCookies(['close-popup'])
    const { t } = useTranslation();
    const closePopup = () => {
        setShowPopup(false);
        setCookie('close-popup', true, { path: '/' });
    };

    return (
        <>
        {showPopup &&
            <>
                <div className="pop-up-container flex justify-center items-center w-full h-full fixed flex-col gap-md">
                    <div className="pop-up bg-white rounded-md w-full">
                        <div className="pop-up-img-container flex justify-center items-center bg-surface-secondary rounded-t-md">
                            <img src={image01} alt="text1" />
                        </div>
                        <div className="flex flex-col gap-x-big justify-center items-center px-md pt-x-big pb-lg lg:px-xxx-lg text-center">
                            <div className="subtitle text-600">
                                Hong Kong Property Review Monthly Supplement (August 2023) issued
                            </div>
                            <BlueForwardLink href='/' t={t} label="View"/>
                        </div>
                    </div>
                    <div className="close-pop-up-btn bg-white rounded-full p-x-sm cursor-pointer hover:bg-surface-hover" onClick={closePopup}>
                        <CloseIcon className="text-body"/>
                    </div>
                </div>
                <div className="pop-up-layout fixed w-full h-full" onClick={closePopup}></div>
            </>
        }
        </>
    )
}

export default Popup;