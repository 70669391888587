import FieldCard from "../../components/form/FieldCard";
import VerifyField from "../../components/input/VerifyField";

const StepTwo = ({ props, data, setError, api_name, slug, t }) => {
    const isAccountVerified = slug && (slug === 'password' || slug === 'activate') ? data?.['verification_code'] : slug && slug === 'username' ? data?.['username'] : false;
    const additionalData = slug && slug === 'username' ? {'number': data?.['number']} : slug && slug === 'password' ? data : false;
    const action = slug && slug === 'password' ? 'reset' : slug === 'username' ? 'view' : 'activate';
    const subtitle =
        isAccountVerified ? `Request has been verified, please click "Continue" to ${action} your ${slug === 'activate' ? 'account' : slug}.` :

            `The verification code is sent to your email, please enter the code to continue.`;
    return (
        <div className="gap-md flex flex-col">
            <FieldCard title="Enter Verification Code" subtitle={subtitle} t={t}>
                <VerifyField
                    {...props("verification_code", "Verification Code")}
                    show={true}
                    parentData={data?.["email"] ? {
                        'email': data?.["email"]
                    } : {
                        'mobile': data?.["mobile"] || '',
                        'country_code': data?.["country_code"] || ''
                    }}
                    uuid={data?.["uuid"]}
                    start_counting={data?.["uuid"] !== undefined}
                    additionalData={additionalData}
                    setError={setError }
                    api_name={api_name}
                    slug={slug}
                    t={t}
                />
            </FieldCard>
        </div>
    )
}

export default StepTwo;