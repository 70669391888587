import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import PropertyTemplate from "../../components/property/Template";
import PropertyDataTableTemplate from "../../components/property/PropertyDataTableTemplate";
import BasicTag from "../../components/tags/BasicTag";
import LoadingUI from "../../components/loading/LoadingUI";
import searchBarField from "../../data/search_bar_field.json";
import SmallFunction from "../../components/function/SmallFunction";
import Breadcrumb from "../../components/header/Breadcrumb";
import { ReactComponent as YesSVG } from "../../assets/images/others/yes.svg";
import { ReactComponent as NoSVG } from "../../assets/images/others/cancel.svg";
import { EditButton } from "../../components/button/SingleButton";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import { useNavigate, useParams } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocument";

const AllPropertyPage = ({ lng }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userLoading, account_type, token } = useUserLoginContext();
  const [rowData, setRowData] = useState([]);
  const [currentCategories, setCurrentCategories] = useState([]);
  const [selectStatus, setSelectStatus] = useState({});
  const [error, setError] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [filterOption, setFilterOption] = useState({});
  const [searchData, setSearchData] = useState(searchBarField);
  const [isFetching, setIsFetching] = useState(false);
  const { postForm } = SubmitAndFetch({ lng, t });
  const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
  useDocumentTitle("All Properties");

  useEffect(() => {
    const getFilterData = async () => {
      await postForm(
        "property/list/filter",
        null,
        null,
        setError,
        null,
        false,
        null,
        token,
        setFilterOption,
        null,
        null,
        false
      );
    };
    getFilterData();
  }, []);

  const updateFields = (fields, setState) =>
    setState((prev) => ({ ...prev, ...fields }));
  const updateSearchData = (fields) => updateFields(fields, setSearchData);

  const changeCateAction = (event) => {
    navigate(
      `/${lng}/property-list/view-property/${event.target.value || "0"}`
    );
    setIsFetching(true);
    setSearchData({
      ...searchBarField,
      is_include_sub_category: searchData.is_include_sub_category,
    });
    setSelectStatus({});
  };

  const stateProps = {
    currentCategories,
    setCurrentCategories,
    filterOption,
    setIsFetching,
    setRowData,
    lang,
    searchData,
    setError,
    error,
    setSearchData,
    action: (event) => setIsFetching(true),
    changeCateAction,
    updateFields: updateSearchData,
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "assessmentNo",
        header: "Assessment No.",
        minSize: 230,
        grow: false,
      },
      {
        accessorKey: "address",
        header: "Address or Description of Tenement",
        minSize: 440,
        maxSize: 440,
        enableSorting: false,
        grow: false,
      },
      // {
      //     accessorKey: 'proposal_submission',
      //     header: 'Proposal Submission Status ',
      //     minSize: 140,
      //     maxSize: 140,
      //     enableSorting: false,
      //     grow: false,
      //     Cell: ({ cell, row }) => (
      //         <div className="flex w-full">
      //             {cell.getValue() ? <YesSVG width={24}/> : <NoSVG width={24}/>}
      //         </div>
      //     ),
      // },
      {
        accessorKey: "changeRv",
        header: "% Change in RV",
        maxSize: 220,
        grow: false,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const numericValue = parseFloat(value);

          if (numericValue > 0) {
            return <span className="text-red-600">{value}</span>;
          }
          if (numericValue < 0) {
            return <span className="text-green-600">{value}</span>;
          }
          return <span className="text-neutral-600">{value}</span>;
        },
      },
      {
        accessorKey: "statusOccupation",
        header: "Status",
        minSize: 260,
        grow: true,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          let occupation = null;
          let physical = null;

          if (cell.getValue()) {
            occupation = cell.getValue();
          }

          if (row.original.statusPhysical) {
            physical = row.original.statusPhysical;
          }

          if (occupation || physical) {
            return (
              <div className="justify-between items-center flex me-10px w-full gap-md">
                <div className="gap-xx-sm flex-wrap flex">
                  {occupation && (
                    <span className="body2">
                      <BasicTag
                        key={occupation.value}
                        label={occupation?.[`label${lang}`]}
                        color={occupation.color}
                      />
                    </span>
                  )}
                  {physical && (
                    <span className="body2">
                      <BasicTag
                        key={physical.value}
                        label={physical?.[`label${lang}`]}
                        color={physical.color}
                      />
                    </span>
                  )}
                </div>
              </div>
            );
          } else {
            return "-";
          }
        },
      },
      {
        accessorKey: "button",
        header: "",
        maxSize: 104,
        grow: false,
        enableSorting: false,
        Cell: ({ cell, row }) => (
          <EditButton
            t={t}
            href={`/${lng}/property-list/view-property/edit/${row.original.id}`}
          />
        ),
      },
    ],
    [rowData]
  );

  if (userLoading) return <LoadingUI />;

  const tableDataProps = {
    columns,
    data: rowData.list,
    loading: tableLoading,
    apiProps: {
      isFetching,
      setIsFetching,
      setState: setRowData,
      data: rowData,
      api: "property/list",
      appandData: {
        category_id: [currentCategories],
        ...searchData,
      },
    },
    pageSize: 20,
    pageSizeOptions: [20, 50],
  };

  return (
    <>
      <Breadcrumb lng={lng} homeElement={"Home"} capitalizeLinks />
      <PropertyTemplate lng={lng} title="All Properties">
        <PropertyDataTableTemplate
          stateProps={stateProps}
          tableProps={tableDataProps}
        />
      </PropertyTemplate>
    </>
  );
};

export default AllPropertyPage;
