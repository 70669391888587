import React from 'react';
import StepThree from "../../components/registration_form/StepThree";
import StepFour from "../../components/registration_form/StepFour";
import StepFive from "../../components/registration_form/StepFive";

const StepSix = ({ data, stepThreeData, stepFiveData, active, lng, t }) => {
    return (
        <>
            <div className="gap-md flex flex-col stepSix">
                <StepThree
                    disabled={true}
                    data={stepThreeData}
                    step="final"
                    active={active}
                    lng={lng}
                    t={t}
                />
                <StepFour
                    disabled={true}
                    data={data}
                    step="final"
                    t={t}
                />
                <StepFive
                    disabled={true}
                    data={stepFiveData}
                    active={active}
                    account_type={stepThreeData['account_type']}
                    step="final"
                    t={t}
                />
            </div>
        </>
    );
};

export default StepSix;
