import React, { useState } from "react";
import FieldCard from "../../components/form/FieldCard";
import Switcher from "../../components/button/Switcher";
import Input from "../../components/input/Input";
import FileDropzone from "../../components/upload/FileDropzone";
import ErrorMessage from "../error/ErrorMessage";
import USER_TYPE from "../../data/user_type.json";
import UPLOAD_DOC_TYPE from "../../data/upload_doc_type.json";

const StepFive = ({
  active,
  updateFields,
  data,
  step,
  disabled,
  error,
  setError,
  account_type,
  t,
}) => {
  const [proofIndex, setProofIndex] = useState(data["identity_type"] || 1);
  const notFinal = step !== "final";
  const isFinal = step === "final";

  const InputProps = (name, label, required = false) => ({
    name,
    inputLabel: label,
    updateFields,
    data: data[name],
    disabled,
    error: error ? error[name] : "",
    required,
    setError,
    t,
  });

  // need remove
  const renderCorporateInfo = () =>
    account_type === USER_TYPE.CORPORATE && (
      <FieldCard title="Corporate Details" t={t}>
        <div>
          <Input
            {...InputProps(
              "corporate_brn",
              "Business Registration Certificate"
            )}
          />
          {error && <ErrorMessage error={error["corporate"]} t={t} />}
        </div>
        <Input
          {...InputProps(
            "corporate_name_en",
            "English Name of Corporate/Organisation"
          )}
        />
        {((isFinal && data["corporate_name_zh"]) || notFinal) && (
          <Input
            {...InputProps(
              "corporate_name_zh",
              "Chinese Name of Corporate/Organisation"
            )}
            optional={true}
          />
        )}
      </FieldCard>
    );
  const renderIdentityVerification = () => (
    <FieldCard title="Identification" t={t}>
      {notFinal && (
        <Switcher
          options={[
            { value: 1, label: "HKIC" },
            { value: 2, label: "Passport" },
            { value: 3, label: "Other Travel Document" },
          ]}
          name="identity_type"
          activeIndex={proofIndex}
          setActiveIndex={setProofIndex}
          updateFields={updateFields}
          data={data["identity_type"]}
          disabled={disabled}
          setError={error && setError}
          t={t}
        />
      )}

      {isFinal && (
        <Switcher
          options={[
            data["identity_type"] === 1
              ? { value: 1, label: "HKIC" }
              : data["identity_type"] === 2
              ? { value: 2, label: "Passport" }
              : { value: 3, label: "Other Travel Document" },
          ]}
          name="identity_type"
          activeIndex={proofIndex}
          disabled={disabled}
          data={data["identity_type"]}
          t={t}
        />
      )}

      {proofIndex === 1 && (
        <Input
          {...InputProps("identity_number", "Hong Kong Identity Card No.")}
        />
      )}

      {proofIndex === 2 && (
        <>
          <Input {...InputProps("identity_number", "Passport No.")} />
          <Input
            {...InputProps(
              "passport_issue_place",
              "Place of Issue for Passport"
            )}
          />
          <Input
            {...InputProps(
              "passport_issuance_authority",
              "Issuance authority of passport"
            )}
          />
        </>
      )}

      {proofIndex === 3 && (
        <>
          <Input
            {...InputProps("identity_number", "Other Travel Document No.")}
          />
          <Input
            {...InputProps(
              "other_issue_place",
              "Place of issue for Other Travel Document"
            )}
          />
        </>
      )}
    </FieldCard>
  );

  const renderIdentityProof = () => (
    <>
      <FieldCard
        title={
          account_type === USER_TYPE.INDIVIDUAL
            ? "Identity Proof"
            : "Business Registration Certificate"
        }
        t={t}
      >
        <FileDropzone
          name="identity_proof_file"
          updateFields={updateFields}
          data={data ? data?.["identity_proof_file"] : ""}
          disabled={disabled}
          error={error ? error?.["identity_proof_file"] : ""}
          type={UPLOAD_DOC_TYPE.IDENTITY_PROOF_DOCUMENT}
          setError={setError}
          t={t}
        />
        {account_type === USER_TYPE.INDIVIDUAL && active === 1 ? (
          <div style={{ marginTop: "-20px" }}>
            <ErrorMessage
              help_text="Only valid HK Smart Identity Card is accepted"
              t={t}
            />
          </div>
        ) : null}
      </FieldCard>
    </>
  );

  const authorisationTootip = () => {
    return (
      <span className="caption1 text-caption">
        {t("For more details please")}{" "}
        <a
          href="#"
          download
          className="text-surface-primary label2 hover:underline"
        >
          {t("download ") + t("Confirmation Letter") + t(" Sample")}
        </a>
      </span>
    );
  };

  const renderConfirmationLetter = () =>
    (isFinal && data["confirmation_letter_file"]) || notFinal ? (
      <FieldCard
        title="Company's Confirmation Letter"
        tooltip={authorisationTootip}
        t={t}
      >
        <FileDropzone
          name="confirmation_letter_file"
          updateFields={updateFields}
          data={data ? data["confirmation_letter_file"] : ""}
          disabled={disabled}
          error={error ? error["confirmation_letter_file"] : ""}
          type={UPLOAD_DOC_TYPE.AUTHORISATION_LETTER}
          setError={setError}
          t={t}
        />
      </FieldCard>
    ) : null;

  return (
    <>
      <div className="gap-md flex flex-col">
        <input name="account_type" value={account_type} type="hidden" />

        {/*need remove*/}
        {/* {account_type === USER_TYPE.INDIVIDUAL && renderIdentityVerification()} */}
        {/*{account_type === USER_TYPE.CORPORATE && renderCorporateInfo()}*/}
        {renderIdentityProof()}
        {/*need update*/}
        {account_type === USER_TYPE.CORPORATE && renderConfirmationLetter()}
      </div>
    </>
  );
};

export default StepFive;
