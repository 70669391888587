import React from "react";
import MyTooltip from "../../components/tooltip/Tooltip";

const FieldCard = ({ title=null, children, subtitle, tooltip, t }) => {
    return (
        <div className="field-card">
            {title &&
                <div className="label1 field-card-title">
                    <div className="flex gap-md items-center">
                        { t(title) }
                        {tooltip && <MyTooltip title={tooltip} w='300px' t={t}/>}
                    </div>
                    { subtitle && <div className="mt-x-sm body2 text-title">{ t(subtitle) }</div> }
                </div>
            }

            { children }
        </div>
    )
}

export default FieldCard;
