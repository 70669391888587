import ProfileMenu from "../../components/account_management/Menu";
import TextHeadline from "../../components/TextHeadline";
import { useTranslation } from "react-i18next";

const AccountManagementTemplate = ({ children, lng, accountType, title, button_bar, noMenu=false, subtitle }) => {
    const { t } = useTranslation();
    return (
        <div className="layout-container content-container">
            <div className="flex gap-xx-big flex-col md:flex-row w-full">
                {!noMenu && <ProfileMenu lng={lng} account_type={accountType} t={t}/>}
                <div className='flex flex-col gap-xx-big w-full overflow-hidden'>
                    <div className={`flex items-center justify-between ${button_bar ? 'gap-md' : ''}`}>
                        <div className="flex flex-col gap-x-sm">
                            <TextHeadline title={t(title)} />
                            {subtitle && <span className="text-caption label1">{typeof subtitle !== "object" ? t(subtitle) : subtitle}</span>}
                        </div>
                        {button_bar ? button_bar : null}
                    </div>
                    <div className="flex-1 overflow-auto">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountManagementTemplate;
