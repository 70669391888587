import React, { useState, useRef, useEffect} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import { ReactComponent as Pause } from '../../assets/images/swiper/pause.svg'
import { ReactComponent as Start } from '../../assets/images/swiper/start.svg'
import { Pagination, Autoplay } from 'swiper/modules';
import { useTranslation } from "react-i18next";
import { LoadingImageSkeleton } from "../loading/LoadingSkeleton";
import EmptyDataText from "../empty/EmptyDataText";
import { Link } from "react-router-dom";

const HomeSwiper = ({ lng, data, loading }) => {
    const { t } = useTranslation();
    const whatNewSwiper = useRef("");
    const [isPause, setIsPuase] = useState(false);

    useEffect(() => {
        if (data && data.length) {
            if (isPause) {
                whatNewSwiper.current.swiper.autoplay.stop()
            } else {
                whatNewSwiper.current.swiper.autoplay.start();
            }
        }
    }, [isPause, data]);

    const handlePrev = () => {
        if (!whatNewSwiper.current) return;
        whatNewSwiper.current.swiper.slidePrev();
    };

    const handleNext = () => {
        if (!whatNewSwiper.current) return;
        whatNewSwiper.current.swiper.slideNext();
    };

    return (
        <>
        <div className="home-card-announcements-title flex justify-between items-center flex-wrap gap-big">
            <h3 className="h3 text-neutral-800">{t('Announcements')}</h3>
            {data && data.length ? <div className="home-card-tool">
                <button id="arrow_left" title={t("Previous")} onClick={handlePrev} className="hover:bg-surface-hover">
                    <div className="triangle-left"></div>
                </button>
                <button id="arrow_right" title={t("Next")} onClick={handleNext} className="hover:bg-surface-hover">
                    <div className="triangle-right"></div>
                </button>
                {isPause ?
                    <button id="pause" title={t("Play")}
                            className="w-16 bg-surface-primary hover:bg-surface-primary-hover"
                            onClick={() => setIsPuase(!isPause)}>
                        <Start className="h-2"/>
                    </button>
                    :
                    <button id="pause" title={t("Pause")}
                            className="w-16 bg-surface-primary hover:bg-surface-primary-hover"
                            onClick={() => setIsPuase(!isPause)}>
                        <Pause className="h-2"/>
                    </button>
                }
            </div> : null}
        </div>
            {data && data.length ?
                <Swiper
                modules={[Pagination, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                slidesPerGroup={1}
                loop={true}
                autoplay={{
                    delay: 5000,
                }}
                pagination={{
                    clickable: true,
                    el: "#home-card-announcements-pagination",
                    type: "bullets",
                    bulletClass: "swiper-announcements-pagination",
                    bulletActiveClass: "swiper-announcements-pagination-active",
                }}
                ref={whatNewSwiper}
                className="bg-neutral-50 my-big"
            >
                {loading ? <LoadingImageSkeleton h={'80%'} w={'100%'} containerClassName={'image-container'}/> :
                    data && data.map((ann, index) => {
                        const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
                        return (
                            <SwiperSlide key={index}>
                                <Link to="/">
                                    <div className="image-container">
                                        <img src={ann?.[`thumbnail${lang}`]} alt={ann?.[`title${lang}`]} width="100"
                                             height="100"/>
                                    </div>
                                    <p className="label1 min-h-10">{ann?.[`title${lang}`]}</p>
                                </Link>
                            </SwiperSlide>
                        )
                    })}
            </Swiper>
                : <EmptyDataText text={t('No Announcements Available')} />
            }
        <div id="home-card-announcements-pagination" className="flex justify-center gap-x-sm"></div>
        </>
    );
};

export default HomeSwiper;