import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormControl } from "@mui/material";
import dayjs from "dayjs";
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import ErrorMessage from "../../error/ErrorMessage";
const WhiteDatePicker = ({
  name,
  inputLabel,
  action,
  updateFields,
  data,
  disabled,
  error,
  required,
  t,
  format,
}) => {
  const [value, setValue] = useState(data || "");

  const handleChange = (newValue) => {
    let dateValue = "";
    if (newValue) {
      dateValue = newValue.format(format ? format : "YYYY-MM-DD");
    }
    setValue(newValue);
    if (action) action(dateValue, setValue);
    if (updateFields) updateFields({ [name]: dateValue });
  };

  useEffect(() => {
    if (!data) setValue(null);
  }, [data]);

  return (
    <FormControl className="w-full">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          className="body1 text-body w-full"
          name={name}
          label={inputLabel}
          defaultValue={dayjs(data)}
          value={dayjs(value || data)}
          format="YYYY/MM/DD"
          slots={{
            openPickerIcon: CalendarMonthSharpIcon,
          }}
          onChange={handleChange}
          disableOpenPicker={disabled}
          disabled={disabled}
          slotProps={{
            day: {
              sx: {
                "&:hover": {
                  backgroundColor: "var(--surface-secondary)",
                },
                "&:focus": {
                  backgroundColor: "var(--surface-secondary)",
                },
                "&.Mui-selected": {
                  backgroundColor: "var(--surface-primary) !important",
                  color: "white",
                },
                "&.MuiPickersDay-today": {
                  backgroundColor: "var(--surface-secondary)",
                },
              },
            },
            yearButton: {
              sx: {
                "&:hover": {
                  backgroundColor: "var(--surface-secondary)",
                },
                "&:focus": {
                  backgroundColor: "var(--surface-secondary)",
                },
                "&.Mui-selected": {
                  backgroundColor: "var(--surface-primary) !important",
                  color: "white",
                },
                "&.MuiPickersDay-today": {
                  backgroundColor: "var(--surface-secondary)",
                },
              },
            },
            textField: {
              className: "input bg-white",
              error: !!error,
              sx: {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "var(--border-default)",
                  },
                  "&:hover fieldset": {
                    borderColor: "var(--border-default)",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid var(--surface-primary)",
                  },
                },

                "& .MuiInputBase-root": {
                  background: "var(--surface-card)",
                  height: "48px",
                  borderRadius: "var(--radius-sm)",
                },
              },
            },
          }}
        />
      </LocalizationProvider>
      <ErrorMessage error={error} t={t} />
    </FormControl>
  );
};

export default WhiteDatePicker;
