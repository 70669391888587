import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import USER_TYPE from "../../data/user_type.json";
import LoadingUI from "../../components/loading/LoadingUI";
import PropertyTemplate from "../../components/property/Template";
import CategoryTable from "../../components/table/CategoryTable";
import MyTooltip from "../../components/tooltip/Tooltip";
import Popup from "../../components/popup/Popup";
import Input from "../../components/input/Input";
import Breadcrumb from "../../components/header/Breadcrumb";
import {
  MRT_TableBodyCellValue,
  flexRender,
  useMaterialReactTable,
} from "material-react-table";
import TableModelMenu from "../../components/menu/TableModelMenu";
import { ReactComponent as SearchSVG } from "../../assets/images/others/search_white.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import { EditButton, PrimaryLink } from "../../components/button/SingleButton";
import ErrorMessage from "../../components/error/ErrorMessage";
import PERMISSIONS from "../../data/property_permission.json";
import useDocumentTitle from "../../hooks/useDocument";

const no_permission = PERMISSIONS.NO_PERMISSION;
const full_access = PERMISSIONS.FULL_ACCESS;
const display_only = PERMISSIONS.DISPLAY_ONLY;
const editable = PERMISSIONS.EDITABLE;
const view_only = PERMISSIONS.VIEW_ONLY;
const restricted = PERMISSIONS.RESTRICTED;
const shared = PERMISSIONS.SHARED;

const PropertyList = ({ lng }) => {
  const { t } = useTranslation();
  const { userLoading, account_type, token } = useUserLoginContext();
  const { postForm, loading } = SubmitAndFetch({ lng, t });
  const [listTableLoading, setListTableLoading] = useState(true);
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [showAliasPopup, setShowAliasPopup] = useState(false);
  const [showAddCatePopup, setShowAddCatePopup] = useState(false);
  const [showDeletePropertyPopup, setShowDeletePropertyPopup] = useState(false);
  const [pData, setPData] = useState({});
  const [rows, setRows] = useState({});
  const [error, setError] = useState([]);
  useDocumentTitle("Property List");

  useEffect(() => {
    const getData = async () => {
      await postForm(
        "property/category/list",
        null,
        null,
        null,
        null,
        null,
        null,
        token,
        setRows,
        setListTableLoading,
        false
      );
    };
    getData();
  }, []);

  const viewAllProperties = () => {
    return (
      <PrimaryLink
        target=""
        t={t}
        label={
          account_type === USER_TYPE.INDIVIDUAL
            ? "View Properties"
            : "View All Properties"
        }
        href={
          account_type === USER_TYPE.INDIVIDUAL
            ? `/${lng}/property-list/view-property/${rows ? rows[0]?.id : ""}`
            : `/${lng}/property-list/all-properties`
        }
      />
    );
  };

  const handleSuccess = (data) => {
    setShowDeletePropertyPopup(false);
    setShowAddCatePopup(false);
    setShowRenamePopup(false);
    setShowAliasPopup(false);
  };

  const handlePopupSubmit = async (event) => {
    event.preventDefault();
    const api_name = "property/category";
    const form_data = new FormData(event.target);
    let slug = showRenamePopup
      ? "rename"
      : showAddCatePopup
      ? `add`
      : showAliasPopup
      ? `alias`
      : `delete/${pData.id}`;
    await postForm(
      api_name,
      slug,
      form_data,
      setError,
      null,
      false,
      handleSuccess,
      token,
      setRows,
      null,
      true,
      showDeletePropertyPopup,
      () => {
        setShowDeletePropertyPopup(false);
      }
    );
  };

  const popupProps = (title, setState, text) => ({
    id: pData.id,
    name: "id",
    apiProps: {
      setError,
      customSubmit: handlePopupSubmit,
      loading,
    },
    t,
    setState,
    title,
    text,
  });

  const columns = useMemo(() => {
    const cols = [
      {
        accessorKey: "name",
        header: "Category",
        minSize: 600,
        grow: false,
        enableSorting: false,
        Header: ({ column }) => (
          <div className="flex gap-x-sm items-center">
            {column.columnDef.header}
            <MyTooltip
              t={t}
              title="Category serves as a grouping function, allowing you to organise your property list by your company preferences and nature. Category limited to 5 levels of hierarchy."
            />
          </div>
        ),
        Cell: ({ cell, row }) =>
          CategoryColumn(
            row,
            cell,
            account_type,
            row.original.rowType !== "parent"
          ),
      },
      {
        accessorKey: "propertyTotal",
        header: "No. of Property",
        minSize: 220,
        grow: true,
        enableSorting: false,
        Header: ({ column }) => (
          <div className="flex gap-x-sm items-center">
            {column.columnDef.header}
            <MyTooltip
              t={t}
              title="It shows the total number of properties and the maximum number of properties per account is 20,000."
            />
          </div>
        ),
        Cell: ({ cell }) => <div className="label1">{cell.getValue()}</div>,
      },
    ];

    if (
      account_type === USER_TYPE.CORPORATE ||
      account_type === USER_TYPE.OFFICER
    ) {
      cols.push({
        accessorKey: "permission",
        header: "Access",
        grow: false,
        minSize: 200,
        enableSorting: false,
        Header: ({ column }) => (
          <div className="flex gap-x-sm items-center">
            {column.columnDef.header}
            <MyTooltip
              t={t}
              title="It refers to the different levels of permissions granted to users within the portal, including 4 levels: Full access, Shared, Edit and View only."
            />
          </div>
        ),
        Cell: ({ cell, row }) => AccessIcon(row, cell),
      });
    }

    return cols;
  }, [account_type]);

  const { CatTableTemplate, CategoryColumn, AccessIcon } = CategoryTable({
    rows,
    columns,
  });

  const renamePopup = (pData) => {
    return (
      <Popup {...popupProps("Rename", setShowRenamePopup)}>
        <Input
          inputLabel="Category Name"
          name="name"
          data={pData.name}
          error={error["name"]}
          t={t}
        />
        <ErrorMessage error={error["message"]} t={t} />
      </Popup>
    );
  };

  const aliasPopup = (pData) => {
    return (
      <Popup {...popupProps("Alias", setShowAliasPopup)}>
        <Input
          inputLabel="Alias Name"
          name="name"
          data={pData.alias}
          error={error["name"]}
          t={t}
        />
        <ErrorMessage error={error["message"]} t={t} />
      </Popup>
    );
  };

  const addCatePopup = (pData) => {
    return (
      <Popup {...popupProps("Add Sub-category", setShowAddCatePopup)}>
        <div className="flex flex-col gap-xxx-big">
          <Input
            inputLabel="Parent Category Name"
            name="parent_category"
            disabled={true}
            data={pData.name}
          />
          <Input
            inputLabel="Sub-Category Name"
            name="name"
            error={error["name"]}
            t={t}
          />
        </div>
        <ErrorMessage error={error["message"]} t={t} />
      </Popup>
    );
  };

  const deletePropertyPopup = (pData) => {
    const text = (
      <div>
        Are you sure to delete category{" "}
        <span style={{ fontWeight: "700" }}>{pData.name} </span>(and its
        sub-categories, if any) from your list?
      </div>
    );

    return (
      <Popup
        confirm_popup={true}
        {...popupProps(
          "Confirm to delete category?",
          setShowDeletePropertyPopup,
          text
        )}
      />
    );
  };

  const savePData = (e) => {
    setPData({ id: e.target.dataset.id, ...JSON.parse(e.target.dataset.get) });
  };

  const RenamePopup = (e) => {
    setShowRenamePopup(true);
    savePData(e);
  };
  const AliasPopup = (e) => {
    setShowAliasPopup(true);
    savePData(e);
  };

  const AddCatePopup = (e) => {
    setShowAddCatePopup(true);
    savePData(e);
  };

  const DeletePropertyPopup = (e) => {
    setShowDeletePropertyPopup(true);
    savePData(e);
  };

  const options = [
    {
      label: "View Property",
      link: `/${lng}/property-list/view-property/`,
      permission: [full_access, editable, view_only, display_only, restricted],
    },
    {
      label: "Rename",
      action: RenamePopup,
      permission: [full_access, editable],
    },
    {
      label: "Alias",
      action: AliasPopup,
      permission: [full_access, editable, view_only],
    },
    {
      label: "Add Sub-category",
      action: AddCatePopup,
      permission: [full_access, editable],
    },
    { label: "Delete", action: DeletePropertyPopup, permission: [full_access] },
  ];

  const table = useMaterialReactTable({
    columns,
    data: rows,
    enableExpanding: true,
    enablePagination: false,
    getSubRows: (originalRow) => originalRow.children,
    enableExpandAll: false,
    initialState: {
      columnVisibility: {
        "mrt-row-expand": false,
      },
      expanded: true,
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "",
      },
    },
  });

  if (userLoading) return <LoadingUI />;

  const TableHeader = ({ table }) => (
    <thead className="bg-surface-secondary h-[56px]">
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <th
              key={header.id}
              className="label1 text-body pl-big"
              style={{
                minWidth: header.column.columnDef.minSize,
                grow: header.column.columnDef.grow,
              }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.Header ??
                      header.column.columnDef.header,
                    header.getContext()
                  )}
            </th>
          ))}
          <th className="pl-big"></th>
        </tr>
      ))}
    </thead>
  );

  const TableRow = ({ row, rowIndex, table, options, t }) => (
    <tr key={row.id} className="bg-surface-card">
      {row.getVisibleCells().map((cell, _columnIndex) => (
        <td key={cell.id} className="py-big px-big border-light border-t">
          <MRT_TableBodyCellValue
            cell={cell}
            table={table}
            staticRowIndex={rowIndex}
          />
        </td>
      ))}
      <td className="px-lg py-big border-light border-t">
        <div className="table-row-actions">
          {row.original.rowType === "share_parent" ? (
            <EditButton t={t} searchIcon={true} href="" />
          ) : row.original.rowType !== "share_parent" &&
            Number(row.original.permission) !== no_permission &&
            Number(row.original.permission) !== restricted ? (
            <TableModelMenu
              buttons={
                <MoreHorizIcon style={{ fontSize: "var(--spacing-xxx-big)" }} />
              }
              options={options}
              is_parent={row.original.rowType === "parent"}
              row={row}
              getDatas={JSON.stringify({
                name: row.original.name,
                alias: row.original.alias,
              })}
            />
          ) : null}
        </div>
      </td>
    </tr>
  );

  return (
    <>
      <Breadcrumb lng={lng} homeElement={"Home"} capitalizeLinks />
      {showRenamePopup && renamePopup(pData)}
      {showAddCatePopup && addCatePopup(pData)}
      {showAliasPopup && aliasPopup(pData)}
      {showDeletePropertyPopup && deletePropertyPopup(pData)}
      <PropertyTemplate
        lng={lng}
        title="Property List"
        button_bar={viewAllProperties}
      >
        <style>
          {`
                       tbody:before {line-height: var(--spacing-big); content: "\\200C"; display:block;}
                       table tbody td {
                          display: table-cell
                        }
                        
                        table tbody tr:first-child td:first-child {
                          border-top-left-radius: var(--radius-big);
                        }
                        
                        table tbody tr:first-child td {
                          border-top: none;
                        }
                        
                        table tbody tr:first-child td:last-child {
                          border-top-right-radius: var(--radius-big);
                        }
                        
                        table tbody tr:last-child td:first-child {
                          border-bottom-left-radius: var(--radius-big);
                        }
                        
                        table tbody tr:last-child td:last-child {
                          border-bottom-right-radius: var(--radius-big);
                        }
                   `}
        </style>

        <div className="w-full overflow-x-auto custom-scrollbar-table">
          <table className="border-collapse w-full">
            <TableHeader table={table} />
            <tbody>
              {table
                .getRowModel()
                .rows.map((row, rowIndex) =>
                  row.original.rowType !== "shared_parent" &&
                  row.original.rowType !== "shared_child" ? (
                    <TableRow
                      key={row.id}
                      row={row}
                      rowIndex={rowIndex}
                      table={table}
                      options={options}
                      t={t}
                    />
                  ) : null
                )}
            </tbody>
            <tbody className="mt-big">
              {table
                .getRowModel()
                .rows.map((row, rowIndex) =>
                  row.original.rowType === "shared_parent" ||
                  row.original.rowType === "shared_child" ? (
                    <TableRow
                      key={row.id}
                      row={row}
                      rowIndex={rowIndex}
                      table={table}
                      options={options}
                      t={t}
                    />
                  ) : null
                )}
            </tbody>
          </table>
        </div>
      </PropertyTemplate>
    </>
  );
};

export default PropertyList;
