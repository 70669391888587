import React, {useEffect, useState} from "react";
import changeStepForms from "../../components/step/ChangeStepForms";
import StepOne from "../../components/forgot-activate-form/StepOne";
import StepTwo from "../../components/forgot-activate-form/StepTwo";
import StepThree from "../../components/forgot-activate-form/StepThree";
import FormHeaderTemplate from "../../components/form/FormHeaderTemplate";
import StepFormTemplate from "../../components/form/StepFormTemplate";
import { useTranslation } from "react-i18next";
import MtCaptchaScript from "../../components/MtCaptcha/MtCaptchaScript";
import useDocumentTitle from "../../hooks/useDocument";

const ActivateAccount = ({ lng }) => {
    const [data, setData] = useState({});
    const [error, setError] = useState({});
    const [loadingCapthcha, setLoadingCapthcha] = useState(false);
    const { t } = useTranslation();
    const api_name = 'corporate/account';
    const slug = 'activate';
    const { loadMtCaptcha } = MtCaptchaScript({loadingCapthcha, setLoadingCapthcha});
    useDocumentTitle("Activate Account")

    const updateFields = (fields => {
        setData(prevData => ({ ...prevData, ...fields }));
    });

    useEffect(() => {
        loadMtCaptcha();
    }, []);

    const props = (name, inputLabel, type="text") => {
        return {
            name,
            inputLabel,
            type,
            updateFields,
            error: error ? error[name] : '',
            data: data ? data[name] : '',
            setError,
            t,
        }
    }

    useEffect(() => {
        if (data['uuid']) {
            updateFields({uuid_email: data['uuid']})
        }
    }, [data['uuid']])

    const {
        steps,
        currentStepIndex,
        step,
        isFirstStep,
        isLastStep,
        back,
        next
    } = changeStepForms([
        <StepOne
            key="1"
            api_name={api_name}
            props={props}
            data={data}
            error={error}
            setError={setError}
            loadingCapthcha={loadingCapthcha}
            t={t}
        />,
        <StepTwo
            key="2"
            slug={slug}
            props={props}
            data={data}
            error={error}
            setError={setError}
            api_name={api_name}
            t={t}
        />,
        <StepThree
            data={data}
            lng={lng}
            key="3"
            api_name={api_name}
            props={props}
            updateFields={updateFields}
            PasswordData={data ? data["password"] : ''}
            ConfirmData={data ? data["password2"] : ''}
            PasswordError={error ? error["password"] : ''}
            ConfirmError={error ?error["password2"] : ''}
            setError={setError }
            error={error}
            t={t}
        />
    ]);

    return (
        <div className="bg-50">
            <div className="registration-container py-xxx-lg layout-container">
                <FormHeaderTemplate
                    title='Activate Account'
                    subtitle='Please follow the instructions to activate your account.'
                    t={t}
                />
                <StepFormTemplate
                    formData={data}
                    setError={setError}
                    step={step}
                    isLastStep={isLastStep}
                    back={back}
                    next={next}
                    currentStepIndex={currentStepIndex}
                    isFirstStep={isFirstStep}
                    api_name={api_name}
                    slug={isLastStep ? slug : slug}
                    back_path={`/login`}
                    type={api_name}
                    lng={lng}
                    redirect_path={`/successfully?type=activate_account`}
                    updateFields={updateFields}
                    getCode={isFirstStep}
                />
            </div>
        </div>
    );
};

export default ActivateAccount;