import { createContext, useContext, useState } from 'react';

const CateDataContext = createContext({
    cateName: '',
    setCateName: () => {},
});


const CateDataProvider = ({ children }) => {
    const [cateName, setCateName] = useState('');
    return (
        <CateDataContext.Provider value={{ cateName, setCateName }}>
            {children}
        </CateDataContext.Provider>
    );
};

export const useCateDataContext = () => useContext(CateDataContext);
export default CateDataProvider;
