import {useState} from "react";
import Header from "../../components/header/Header";
import Nav from "../../components/header/Nav";

const HeaderAndNavBar = ({ lng }) => {
    const [showNavBar, setShowNavBar] = useState(false);
    const handleToggle = () => {
        setShowNavBar(prev => !prev);
    };

    return (
        <>
            <Header showNavBar={ showNavBar } handleToggle={ handleToggle } lng={lng}/>
            <Nav showNavBar={showNavBar} handleToggle={handleToggle} lng={lng}/>
        </>
    )
}

export default HeaderAndNavBar;