import TextHeadline from "../../components/TextHeadline";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Menu from "../../components/help-centre/Menu";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import LoadingUI from "../../components/loading/LoadingUI";
import Breadcrumb from "../../components/header/Breadcrumb";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import useDocumentTitle from "../../hooks/useDocument";


const GettingStarted = ({ lng }) => {
    const { t } = useTranslation();
    const [categoryId, setCategoryId] = useState(1);
    const [menuList, setMenuList] = useState([]);
    const [postData, setPostData] = useState([]);
    const { userLoading, token } = useUserLoginContext();
    const { fetchData } = SubmitAndFetch({ lng, t});
    const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
    useDocumentTitle("Getting Started");

    const fetchPostData = async () => {
        await fetchData(`tutorial/list/${categoryId}`, null, setPostData, null, lng, token)
    }

    useEffect(() => {
        const fetchMenu = async () => {
            await fetchData('tutorial/category', null, setMenuList, null, lng, token)
        }
        if (token) {
            fetchMenu();
            fetchPostData()
        }
    }, [token]);

    useEffect(() => {
        fetchPostData();
    }, [categoryId]);

    if (userLoading) return <LoadingUI/>;

    const NoDataText = () => (
        <div className=" p-md">{t('No data found')}</div>
    )

    return (
        <>
            <Breadcrumb
                lng={lng}
                homeElement={'Home'}
                capitalizeLinks
            />
            <div className="layout-container content-container flex flex-col gap-lg">
                <TextHeadline title={t('Getting Started')} />
                {menuList.length && postData.length ?
                    <div className="flex gap-xx-big flex-col md:flex-row w-full">
                        {menuList.length ? <Menu menuList={menuList} state={categoryId} setState={setCategoryId} t={t}
                                          lang={lang}/> : null}
                        {postData.length ?
                            <div
                                className='flex flex-col gap-xxx-big p-xxx-big w-full overflow-hidden rounded-big bg-surface-card'>
                                <div className="overflow-auto">
                                    <h2 className="h2 text-title">{postData[0]?.[`title${lang}`]}</h2>
                                </div>
                                <div>
                                    <div dangerouslySetInnerHTML={{__html: postData[0]?.[`content${lang}`]}}
                                         className="body2 text-title"></div>
                                </div>
                            </div>
                            : NoDataText()}
                    </div>
                    :
                    NoDataText()
                }
            </div>
        </>
    )
}

export default GettingStarted;