import {useEffect, useState} from "react";
import { FilledInput, FormControl, IconButton, InputAdornment, InputLabel, inputLabelClasses } from "@mui/material";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ErrorMessage from "../../components/error/ErrorMessage";

const Password = ({ name, inputLabel, action, updateFields, data, disabled, error, required, t, setError, showEyeIcon=true }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [value, setValue] = useState(data);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handleChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        if (action) {
            action(newValue);
        }
        if (updateFields) {
            updateFields({ [name]: newValue });
        }
        if (setError) setError(preError => ({ ...preError, [name]: '' }));
    }

    useEffect(() => {
        if (!data) {
            setValue('');
        }
    }, [data])

    const handleCoptyAndPaste = (event) => {
        event.preventDefault();
    }

    return (
        <FormControl variant="filled">
            <InputLabel
                htmlFor={name}
                error={Boolean(error)}
                className="body2"
                sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "var(--text-caption)",
                    },
                    color: 'var(--text-body)',
                    [`&.Mui-error.${inputLabelClasses.focused}`]: {
                        [`&.${inputLabelClasses.shrink}`]: {
                            color: "var(--text-warning)"
                        }
                    },
                    [`&.${inputLabelClasses.focused}`]: {
                        [`&.${inputLabelClasses.shrink}`]: {
                            color: "var(--label-icon-on-light)"
                        }
                    },
                    [`&.${inputLabelClasses.shrink}`]: {
                        color: "var(--text-caption)"
                    },
                    [`&.Mui-error.${inputLabelClasses.shrink}`]: {
                        color: "var(--text-warning)"
                    }
                }}
            >
                { t ? t(inputLabel) : inputLabel }
            </InputLabel>
            <FilledInput
                className="input bg-surface-base"
                onChange={handleChange}
                error={Boolean(error)}
                id={name}
                name={name}
                value={value}
                disabled={disabled}
                required={required}
                onCut={handleCoptyAndPaste}
                onCopy={handleCoptyAndPaste}
                inputProps={{
                    maxLength: "20",
                }}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    !disabled && (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                className="text-600"
                            >
                                {showEyeIcon ?
                                    showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />
                                : null}
                            </IconButton>
                        </InputAdornment>
                    )
                }
                sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "var(--text-body)",
                        background: 'var(--surface-card)',
                    },
                    background: 'var(--surface-base)',
                    ':before': { borderBottomColor: 'var(--Neutral-300)' },
                    ':after': { borderBottomColor: 'var(--surface-primary)' },
                }}
            />
            <ErrorMessage error={error} t={t}/>
        </FormControl>
    );
}

export default Password;
