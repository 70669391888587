import {Tooltip} from "@mui/material";
import { ReactComponent as HelpSVG } from "../../assets/images/categort/help.svg";
const MyTooltip = ({ title, w, t }) => {

    return(
        <Tooltip
            title={typeof title === "function" ? title() : t ? t(title) : title}
            arrow
            className="label1"
            placement="top"
            componentsProps={{
                tooltip: {
                    sx: {
                        background: 'var(--surface-card)',
                        color: 'var(--Neutral-800)',
                        padding: 'var(--spacing-md)',
                        fontSize: '0.875rem',
                        borderRadius: 'var(--radius-md)',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        boxShadow: '0px 15px 40px 0px rgba(0, 0, 0, 0.25)',
                        maxWidth: w ? w : 'auto',
                    }
                },
                arrow: {
                    sx: {
                        marginRight: 'var(--spacing-md)',
                        color: 'var(--surface-card)'
                    }
                }
            }}
        >
            <span>{<HelpSVG width={24} />}</span>
        </Tooltip>
    )
}

export default MyTooltip;