import React from 'react';
import { Outlet } from 'react-router-dom';
import CateDataProvider from "../context/CateDataProvider";
function PropertyLayout() {
    return (
        <CateDataProvider>
            <Outlet />
        </CateDataProvider>
    );
}

export default PropertyLayout;