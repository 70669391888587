import React, { useEffect, useState } from "react";
import SmallFunction from "../../components/function/SmallFunction";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/header/Breadcrumb";
import {
  DeleteWithTextButton,
  ArrowBackButton,
  BlueForwardLink,
} from "../../components/button/SingleButton";
import { useTranslation } from "react-i18next";
import ArticleTemplates from "../../components/articles/ArticlesTemplate";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import LoadingUI from "../../components/loading/LoadingUI";
import Popup from "../../components/popup/Popup";
import NotFoundPage from "../error/NotFoundPage";

const NotificationCentreDetailsPage = ({ lng }) => {
  const { id } = useParams();
  const [allNotification, setAllNotification] = useState({});
  const [data, setData] = useState({});
  const [result, setResult] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { changeDate } = SmallFunction();
  const { userLoading, token } = useUserLoginContext();
  const { t } = useTranslation();
  const { postForm, loading } = SubmitAndFetch({ lng, t });
  const navigate = useNavigate();
  const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
  const [prevId, setPrevId] = useState(null);
  const [nextId, setNextId] = useState(null);

  if (!id) {
    navigate(`/${lng}/notification-centre`);
  }

  const getNotification = (resData) => {
    if (resData) {
      setData(resData);
      setPrevId(resData?.prevId);
      setNextId(resData?.nextId);
    }
  };

  useEffect(() => {
    setData({});
    setPrevId(null);
    setNextId(null);

    async function fetchData() {
      await postForm(
        `notification/${id}`,
        null,
        null,
        null,
        null,
        false,
        getNotification,
        token,
        null,
        null,
        false,
        false,
        () => navigate(`/404`)
      );
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    if (data && data[`subject${lang}`]) {
      document.title = `${t("web_portal_title_text")} - ${t("Notification")} | ${data[`subject${lang}`]}`;
    }
  }, [data, lang]);

  const subtitle = () => {
    const date = changeDate(data.date);
    const time = changeDate(null, data.date, null, { ss: false });
    return (
      <div className="flex gap-x-sm items-center mb-xx-big">
        {date}
        <span className="hidden md:block w-[5px] h-[5px] bg-surface-disabled rounded-full"></span>
        {time}
      </div>
    );
  };

  if (userLoading) return <LoadingUI />;

  const handleDeleteNotification = async (id, event) => {
    event.preventDefault();
    await postForm(
      `notification/delete/${id}`,
      null,
      null,
      null,
      `${
        prevId || nextId
          ? `/${lng}/notification-centre/details/${prevId || nextId}`
          : `/${lng}/notification-centre/`
      }`,
      false,
      null,
      token,
      null,
      null,
      true,
      true
    ); //Object.entries(nextData).length ?`/${lng}/notification-centre/details/${Number(id) + 1}` : Object.entries(preData).length ?  `/${lng}/notification-centre/details/${Number(id) + 1}` :
    setShowDeletePopup(false);
  };

  const apiProps = {
    loading: deleteLoading,
    customSubmit: (event) => handleDeleteNotification(id, event),
  };
  return (
    <>
      {showDeletePopup && (
        <Popup
          t={t}
          lng={lng}
          setState={setShowDeletePopup}
          confirm_popup={true}
          apiProps={apiProps}
          title="Confirm to delete notification?"
          text="Are you sure you want to delete this notification? Once deleted, it cannot be undone."
        />
      )}
      <Breadcrumb
        lng={lng}
        homeElement={"Home"}
        pervElement={{
          skip: true,
          position: 2,
        }}
        currentElement={data?.[`subject${lang}`]}
        capitalizeLinks
      />
      <ArticleTemplates
        subtitle={subtitle()}
        title={data?.[`subject${lang}`]}
        buttonBar={
          Object.entries(data).length ? (
            <DeleteWithTextButton
              t={t}
              action={() => setShowDeletePopup(true)}
            />
          ) : null
        }
      >
        <div className="flex-1 overflow-auto">
          <div className="bg-white rounded-big py-xxx-big px-xxx-big">
            <div
              dangerouslySetInnerHTML={{ __html: data?.[`content${lang}`] }}
              className="body1 text-title"
            ></div>
          </div>
          <div className="flex w-full justify-between mt-xx-big">
            <ArrowBackButton t={t} href={`/${lng}/notification-centre`} />
            <div className="flex gap-md">
              <ArrowBackButton
                t={t}
                disabled={!prevId}
                label={t("Previous")}
                href={
                  prevId
                    ? `/${lng}/notification-centre/details/${prevId}`
                    : null
                }
              />

              <BlueForwardLink
                t={t}
                disabled={!nextId}
                label={t("Next")}
                href={
                  nextId
                    ? `/${lng}/notification-centre/details/${nextId}`
                    : null
                }
              />
            </div>
          </div>
        </div>
      </ArticleTemplates>
    </>
  );
};

export default NotificationCentreDetailsPage;
