import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { PrimaryButton } from "../button/SingleButton";
import { useTranslation } from "react-i18next";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment key={index}>
                    {children}
                </React.Fragment>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TabTemplate = ({ lng, title, tab, headData, changed, setToDefaultValue, backButton, discardButton, saveButton, loading, setCurrentTab, setError }) => {
    const [value, setValue] = useState(0);
    const { t } = useTranslation(lng, 'translation');
    const handleChange = (event, newValue) => {
        if (setValue) setValue(newValue);
        if (setError) setError({});
        if (setCurrentTab) setCurrentTab(newValue);
    };

    const handleAlert = (e, index) => {
        if (changed && value !== index) {
            e.preventDefault();
            if (window.confirm("You have unsaved changes that will be lost if you decide to continue.")) {
                setToDefaultValue();
                setValue(index);
            } else {
                setValue(value);
            }
        }
    };

    return (
        <>
            <div className={discardButton ? 'inline-block md:hidden' : 'hidden'}>
                {backButton ? backButton : null}
            </div>
            {headData? headData : null}
            <div className="mt-xx-big">
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { backgroundColor: "var(--surface-primary)" } }} variant="scrollable" >
                            {tab && tab.map((item, index) => (
                                <Tab className="text-caption subtitle font-medium"
                                     sx={{
                                         '&.Mui-selected': {
                                             color: 'var(--surface-primary)',
                                         },
                                         fontSize: '22px !important',
                                         textTransform: 'none',
                                         padding: 'var(--spacing-big) var(--spacing-md)'
                                     }}
                                 label={t(item.label)} {...a11yProps(index)} key={index} onClick={(e) => handleAlert(e, index)} />
                            ))}
                        </Tabs>
                    </Box>
                </Box>
                {tab && tab.map((item, index) => {
                    return (
                        <CustomTabPanel value={value} index={index} key={index} className="p-0 flex flex-col gap-xx-big">
                            <>
                                {item.field && item.field.length > 1 ? item.field.map((i, index) => {
                                        if (!i) return null;
                                        return (
                                            <div className="profile-field bg-white rounded-b-big" key={index}>
                                                {i}
                                            </div>
                                        );
                                    }) :
                                    <div className="profile-field bg-white rounded-b-big">
                                        {item.field}
                                    </div>}

                                <div className="flex justify-between items-center">
                                    <div className={discardButton ? 'hidden md:inline-block' : 'inline-block'}>
                                        {backButton ? backButton : null}
                                    </div>
                                    <div className="justify-between md:justify-end flex gap-md">
                                        {discardButton ? discardButton : null}
                                        {saveButton && <div className="ms-auto">
                                            <PrimaryButton label={saveButton || 'Save Changes'} t={t} type="submit" loading={loading} />
                                        </div>}
                                    </div>
                                </div>
                            </>
                        </CustomTabPanel>
                    )
                })}
            </div>
        </>
    );
};

export default TabTemplate;
