export function AddDataToHTML(dataName, dataValue) {
    let html_element = document.querySelector('html');
    html_element.setAttribute(dataName, dataValue);
}

export function ScrollToError() {
    let errorField = document.querySelector('.Mui-error');
    if (errorField) {
        const errorFieldTop = errorField.getBoundingClientRect().top;
        const topOffset = errorFieldTop + window.pageYOffset - 40;
        window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
}

export function DeepEqual(obj1, obj2) {
    if (obj1 === obj2) {
        return true;
    }

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (!obj2.hasOwnProperty(key) || !DeepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;

}

