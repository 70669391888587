import SearchBy from "../../components/search/SearchBy";
import WhiteInput from "../../components/input/portal/WhiteInput";

const CustomSearchBar = ({
  searchBy,
  setSearchBy,
  t,
  showAdvance = false,
  options,
  searchbar,
  noShowAdvance = false,
  customSearchAction,
  searchList,
  resetAction,
}) => {
  const customInput = (name, placeholder, searchbar) => {
    return (
      <WhiteInput
        name={name}
        searchbar={searchbar}
        placeholder={placeholder}
        setSearchBy={setSearchBy}
        t={t}
        customSearchAction={customSearchAction}
        updateFields={searchList?.updateFields}
        resetAction={resetAction}
      />
    );
  };

  return (
    <>
      {(showAdvance || noShowAdvance) && (
        <div className="flex flex-col gap-sm">
          <SearchBy
            t={t}
            setSearchBy={setSearchBy}
            searchByOptions={options?.options}
            data={searchBy}
            title={options?.title}
            updateFields={searchList?.updateFields}
          />
          <div className="flex gap-sm items-center">
            {options &&
              options?.options.map((item, index) => {
                return (
                  searchBy === item.value && (
                    <div key={index} className="w-full">
                      {customInput(item.name, item.label, searchbar)}
                    </div>
                  )
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomSearchBar;
