const Line = ({ my }) => {
    return (
        <>
            <style>
                {`
                .divideLine {
                        display: block;
                        border-top: 1px dashed var(--border-light);
                        border-spacing: 10px;
                        border-collapse: separate;
                }
            `}
            </style>
            <div className={`divideLine ${my ? my : 'my-xxx-big'}`}></div>
        </>
    )
}

export default Line;
