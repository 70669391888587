import MyTooltip from "../../components/tooltip/Tooltip";
import Radio from "../../components/input/Radio";


const SearchBy = ({ searchByOptions, data, setSearchBy, title, resetData, t, updateFields }) => {

    const handleSearchBy = (value) => {
        if(resetData) resetData({});
        if (updateFields) {
            searchByOptions?.forEach(item => {
                updateFields({ [item.name]: '' });
            });
        }
        setSearchBy(Number(value));
    }

    return (
        <>
            <div className="flex flex-col md:flex-row items-start md:items-center gap-x-sm w-full">
                <div className='flex gap-x-sm'>
                    <MyTooltip title={title} t={t} />
                    <div className="label1-400 text-body w-fit">Search By</div>
                </div>
                <Radio name="search_by" options={searchByOptions} labelClass="label1-400" action={handleSearchBy} data={data} gap='x-sm' t={t} className='flex-col flex md:flex-row' />
            </div>
        </>

    )
}

export default SearchBy;