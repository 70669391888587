import { useTranslation } from "react-i18next";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import LoadingUI from "../../components/loading/LoadingUI";
import React, {useEffect, useState} from "react";
import TextHeadline from "../../components/TextHeadline";
import ArticlesTemplate from "../../components/help-centre/ArticlesTemplate";
import Pagination from "../../components/table/Pagination";
import Breadcrumb from "../../components/header/Breadcrumb";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import { useSearchParams } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocument";
const Articles = ({lng }) => {
    const [searchParams] = useSearchParams();
    const related_id = searchParams.get('related_id');
    const {t} = useTranslation();
    const {userLoading, token} = useUserLoginContext();
    const [articles, setArticles] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [isFetching, setIsFetching] = useState(true);
    const [paginationModel, setPaginationModel] = useState({
        pageIndex: 0,
        pageSize: 9,
    });
    const lang = lng ? lng[0].toUpperCase() + lng.slice(1) : '';
    const {postForm} = SubmitAndFetch({lng, t});
    useDocumentTitle(related_id ? "Related Articles" : "Articles");


    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchArticles = async () => {
            let api_name = 'article';
            if (related_id) api_name = 'article/related'
            await postForm(api_name, null, {
                id: related_id ? related_id : null,
                page_num: paginationModel.pageIndex + 1,
                page_size: paginationModel.pageSize
            }, null, null, false, saveArticleData, token, null, null, false)
        }
        if (token || isFetching) {
            fetchArticles();
        }
        setIsFetching(false);
    }, [token, isFetching]);


    const saveArticleData = (resData) => {
        if (resData) {
            setTotalRows(resData?.total);
            setTotalPages(resData?.pages);
            setArticles(resData);
        }
    }

    if (userLoading) return <LoadingUI/>;

    return (
        <>
            <Breadcrumb
                lng={lng}
                homeElement={'Home'}
                currentElement={related_id ? 'Related Articles' : 'Articles'}
                capitalizeLinks
            />


            <div className="layout-container content-container flex flex-col gap-lg">
                <TextHeadline title={related_id ? 'Related Articles' : 'Articles'}/>
                {articles?.list ?
                    <>
                        <div className='grid grid-cols-3 grid-rows-3 gap-xxx-big'>
                            <ArticlesTemplate data={articles?.list} lang={lang} lng={lng}/>
                        </div>
                        <Pagination paginationModel={paginationModel} setPaginationModel={setPaginationModel}
                                    totalPages={totalPages} totalRows={totalRows} setIsRefetching={setIsFetching}/>

                    </>
                    :
                    <div className='label1 w-full flex justify-center items-center h-[100px] '>
                        {t('No articles found')}
                    </div>
                }
            </div>
        </>
    )
}

export default Articles;