import TextHeadline from "../../components/TextHeadline";

const PropertyTemplate = ({ children, title, button_bar }) => {
    return (
        <div className="layout-container content-container flex gap-60px flex-col md:flex-row">
            <div className="flex flex-col gap-xx-big w-full">
                <div className={`flex md:justify-between md:items-center flex-col md:flex-row ${button_bar ? 'gap-md' : ''}`}>
                    <TextHeadline title={title} />
                    <span className='w-fit mt-sm md:mt-0'>{button_bar ? button_bar() : null}</span>
                </div>
                { children }
            </div>
        </div>
    );
};

export default PropertyTemplate;