import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import UserLoginProvider from "../context/UserLoginProvider";
import HeaderAndNavBar from "../components/header/HeaderAndNavBar";
import ShowPopupProvider from "../context/ShowPopupProvider";
import Popup from "../components/home/Popup";
import Footer from "../components/footer/Footer";
function HomeLayout() {
    const {i18n: {language}} = useTranslation();
    return (
        <UserLoginProvider lng={language}>
            <ShowPopupProvider>
                <Popup lng={language}/>
                <HeaderAndNavBar lng={language}/>
                <main>
                    <Outlet />
                </main>
                <Footer/>
            </ShowPopupProvider>
        </UserLoginProvider>
    );
}

export default HomeLayout;