import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useDocumentTitle = (titleKey) => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = `${t("web_portal_title_text")} - ${titleKey ? t(titleKey) : ''}`;
    }, []);
};

export default useDocumentTitle;