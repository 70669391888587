import NoteTemplate from '../../components/registration_form/NoteTemplate'
import FormHeaderTemplate from '../../components/form/FormHeaderTemplate';
const StepOne = ({ t }) => {
    return (
        <>
            <FormHeaderTemplate title="Important Notes" subtitle="Please read the important notes before register a new account" t={t}/>
            <NoteTemplate title="Important Notes"  t={t}>
                <p className='text-start'>{t('For')} <span className="font-[700]">{t('Individual')}</span>, {t('Please prepare the following documents to register a Web Portal Account:')}</p>
                <ol className="list-decimal ms-xxx-big text-start md:text-justify">
                    <li>{t('a copy of your Hong Kong identity card or travel document (Not applicable to iAM Smart Users)')}; {t('&')}</li>
                    <li>{t('latest quarterly "Demand for Rates and/or Government Rent"')}</li>
                </ol>
                <br/>
                <p className='text-start'>{t('For')} <span className="font-[700]">{t('Corporate')}</span>, {t('Please prepare the following documents to register a Web Portal Account:')}</p>
                <ol className="list-decimal ms-xxx-big text-start md:text-justify">
                    <li>{t('a copy of your Business Registration Certificate; &')}</li>
                    <li>{t('a confirmation letter with company/organisation chop or authorised signature')}(<a href='#' className="text-surface-primary font-[700] hover:underline">{t('See sample')}</a>); {t('&')}</li>
                    <li>{t('latest quarterly "Demand for Rates and/or Government Rent"')}</li>
                </ol>
            </NoteTemplate>
        </>
    )
}

export default StepOne;