import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/style/root.css';
import './assets/style/theme.css';
import './assets/style/globals.css';
import '../src/i18n/i18n.js';
import '../src/fonts/inter-v13-latin-500.woff2';
import '../src/fonts/inter-v13-latin-600.woff2';
import '../src/fonts/inter-v13-latin-700.woff2';
import '../src/fonts/inter-v13-latin-800.woff2';
import '../src/fonts/inter-v13-latin-900.woff2';
import '../src/fonts/inter-v13-latin-regular.woff2';
import App from './App';
import reportWebVitals from './reportWebVitals';
import FontSizeProvider from "./context/FontSizeProvider";
import { SnackbarProvider } from 'notistack';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <SnackbarProvider maxSnack={1}>
          <FontSizeProvider>
            <App />
          </FontSizeProvider>
      </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
