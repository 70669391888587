import WhiteSelect from "../../components/input/portal/WhiteSelect";
import WhiteInput from "../../components/input/portal/WhiteInput";
import { PrimaryButton, DiscardButton } from "../../components/button/SingleButton";

const AssessmentAndTRNSearch = ({ searchBy, t, loading, setSearchBy, cancelAction, data, updateFields }) => {
    const handleSearchBy = (value) => {
        setSearchBy(value);
    }

    const searchByOptions = [
        { label: 'Assessment No.', value: 1 },
        { label: 'Transaction Reference No.', value: 2 },
    ];

    const TRNInput = () => (
        <WhiteInput name="trn" placeholder="Transaction Reference No. (Source)" data={data ? data['trn'] : ''} updateFields={updateFields} />
    );

    const AssessmentNoInput = () => (
        <WhiteInput name="assessment_no" placeholder="Assessment No." data={data ? data['assessment_no'] : ''} updateFields={updateFields} />
    );

    return (
        <>
            <div className="flex gap-sm items-center">
                <div className="w-full max-w-[312px]">
                    <WhiteSelect options={searchByOptions} placeholder="Search" name='search_by' defaultValue={1} action={handleSearchBy}/>
                </div>
                <div className="max-w-[700px] flex-grow flex-1">
                    {searchBy === 1 && AssessmentNoInput()}
                    {searchBy === 2 && TRNInput()}
                </div>
                <div className="flex gap-sm">
                    <PrimaryButton t={t} label='Search' loading={loading} type='submit' className='w-[108px]'/>
                    <DiscardButton t={t} label='Cancel' action={cancelAction} className='w-[108px]'/>
                </div>
            </div>
        </>
    )
}

export default AssessmentAndTRNSearch;