import orange from "../../assets/images/avatars/orange_avatars.png";
import yellow from "../../assets/images/avatars/yellow_avatars.png";
import green from "../../assets/images/avatars/green_avatars.png";
import cyan from "../../assets/images/avatars/cyan_avatars.png";
import blue from "../../assets/images/avatars/blue_avatars.png";
import purple from "../../assets/images/avatars/purple_avatars.png";
import pink from "../../assets/images/avatars/pink_avatars.png";
import grey from "../../assets/images/avatars/grey_avatars.png";
import Swal from "sweetalert2";

const SmallFunction = () => {
  const getCategoryLabels = (Ids, options, type = "status") => {
    if (Object.entries(options).length) {
      const newOption = Object.entries(options).filter(
        ([key, value]) => Number(value.value) === Number(Ids)
      );
      if (newOption.length > 0) {
        return { [type]: newOption[0][1][type], color: newOption[0][1].color };
      }
    }
    return null;
  };

  const getPunctuation = (type, t) => {
    const punctuationMap = {
      full_stop: t("full_stop"),
    };
    return punctuationMap[type] || type;
  };

  const sweetFailAlert = (resData, t, secondParam) => {
    Swal.fire({
      html: `<p>${
        resData?.errors && typeof resData?.errors === "object"
          ? Object.values(resData?.errors)
              .map((error) => error)
              .join(", ")
          : resData?.message || resData.error || "An error occurred"
      }</p>`,
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: t("Ok"),
      customClass: { confirmButton: "primary-btn" },
      timer: secondParam ? secondParam : 5000,
    });
  };

  const sweetSuccessAlert = (resData, t) => {
    return new Promise((resolve) => {
      Swal.fire({
        html: `<p>${resData.message}</p>`,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: t("Ok"),
        customClass: { confirmButton: "primary-btn" },
        timer: 1000,
      }).then(() => {
        resolve();
      });
    });
  };

  const checkAndChangeUploadCate = (
    cateId,
    setSelectUploadCate,
    assignCategoryList
  ) => {
    if (cateId && assignCategoryList) {
      const newCate = assignCategoryList.find((item) => item.id === cateId);

      if (newCate) {
        setSelectUploadCate(newCate.id);
      } else {
        setSelectUploadCate("");
      }
    } else {
      setSelectUploadCate("");
    }
  };

  const handleUnits = (fileSize) => {
    const sizeInKb = fileSize / 1024;
    if (sizeInKb > 1024) {
      return `${(sizeInKb / 1024).toFixed(2)} MB`;
    } else {
      return `${sizeInKb.toFixed(2)} KB`;
    }
  };

  const changeDate = (
    date,
    time,
    dateTime,
    condition = {
      ss: true,
      symbol: "/",
    }
  ) => {
    const convertDate = (input) => {
      input = new Date(input);
      let yyyy = input.getFullYear().toString();
      let mm = (input.getMonth() + 1).toString();
      let dd = input.getDate().toString();

      let mmChars = mm.split("");
      let ddChars = dd.split("");

      return (
        (ddChars[1] ? dd : "0" + ddChars[0])+
        condition.symbol +
        (mmChars[1] ? mm : "0" + mmChars[0]) +
        condition.symbol +
        yyyy
      );
    };

    const convertTime = (input) => {
      input = new Date(input);
      let hh = input.getHours().toString();
      let min = input.getMinutes().toString();
      let ss = input.getSeconds().toString();

      return (
        (hh.length === 2 ? hh : "0" + hh) +
        ":" +
        (min.length === 2 ? min : "0" + min) +
        (condition.ss ? ":" + (ss.length === 2 ? ss : "0" + ss) : "")
      );
    };

    if (dateTime) {
      return convertDate(dateTime) + " " + convertTime(dateTime);
    }

    if (date) return convertDate(date);
    if (time) return convertTime(time);
  };

  const getIconValue = (value, setIcon) => {
    switch (value) {
      case "1":
        setIcon(orange);
        break;
      case "2":
        setIcon(yellow);
        break;
      case "3":
        setIcon(green);
        break;
      case "4":
        setIcon(cyan);
        break;
      case "5":
        setIcon(blue);
        break;
      case "6":
        setIcon(purple);
        break;
      case "7":
        setIcon(pink);
        break;
      case "8":
        setIcon(grey);
        break;
      default:
        setIcon(blue);
        break;
    }
  };

  return {
    getCategoryLabels,
    getIconValue,
    changeDate,
    handleUnits,
    checkAndChangeUploadCate,
    getPunctuation,
    sweetFailAlert,
    sweetSuccessAlert,
  };
};

export default SmallFunction;
