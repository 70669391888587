import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import ProfileSection from "../../components/my-profile/ProfileSection";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import LoadingUI from "../../components/loading/LoadingUI";
import AccountManagementTemplate from "../../components/account_management/Template";
import {
  BlueForwardButton,
  ArrowBackButton,
} from "../../components/button/SingleButton";
import Switcher from "../../components/button/Switcher";
import CategoryTable from "../../components/table/CategoryTable";
import FileDropzone from "../../components/upload/FileDropzone";
import {
  EffectPeriod,
  SwitchAgent,
  Name,
} from "../../components/agent_appointments/FieldSection";
import Line from "../../components/line/Line";
import UPLOAD_DOC_TYPE from "../../data/upload_doc_type.json";

const CorporateAccountCreation = ({ lng }) => {
  const { t } = useTranslation();
  const { userLoading, token } = useUserLoginContext();
  const { postForm, loading } = SubmitAndFetch({ lng, t });
  const [noEndDate, setNoEndDate] = useState(false);
  const [viewOnlyState, setViewOnlyState] = useState({});
  const [editableState, setEditableState] = useState({});
  const [data, setData] = useState({ created_by: 1, type: 1 });
  const [originalData, setOriginalData] = useState({ created_by: 1, type: 1 });
  const [error, setError] = useState({});

  const updateFields = (fields) => {
    setData((prev) => ({ ...prev, ...fields }));
  };

  const updateOriginalFields = (fields) => {
    setOriginalData((prev) => ({ ...prev, ...fields }));
  };

  useEffect(() => {
    const fetchData = async () => {
      await postForm(
        "appointment/create/init",
        null,
        null,
        null,
        null,
        false,
        handleSaveData,
        token,
        null,
        null,
        false
      );
    };
    if (token) {
      fetchData();
    }
  }, [token]);

  const handleSaveData = (resData) => {
    updateFields(resData);
    updateOriginalFields(resData);
  };

  const inputProps = (name, inputLabel = null, type = "text") => ({
    name,
    inputLabel,
    type,
    data: data ? data[name] : "",
    error: error ? error[name] : "",
    updateFields,
    setError,
    t,
  });

  const options = [
    { name: "view_only_", stateName: viewOnlyState },
    { name: "editable_", stateName: editableState },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Category",
        minSize: 560,
        grow: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return CategoryColumn(row, cell);
        },
      },
      {
        accessorKey: "propertyTotal",
        header: "No. of Property",
        maxSize: 180,
        grow: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return <div className="label1 text-body">{cell.getValue()}</div>;
        },
      },
      {
        accessorKey: "view_only",
        header: "View Only",
        size: 140,
        enableSorting: false,
        className: "text-center",
        Cell: ({ cell, row }) => {
          return (
            <div className="pr-big">
              {row.original.canEdit
                ? TableTreeCheckbox(
                    row,
                    cell,
                    setViewOnlyState,
                    viewOnlyState,
                    `view_only_${row.original.id}`,
                    !row.original.canEdit,
                    false,
                    options,
                    false
                  )
                : "-"}
            </div>
          );
        },
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "editable",
        header: "Editable",
        size: 140,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <div className="pr-lg">
              {row.original.canEdit
                ? TableTreeCheckbox(
                    row,
                    cell,
                    setEditableState,
                    editableState,
                    `editable_${row.original.id}`,
                    !row.original.canEdit,
                    false,
                    options,
                    false
                  )
                : "-"}
            </div>
          );
        },
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    [editableState, viewOnlyState, data]
  );

  const { CatTableTemplate, CategoryColumn, TableTreeCheckbox } = CategoryTable(
    { rows: data?.propertyCategoryList, columns, t }
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    let formData = new FormData();
    const viewOnlyArray = Object.keys(viewOnlyState);
    const editableArray = Object.keys(editableState);

    const viewOnlyCategoryList = viewOnlyArray.map((value) => ({
      category_id: value,
      permission: 1,
    }));
    const editableCategoryList = editableArray.map((value) => ({
      category_id: value,
      permission: 2,
    }));

    const dataObj = {
      type: data?.created_by === 1 ? "PA" : "AP",
      email: data?.email ?? "",
      start_date: data?.start_date ?? "",
      end_date: data?.end_date ?? "",
      category_list: [...viewOnlyCategoryList, ...editableCategoryList],
    };

    formData.append(`auth_letter`, data?.auth_letter ?? "");

    formData.append("data", JSON.stringify(dataObj));

    await postForm(
      "appointment/create",
      null,
      formData,
      setError,
      `/${lng}/appointment-management`,
      false,
      null,
      token,
      null,
      null,
      true,
      false,
      null,
      true
    );
  };

  const created_by_option = [
    { value: 1, label: "Principal User" },
    { value: 2, label: "Agent" },
  ];

  if (userLoading) return <LoadingUI />;

  return (
    <AccountManagementTemplate
      lng={lng}
      title="Appointment Creation"
      noMenu={true}
    >
      <form onSubmit={handleSubmit} className="flex-col flex gap-xx-big">
        <div className="profile-field bg-white rounded-big flex flex-col gap-xxx-big">
          <ProfileSection t={t} title="Create Appointment By">
            <Switcher
              {...inputProps("created_by")}
              options={created_by_option}
              action={() => {
                setData(originalData);
              }}
            />
          </ProfileSection>
        </div>

        <div className="profile-field bg-white rounded-big flex flex-col">
          {data?.created_by === 1 && (
            <>
              <Name
                inputProps={inputProps}
                t={t}
                title="Principal User"
                company_name="corporateName"
                user_name="userName"
              />
              <Line my="my-x-big" />
            </>
          )}
          <SwitchAgent
            data={data}
            inputProps={inputProps}
            t={t}
            is_principal={data?.created_by === 1}
            updateFields={updateFields}
          />
          <Line my="my-x-big" />
          {data?.created_by === 2 && (
            <>
              <Name
                inputProps={inputProps}
                t={t}
                title="Agent User"
                company_name="corporateName"
                user_name="userName"
              />
              <Line my="my-x-big" />
              <Line my="my-x-big" />
            </>
          )}
          <EffectPeriod
            inputProps={inputProps}
            t={t}
            noEndDate={noEndDate}
            setNoEndDate={setNoEndDate}
          />
        </div>

        <div className="profile-field bg-white rounded-big flex flex-col gap-xxx-big">
          {CatTableTemplate("Property Categories", true)}
        </div>

        <div className="profile-field bg-white rounded-big flex flex-col gap-xxx-big">
          <FileDropzone
            title="Authorisation Letter"
            name="auth_letter"
            updateFields={updateFields}
            data={data ? data["auth_letter"] : ""}
            disabled={false}
            error={error ? error["auth_letter"] : ""}
            type={UPLOAD_DOC_TYPE.AUTHORISATION_LETTER}
            setError={setError}
            t={t}
          />
        </div>

        <div className="flex justify-between items-center mt-xxx-big">
          <ArrowBackButton href={`/${lng}/appointment-management`} t={t} />
          <BlueForwardButton
            loading={loading}
            t={t}
            label="Create Appointment"
            type="submit"
          />
        </div>
      </form>
    </AccountManagementTemplate>
  );
};

export default CorporateAccountCreation;
