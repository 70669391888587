import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import { ReactComponent as UploadSVG } from "../../assets/images/upload/file_upload.svg";
import { ReactComponent as CSVIcon } from "../../assets/images/upload/csv.svg";
import { ReactComponent as PdfSVG } from "../../assets/images/upload/pdf.svg";
import { ReactComponent as JpgSVG } from "../../assets/images/upload/imagery.svg";
import { ReactComponent as VideoSVG } from "../../assets/images/upload/video.svg";
import { ReactComponent as XlsSVG } from "../../assets/images/upload/xls.svg";
import UPLOAD_DOC_TYPE from "../../data/upload_doc_type.json";
import DOC_TYPE_OPTION from "../../data/document_type_options.json";
import UploadFilePreview from "../../components/upload/UploadFilePreview";
import MyLine from "../line/Line";
import { Line } from "rc-progress";
import axios from "axios";
import ErrorMessage from "../error/ErrorMessage";
import ProfileSection from "../my-profile/ProfileSection";
import { UploadButton } from "../button/SingleButton";
import SmallFunction from "../function/SmallFunction";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import { useTranslation } from "react-i18next";
import SubmitAndFetch from "../function/SubmitAndFetch";

const getUploadConfig = (
  type,
  UPLOAD_DOC_TYPE,
  DOC_TYPE_OPTION,
  current_file_length
) => {
  let acceptType = {};
  let numberOfUploading = "";
  let fileSize = 0;

  Object.entries(UPLOAD_DOC_TYPE).forEach(([key, value]) => {
    const docTypeOption = DOC_TYPE_OPTION.find(
      (option) => option.value === value
    );
    if (docTypeOption && docTypeOption.upload_info && type === value) {
      acceptType = docTypeOption.upload_info.type;
      numberOfUploading = current_file_length
        ? docTypeOption.upload_info.number_of_uploading - current_file_length
        : docTypeOption.upload_info.number_of_uploading;
      fileSize = parseFloat(docTypeOption.upload_info.file_size);
    }
  });

  return { acceptType, numberOfUploading, fileSize };
};

const generateUploadCaption = (
  acceptType,
  fileSize,
  numberOfUploading,
  current_file_length,
  t
) => {
  const supportedFormats = Object.keys(acceptType)
    .map((type) => type.split("/")[1].toUpperCase())
    .join(", ");

  const fileLimit =
    numberOfUploading + current_file_length > 1
      ? `${t("Up to")} ${numberOfUploading} ${t("files can be uploaded")}`
      : "";

  return `${t("Supports")} ${supportedFormats} ${t("formats")}. ${t(
    "File size up to"
  )} ${fileSize}MB. ${fileLimit}`.trim();
};

const FileDropzone = ({
  name,
  updateFields,
  uploadedFile,
  data,
  disabled,
  error,
  type = "image",
  status,
  progressBar = false,
  t,
  setError,
  title,
  uploadButtonText,
  showDeleteButton = true,
  apiProps,
  onResetState,
  current_file_length = 0,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [percent, setPercent] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [rejectFile, setRejectFile] = useState([]);
  const [noRequestMessage, setNoRequestMessage] = useState({});
  const [fileRejectionItems, setFileRejectionItems] = useState([]);
  const [controller, setController] = useState({});
  const [appendData, setAppendData] = useState({});
  const { token } = useUserLoginContext();
  const { handleUnits, getPunctuation } = SmallFunction();
  const { downloadData } = SubmitAndFetch({ lng: language, t });

  const { acceptType, numberOfUploading, fileSize } = getUploadConfig(
    type,
    UPLOAD_DOC_TYPE,
    DOC_TYPE_OPTION,
    current_file_length
  );
  const uploadCaption = generateUploadCaption(
    acceptType,
    fileSize,
    numberOfUploading,
    current_file_length,
    t
  );

  useEffect(() => {
    if (progressBar && !status) {
      resetUploadState();
    }
  }, [status, controller]);

  useEffect(() => {
    if (
      rejectFile &&
      !Object.entries(rejectFile)?.length &&
      error &&
      !Object.entries(error)?.length &&
      uploadedFile &&
      uploadedFile[name]
    ) {
      if (!Object.entries(uploadedFile[name]).length) {
        resetUploadState();
      }
    }

    if (onResetState?.onReset) {
      resetUploadState();
      onResetState?.setOnReset(false);
    }
  }, [uploadedFile, error, name, setError, onResetState]);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (!progressBar) {
        handleNonProgressBarUpload(acceptedFiles);
      } else {
        handleProgressBarUpload(acceptedFiles, fileRejections);
      }
    },
    [uploadedFiles, controller, percent, noRequestMessage, apiProps]
  );

  const { getRootProps, getInputProps, fileRejections, open } = useDropzone({
    onDrop,
    accept: acceptType,
    maxSize: fileSize * 1024 * 1024,
    multiple: numberOfUploading + current_file_length > 1,
    maxFiles: numberOfUploading,
  });

  const resetUploadState = () => {
    Object.values(controller).forEach((ctrl) => ctrl && ctrl.abort());
    setUploadedFiles({});
    setPercent({});
    setNoRequestMessage({});
    setError({});
    if (updateFields) updateFields({ [name]: {} });
  };

  const handleNonProgressBarUpload = (acceptedFiles) => {
    const uploadDateTime = new Date().toLocaleString();
    let updatedFields = {};

    if (setError) setError((prevError) => ({ ...prevError, [name]: "" }));

    acceptedFiles.forEach((item) => {
      item.updated_at = uploadDateTime;
      setUploadedFiles((prevState) => ({ ...prevState, [item.name]: item }));
      if (numberOfUploading + current_file_length > 1) {
        updatedFields[item.name] = item;
      } else {
        if (updateFields) updateFields({ [name]: item });
      }
    });

    if (Object.keys(updatedFields).length && updateFields) {
      updateFields({ [name]: { ...data, ...updatedFields } });
    }
  };

  const handleProgressBarUpload = async (acceptedFiles, fileRejections) => {
    const newControllers = { ...controller };
    const newPercent = { ...percent };
    const newNoRequestMessage = { ...noRequestMessage };
    const uploadDateTime = new Date().toLocaleString();
    let updatedFields = {};
    const newUploadFile = {};

    setError({});
    setRejectFile(fileRejections);

    acceptedFiles.forEach((file, index) => {
      newUploadFile[`${file.name}${index}`] = file;
    });
    setUploadedFiles((prevUploadFile) => ({
      ...prevUploadFile,
      ...newUploadFile,
    }));

    try {
      await Promise.all(
        acceptedFiles.map((file, index) =>
          uploadSingleFile(
            file,
            index,
            newControllers,
            newPercent,
            newNoRequestMessage,
            uploadDateTime,
            updatedFields
          )
        )
      );
      updateFields({ [name]: { ...updatedFields } });
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const uploadSingleFile = async (
    file,
    index,
    newControllers,
    newPercent,
    newNoRequestMessage,
    uploadDateTime,
    updatedFields
  ) => {
    const fileKey = `${file.name}${index}`;
    const formData = new FormData();
    if (apiProps && Object.entries(apiProps?.appandData).length) {
      Object.entries(apiProps?.appandData).forEach(([key, value]) => {
        formData?.append(key, value);
      });
    }
    formData?.delete("file");
    formData?.append("file", file);

    const newController = new AbortController();
    newControllers[fileKey] = newController;
    setController(newControllers);

    let timeoutId;

    const timeoutPromise = new Promise(
      (_, reject) =>
        (timeoutId = setTimeout(() => {
          newController.abort();
          newNoRequestMessage[fileKey] = t(
            "Request timed out, please try again later."
          );
          setNoRequestMessage(newNoRequestMessage);
          reject(new Error(`File ${file.name} upload timed out`));
        }, 120000))
    );

    try {
      const res = await Promise.race([
        axios.post(
          `${process.env.REACT_APP_PUBLIC_API_URL}/v1/${apiProps?.api_name}`,
          formData,
          {
            signal: newController.signal,
            headers: {
              "Accept-Language": language,
              Authorization: token,
            },
            onUploadProgress: ({ loaded, total }) => {
              const percentage = total
                ? Math.round((loaded * 100) / total) - 2
                : 0;
              newPercent[fileKey] = percentage;
              setPercent({ ...newPercent });
            },
          }
        ),
        timeoutPromise,
      ]);

      clearTimeout(timeoutId);

      delete newNoRequestMessage[fileKey];
      setNoRequestMessage({ ...newNoRequestMessage });

      const newData = res.data;
      if (newData?.code === 200) {
        let saveData = false;
        if (apiProps?.successAction) apiProps?.successAction(newData?.data);
        file.updated_at = uploadDateTime;
        if (newData?.data) {
          if (newData?.data?.invalidList?.length) {
            if (newData?.data?.allValidList.length) {
              saveData = true;
            }
            file.resData = newData?.data;
            setError({ invalidList: newData?.data?.invalidList.length });
          } else {
            saveData = true;
            file.resData = newData?.data;
          }
        }
        if (saveData) {
          updatedFields[fileKey] = file;
        }
      } else {
        setError(
          newData?.code === 201
            ? newData?.errors
            : {
                message:
                  newData?.message ||
                  t("Unknown error. Please try again later."),
              }
        );
      }
      newPercent[fileKey] = 100;
      setPercent({ ...newPercent });
    } catch (error) {
      console.error(`Error uploading file ${file.name}:`, error);
      setError({
        message: t(
          "An error occurred while uploading the file. Please try again later."
        ),
      });
      throw error;
    }
  };

  const handleCancelUpload = (fileKey) => {
    if (controller[fileKey]) {
      controller[fileKey].abort();
    }

    const newUploadFiles = { ...uploadedFiles };
    delete newUploadFiles[fileKey];
    setUploadedFiles(newUploadFiles);

    const newPercent = { ...percent };
    delete newPercent[fileKey];
    setPercent(newPercent);

    const newNoRequestMessage = { ...noRequestMessage };
    delete newNoRequestMessage[fileKey];
    setNoRequestMessage(newNoRequestMessage);

    if (updateFields) {
      const updatedFiles = data ? { ...data } : {};
      delete updatedFiles[fileKey];
      updateFields({ [name]: { ...updatedFiles } });
    }

    setFileRejectionItems([]);
  };

  const handleDeleteFile = (event, file_name) => {
    event.preventDefault();
    const newUploadFiles = { ...uploadedFiles };
    delete newUploadFiles[file_name];
    setUploadedFiles(newUploadFiles);
    if (updateFields) {
      if (numberOfUploading + current_file_length > 1) {
        updateFields({ [name]: { ...newUploadFiles } });
      } else {
        updateFields({ [name]: "" });
      }
    }
  };

  const getSvg = (type) => {
    const svgMap = {
      "application/pdf": PdfSVG,
      "image/jpeg": JpgSVG,
      "image/png": JpgSVG,
      "text/csv": CSVIcon,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        XlsSVG,
      "video/mp4": VideoSVG,
    };
    const SvgComponent = svgMap[type];
    return SvgComponent ? <SvgComponent style={{ width: "inherit" }} /> : null;
  };

  const handleDownloadInvalidList = async () => {
    const fileKey = Object.keys(uploadedFiles)[0];
    const file = uploadedFiles[fileKey];
    await downloadData(
      apiProps?.downloadInvalidListAPI,
      token,
      { ...apiProps?.appandData, file: file },
      apiProps?.withFile
    );
  };

  const downloadError = () => {
    return (
      <p className="body1">
        {apiProps?.error?.invalidList}{" "}
        {t("invalid Assessment Number(s). Review")}
        <a
          onClick={handleDownloadInvalidList}
          className="text-link cursor-pointer hover:underline label1"
        >
          {t(" here ")}
        </a>
        {t("and try again if necessary.")}
      </p>
    );
  };

  const uploadingProgress = () => {
    return (
      uploadedFiles &&
      Object.keys(uploadedFiles).map((fileKey, index) => {
        const file = uploadedFiles[fileKey];
        return (
          <React.Fragment key={fileKey}>
            {index > 0 && <MyLine />}
            <div className="hidden gap-sm md:flex">
              <div className="w-[32px]">{getSvg(file.type)}</div>
              <div className="flex flex-col gap-sm w-full">
                <div className="flex justify-between items-center">
                  <div className="flex flex-col md:flex-row md:items-center gap-sm">
                    <span className="label1 text-title">{file.path}</span>
                    <span className="caption1 text-placeholder">
                      {handleUnits(file.size)}
                    </span>
                  </div>
                  <div className="flex">
                    <span className="label2 text-placeholder mr-xx-sm md:flex items-center">
                      {percent[fileKey] ?? 0}%
                    </span>
                    {percent[fileKey] === 100 && (
                      <CheckCircleSharpIcon
                        width={20}
                        height={20}
                        style={{
                          color: "var(--label-icon-positive)",
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    )}
                  </div>
                </div>
                {noRequestMessage[fileKey] ||
                Object.entries(apiProps?.error).length ? (
                  <>
                    <Line
                      percent={100}
                      strokeWidth="1"
                      strokeColor="var(--label-icon-warning)"
                      trailColor="var(--surface-secondary)"
                      strokeLinecap="square"
                    />
                    <span className="text-label-icon-warning flex gap-x-sm">
                      {apiProps?.error.invalidList ? (
                        <ErrorMessage
                          t={t}
                          errorClass="text-label-icon-warning"
                          downloadError={downloadError()}
                        />
                      ) : noRequestMessage[fileKey] ||
                        apiProps?.error.message ? (
                        <ErrorMessage
                          message={
                            noRequestMessage[fileKey] || apiProps?.error.message
                          }
                          t={t}
                          errorClass="text-label-icon-warning"
                        />
                      ) : null}
                      {apiProps?.error.file && (
                        <ErrorMessage
                          message={apiProps?.error.file}
                          t={t}
                          errorClass="text-label-icon-warning"
                        />
                      )}
                    </span>
                  </>
                ) : (
                  <Line
                    percent={percent[fileKey] ?? 0}
                    strokeWidth="1"
                    strokeColor="var(--border-highlight)"
                    trailColor="var(--surface-secondary)"
                    strokeLinecap="square"
                  />
                )}
              </div>
              <div
                onClick={() => handleCancelUpload(fileKey)}
                className="h-fit cursor-pointer"
                title={t("remove file")}
              >
                <CancelOutlinedIcon
                  width={30}
                  style={{ color: "var(--label-icon-default)" }}
                />
              </div>
            </div>
            {/* Mobile view */}
            <div className="flex flex-col gap-big md:hidden">
              {/* ... (mobile view implementation, similar to desktop view) */}
            </div>
          </React.Fragment>
        );
      })
    );
  };

  const fileUploadButton = () => {
    return (
      <UploadButton
        t={t}
        action={open}
        label={uploadButtonText}
        outline={true}
      />
    );
  };

  const renderUploadContent = () => {
    if (progressBar && Object.entries(uploadedFiles).length) {
      return uploadingProgress();
    } else if (
      !progressBar &&
      numberOfUploading + current_file_length > 1 &&
      uploadedFiles &&
      Object.entries(uploadedFiles).length
    ) {
      return renderMultipleUploads();
    } else if (
      !progressBar &&
      numberOfUploading + current_file_length <= 1 &&
      data &&
      Object.entries(data).length
    ) {
      return renderSingleUpload();
    } else {
      return renderDropzone();
    }
  };

  const renderMultipleUploads = () => {
    return Object.entries(uploadedFiles).map(([key, item]) => (
      <React.Fragment key={key}>
        <UploadFilePreview
          t={t}
          data={item}
          document_type={apiProps?.document_type}
          downloadApiProps={apiProps?.downloadApiProps}
          handleDeleteFile={
            !disabled && showDeleteButton
              ? (event) => handleDeleteFile(event, key)
              : null
          }
          disabled={disabled}
        />
        {fileRejectionItems}
        {error && <ErrorMessage error={error} t={t} />}
      </React.Fragment>
    ));
  };

  const renderSingleUpload = () => {
    return (
      <>
        <UploadFilePreview
          t={t}
          data={data}
          document_type={apiProps?.document_type}
          downloadApiProps={apiProps?.downloadApiProps}
          handleDeleteFile={
            !disabled && showDeleteButton ? handleDeleteFile : null
          }
          disabled={disabled}
        />
        {fileRejectionItems}
        {error && <ErrorMessage error={error} t={t} />}
      </>
    );
  };

  const renderDropzone = () => {
    return (
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} name={name} />
        <div
          className="flex flex-col items-center gap-xx-sm md:h-189px bg-50 py-x-big px-md justify-center rounded-x-sm text-center"
          style={{ border: "3px dashed var(--Neutral-200, #E5E5E5)" }}
        >
          <span>
            <UploadSVG width="40px" />
          </span>
          <span className="body2 text-800">
            {t("Drop or click to upload file")}
          </span>
          <span className="caption1 text-placeholder w-full text-center">
            {t(uploadCaption)}
            {fileRejectionItems}
            {error && (
              <ErrorMessage error={error} t={t} errorClass="text-center" />
            )}
          </span>
        </div>
      </div>
    );
  };

  const generateFileRejectionItems = (fileRejections) => {
    const uniqueErrors = new Set();

    fileRejections.forEach(({ errors }) => {
      errors.forEach((err) => {
        let errorMessage = "";
        switch (err.code) {
          case "file-too-large":
            errorMessage = `${t(
              "File size exceeds the limit. Maximum file size allowed is"
            )} ${fileSize} MB`;
            break;
          case "file-invalid-type":
            const acceptedTypes = Object.keys(acceptType).map((type) =>
              type.split("/")[1].toUpperCase()
            );

            let acceptedTypesString;
            if (language === "en") {
              acceptedTypesString = acceptedTypes.join(" or ");
            } else {
              if (acceptedTypes.length > 1) {
                const lastType = acceptedTypes.pop();
                acceptedTypesString =
                  acceptedTypes.join("，") + "或" + lastType;
              } else {
                acceptedTypesString = acceptedTypes[0];
              }
            }

            errorMessage = `${t(
              "File type must be"
            )} ${acceptedTypesString}${getPunctuation("full_stop", t)}`;
            break;
          case "too-many-files":
            errorMessage = t("You can only upload {{count}} file(s)", {
              count: numberOfUploading,
            });
            break;
          default:
            errorMessage = t(err.message);
            break;
        }
        uniqueErrors.add(errorMessage);
      });
    });
    return (
      <ul className="text-label-icon-warning">
        {Array.from(uniqueErrors).map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    if (fileRejections) {
      setFileRejectionItems(generateFileRejectionItems(fileRejections));
    }
  }, [fileRejections, fileSize, numberOfUploading]);

  return (
    <section className="container flex flex-col">
      <ProfileSection
        title={title}
        button={uploadButtonText ? fileUploadButton() : null}
        t={t}
      >
        {renderUploadContent()}
      </ProfileSection>
    </section>
  );
};

export default FileDropzone;
