import { ReactComponent as Logo } from "../../assets/images/header/en_logo.svg";
import { ReactComponent as TCLogo } from "../../assets/images/header/tc_logo.svg";
import { ReactComponent as SCLogo } from "../../assets/images/header/sc_logo.svg";
import { ReactComponent as MobileLogo } from "../../assets/images/header/mobile_logo.svg";
import background from "../../assets/images/login/login_bg.png";
import React, {useEffect, useState} from "react";
import { useCookies } from "react-cookie";
import LanguageAndFontSize from "../../components/header/LanguageAndFontSize";
import { useTranslation } from "react-i18next";
import Input from "../../components/input/Input";
import Password from "../../components/input/Password";
import Checkbox from "../../components/input/Checkbox";
import {
  IAmSmartButton,
  PrimaryButton,
} from "../../components/button/SingleButton";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import ErrorMessage from "../../components/error/ErrorMessage";
import { AddDataToHTML } from "../../assets/js/global";
import useDocumentTitle from "../../hooks/useDocument";

const Login = ({ lng }) => {
  const {
    t,
    i18n: { changeLanguage },
  } = useTranslation();
  const [cookies, setCookie] = useCookies(["access_token"]);
  const { postForm, loading } = SubmitAndFetch({ lng, t });
  const [error, setError] = useState({});
  useDocumentTitle("Login");

  const successAction = (resData) => {
    if (resData) {
      localStorage.setItem("userData", JSON.stringify(resData.profile));
      setCookie("access_token", resData.token, {
        secure: true,
        sameSite: "strict",
        path: "/",
      });
      // AddDataToHTML('lang', resData?.profile?.langDisplay?.key)
      // changeLanguage(resData?.profile?.langDisplay?.key)
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    await postForm(
      "login",
      null,
      formData,
      setError,
      `/${lng}/`,
      false,
      successAction
    );
  };

  const inputProps = (name, inputLabel) => ({
    name,
    inputLabel,
    t,
    error: error ? error[name] : "",
    setError,
  });

  return (
    <div
      className="login-container min-h-screen min-w-screen flex"
      style={{
        background: `url(${background}) center`,
        backgroundSize: "cover",
      }}
    >
      <div
        className="login-left flex flex-col gap-xxx-big min-h-screen p-md items-center md:px-xx-lg lg:pt-xx-lg lg:rounded-tr-lg lg:rounded-br-lg lg:pb-x-big lg:px-huge backdrop-blur-blur-30px w-full lg:max-w-800px"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.80)" }}
      >
        <div className="flex justify-center w-full lg:block">
          <div className="flex flex-col gap-lg w-full">
            <div className="w-full flex flex-wrap justify-between gap-md">
              <a className="hidden sm:block" href={t("rvd_home_url")}>
                {lng === "tc" ? (
                  <TCLogo
                    alt="Rating and Valuation Department"
                    width="408"
                    height="100%"
                  />
                ) : lng === "sc" ? (
                  <SCLogo
                    alt="Rating and Valuation Department"
                    width="408"
                    height="100%"
                  />
                ) : (
                  <Logo
                    alt="Rating and Valuation Department"
                    width="408"
                    height="100%"
                  />
                )}
              </a>
              <a className="inline-block sm:hidden" href={t("rvd_home_url")}>
                <MobileLogo width="90" height="100%" />
              </a>
              <div className="inline-flex ml-auto lg:hidden">
                <LanguageAndFontSize />
              </div>
            </div>
            <div className="flex flex-col gap-sm">
              <h2 className="h2">{t("Login")}</h2>
              <p className="body1">{t("Welcome to the RVD Web Portal")}</p>
            </div>
            <form className="flex flex-col gap-xx-big" onSubmit={handleSubmit}>
              <div className="flex flex-col gap-xx-big">
                <div className="flex flex-col gap-big">
                  <Input {...inputProps("username", "Username/Email")} />
                  <Password
                    {...inputProps("password", "Password")}
                    showEyeIcon={true}
                  />
                  {error && error.message && (
                    <ErrorMessage message={error.message} t={t} />
                  )}
                </div>
                <div className="flex gap-md label1 flex-col md:flex-row md:justify-between md:items-center">
                  <Checkbox
                    {...inputProps("is_remember", "Remember Me")}
                    check={"1"}
                    itemStart={false}
                    labelClass="label1"
                  />
                  <div className="label1">
                    {t("Forgot")}&nbsp;
                    <a
                      href={`/portal/${lng}/forgot-username`}
                      className="text-surface-primary hover:text-surface-primary-hover"
                    >
                      {t("Username")}
                    </a>
                    &nbsp;{t("or")}&nbsp;
                    <a
                      href={`/portal/${lng}/forgot-password`}
                      className="text-surface-primary hover:text-surface-primary-hover"
                    >
                      {t("Password")}
                    </a>
                    ?
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-sm links">
                <PrimaryButton
                  t={t}
                  type="submit"
                  loading={loading}
                  label="Login"
                  className="w-full ms-0"
                />
                <IAmSmartButton t={t} href="" label="Continue with iAM Smart" />
                <a
                  className="outline-btn line-height whitespace-nowrap rounded-sm flex items-center justify-center gap-x-sm"
                  href={`/portal/${lng}/activate-account`}
                >
                  <span className="label1 leading-none">
                    {t("Activate Account")}
                  </span>
                </a>
              </div>
            </form>
            <div className="flex flex-col gap-sm text-center links mt-xxx-big">
              <p className="body1">{t("Don't have an account")}?</p>
              <a
                className="label1 blue-50-btn"
                href={`/portal/${lng}/registration`}
              >
                {t("Sign Up Now")}
              </a>
            </div>
            <div className="flex gap-sm flex-col">
              <div
                style={{ width: "100%", height: "1px", background: "#737373" }}
              ></div>
              <p className="caption1 text-caption">
                {t("2024 © Rating and Valuation Department")} |{" "}
                {t("Last revision date: 31 October 2024")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="login-right flex-1 hidden lg:block lg:pt-xx-lg lg:pb-x-big lg:pr-xx-huge ml-lg">
        <div className="w-full flex justify-end ">
          <LanguageAndFontSize />
        </div>
      </div>
    </div>
  );
};

export default Login;
