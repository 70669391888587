import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  inputLabelClasses,
  selectClasses,
} from "@mui/material";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import ErrorMessage from "../../components/error/ErrorMessage";

const AreaAndDistrict = ({
  updateFields,
  areaData,
  districtData,
  subDistrictData,
  disabled,
  AreaError,
  districtError,
  subDistrictError,
  required,
  setError,
  lng,
  readonly = false,
  t,
}) => {
  const [areaAndDistrict, setAreaAndDistrict] = useState([]);
  const [selectedArea, setSelectedArea] = useState(areaData || "");
  const [selectedDistrict, setSelectedDistrict] = useState(districtData || "");
  const [selectedSubDistrict, setSelectedSubDistrict] = useState(
    subDistrictData || ""
  );
  const [districtDisabled, setDistrictDisabled] = useState(true);
  const [subDistrictDisabled, setSubDistrictDisabled] = useState(true);
  const lang = lng[0].toUpperCase() + lng.slice(1);
  const { fetchData } = SubmitAndFetch({ lng, t });

  useEffect(() => {
    fetchData("district", null, setAreaAndDistrict, null, lng);
  }, []);

  useEffect(() => {
    if (areaData) {
      setDistrictDisabled(false);
      if (districtData) {
        setSubDistrictDisabled(false);
      }
    } else {
      updateFields?.({ address_area: "" });
      setDistrictDisabled(true);
      setSubDistrictDisabled(true);
      setSelectedArea("");
      setSelectedDistrict("");
      setSelectedSubDistrict("");
    }
  }, [areaData, districtData, subDistrictData]);

  const handleChange = (e, item) => {
    const value = e.target.value;
    if (item === "address_area") {
      setSelectedArea(value);
      setDistrictDisabled(false);
      setSelectedDistrict("");
      setSelectedSubDistrict("");
      setSubDistrictDisabled(true);
    } else if (item === "address_district") {
      setSelectedDistrict(value);
      setSubDistrictDisabled(false);
      setSelectedSubDistrict("");
    } else {
      setSelectedSubDistrict(value);
    }
    updateFields?.({ [item]: value });
    setError?.((prevError) => ({ ...prevError, [item]: "" }));
  };

  const getDistricts = () => {
    const area = areaAndDistrict.find((a) => a.id === selectedArea);
    return area ? area.districts : [];
  };

  const getSubDistricts = () => {
    const district = getDistricts().find((d) => d.id === selectedDistrict);
    return district ? district.subDistricts : [];
  };

  const renderSelect = (
    id,
    label,
    value,
    onChange,
    options,
    error,
    isDisabled
  ) => {
    const is_sub = id === "address_sub_district";
    const is_empty = options.length === 0;
    return (
      <FormControl variant="filled" className="w-full">
        <InputLabel
          error={error}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "var(--text-caption)",
            },
            color: "var(--text-body)",
            [`&.Mui-error.${inputLabelClasses.focused}`]: {
              [`&.${inputLabelClasses.shrink}`]: {
                color: "var(--text-warning)",
              },
            },
            [`&.${inputLabelClasses.focused}`]: {
              [`&.${inputLabelClasses.shrink}`]: {
                color: "var(--label-icon-on-light)",
              },
            },
            [`&.${inputLabelClasses.shrink}`]: {
              color: "var(--text-caption)",
            },
            [`&.Mui-error.${inputLabelClasses.shrink}`]: {
              color: "var(--text-warning)",
            },
          }}
        >
          {t(label)}
        </InputLabel>
        <Select
          required={required}
          id={id}
          name={id}
          value={value}
          onChange={onChange}
          className={`${
            readonly ? "readonly" : ""
          } body1 text-body w-full rounded-sm`}
          error={error}
          disabled={disabled || isDisabled || (is_sub && is_empty)}
          MenuProps={{
            PaperProps: {
              className: "custom-scrollbar p-x-sm",
              style: {
                maxHeight: "322px",
                marginTop: "var(--spacing-sm)",
                overflowY: "auto",
                background: "var(--surface-card)",
                borderRadius: "var(--radius-sm)",
                boxShadow: "var(--box-shadow-lg)",
              },
            },
            MenuListProps: { style: { padding: "0" } },
          }}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "var(--text-body)",
              background: "var(--surface-card)",
              borderRadius: "var(--radius-x-sm)",
            },
            [`&.${selectClasses.disabled}`]: {
              ":before": {
                content: "none",
              },
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
              borderRadius: "var(--radius-x-sm)",
            },
            "& fieldset": { border: "none" },
            background: "var(--surface-base)",
            ":before": { borderBottomColor: "var(--Neutral-300)" },
            ":after": { borderBottomColor: "var(--surface-primary)" },
          }}
        >
          {options.length > 0 ? (
            options.map((option) => (
              <MenuItem
                value={option.id}
                key={option.id}
                sx={{
                  "&:hover": {
                    "&.Mui-selected": {
                      background: "var(--surface-secondary)",
                    },
                    background: "var(--surface-secondary)",
                  },
                  "&.MuiList-root": { padding: "0" },
                  "&.Mui-selected": {
                    color: "var(--text-body) !important",
                    background: "none",
                  },
                  maxWidth: "100%",
                  whiteSpace: "normal",
                  display: "flex",
                  gap: "var(--spacing-md)",
                  padding: "var(--spacing-x-sm)",
                  borderRadius: "var(--radius-x-sm)",
                }}
              >
                {option[`name${lang}`]}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="" disabled>
              <em>{t("No Data")}</em>
            </MenuItem>
          )}
        </Select>
        <ErrorMessage
          error={error}
          t={t}
          optional={id === "address_sub_district"}
        />
      </FormControl>
    );
  };

  return (
    <div className="flex-col flex md:flex-row gap-x-big">
      {renderSelect(
        "address_area",
        "Area",
        selectedArea,
        (e) => handleChange(e, "address_area"),
        areaAndDistrict,
        AreaError,
        false
      )}
      {renderSelect(
        "address_district",
        "District",
        selectedDistrict,
        (e) => handleChange(e, "address_district"),
        getDistricts(),
        districtError,
        districtDisabled
      )}
      {renderSelect(
        "address_sub_district",
        "Sub-district",
        selectedSubDistrict,
        (e) => handleChange(e, "address_sub_district"),
        getSubDistricts(),
        subDistrictError,
        subDistrictDisabled
      )}
    </div>
  );
};

export default AreaAndDistrict;
