import React, { useRef, useState } from "react";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { ReactComponent as DownArrowSVG } from "../../assets/images/arrow/down_arrow.svg";
import { ReactComponent as RightArrowSVG } from "../../assets/images/arrow/right_arrow.svg";
import { ReactComponent as FolderSVG } from "../../assets/images/categort/category-cat.svg";
import { ReactComponent as CorporateSVG } from "../../assets/images/categort/category-corporate.svg";
import { ReactComponent as IndividualSVG } from "../../assets/images/categort/category-individual.svg";
import { ReactComponent as UnCatSVG } from "../../assets/images/categort/category-uncat.svg";
import { ReactComponent as FullAccessSVG } from "../../assets/images/categort/full-access.svg";
import { ReactComponent as SharedSVG } from "../../assets/images/categort/Appointments.svg";
import { ReactComponent as EditableSVG } from "../../assets/images/categort/editable.svg";
import { ReactComponent as ViewOnlySVG } from "../../assets/images/categort/visibility.svg";
import ProfileSection from "../../components/my-profile/ProfileSection";
import DataTable from "../../components/table/DataTable";
import RoundedCheckbox from "../../components/input/RoundedCheckbox";
import PERMISSIONS from "../../data/property_permission.json";

const CategoryTable = ({ rows, columns, rowSelection, setRowSelection, t }) => {
  const expandButton = useRef([]);
  const [allExpanded, setAllExpanded] = useState(false);

  const CollapseAllButton = () => {
    const handleCollapseExpand = () => {
      const shouldExpand = !allExpanded;
      Object.values(expandButton.current).forEach((button) => {
        if (button) {
          const isExpanded =
            button.querySelector(".down_arrow_button") !== null;
          if ((shouldExpand && !isExpanded) || (!shouldExpand && isExpanded)) {
            button.click();
          }
        }
      });
      setAllExpanded(shouldExpand);
    };
    return (
      <>
        <button
          className="text-label-icon-on-light label1 flex gap-sm items-center"
          onClick={handleCollapseExpand}
          type="button"
        >
          <span>{allExpanded ? "Collapse All" : "Expand All"}</span>
          <div className="w-sm hover:text-surface-primary-hover">
            {allExpanded ? (
              <UnfoldLessIcon style={{ width: "18px", height: "18px" }} />
            ) : (
              <UnfoldMoreIcon style={{ width: "18px", height: "18px" }} />
            )}
          </div>
        </button>
      </>
    );
  };

  const CategoryColumn = (
    row,
    cell,
    account_type,
    folderSVG,
    showParentArrow = true
  ) => {
    const isParentRow = row.original.children && row.original.children.length;
    const isExpanded = row.getIsExpanded();
    const handleExpandButtonClick = () => {
      let allSubRowsExpanded = true;
      Object.values(expandButton.current).forEach((button) => {
        if (button) {
          const isExpanded =
            button.querySelector(".down_arrow_button") !== null;
          if (!isExpanded) {
            allSubRowsExpanded = false;
          }
        }
      });

      if (allSubRowsExpanded) {
        setAllExpanded(true);
      }
    };

    const renderExpandBtn = () => {
      return (
        <button
          ref={(el) => (expandButton.current[row.id] = el)}
          type="button"
          className={isParentRow ? "opacity-100" : "opacity-0"}
        >
          {isExpanded ? (
            <DownArrowSVG width={24} className="down_arrow_button" />
          ) : (
            <RightArrowSVG width={24} className="right_arrow_button" />
          )}
        </button>
      );
    };

    const renderExpandButtonWithIcon = (IconComponent) => (
      <>
        {!folderSVG ? showParentArrow && renderExpandBtn() : renderExpandBtn()}
        <IconComponent width={32} className="expand_arrow_button" />
      </>
    );

    const renderIconForRowDepth = (rowDepth) => {
      const number = !showParentArrow ? 56 : 32;
      const marginSize = 80 * rowDepth - number * rowDepth - 8;
      return (
        <div
          style={
            isParentRow && !showParentArrow && rowDepth === 1
              ? {}
              : { marginLeft: `${marginSize}px` }
          }
          className="flex gap-md"
        >
          {renderExpandButtonWithIcon(FolderSVG)}
        </div>
      );
    };

    const renderIconBasedOnAccountType = () => {
      if (Number(account_type) === 2 && !folderSVG) {
        return <CorporateSVG width={32} className="ms-lg" />;
      } else if (Number(account_type) === 1 && !folderSVG) {
        return <IndividualSVG width={32} className="ms-lg" />;
      } else {
        return <FolderSVG width={32} className="ms-lg" />;
      }
    };

    const renderCatSVG = ({ row }) => {
      const rowDepth = row.depth;

      return (
        <>
          {isParentRow ? (
            row.original.rowType === "shared_parent" ? (
              renderExpandButtonWithIcon(CorporateSVG)
            ) : Number(account_type) === 2 && !folderSVG ? (
              renderExpandButtonWithIcon(CorporateSVG)
            ) : Number(account_type) === 1 && !folderSVG ? (
              renderExpandButtonWithIcon(IndividualSVG)
            ) : (
              renderIconForRowDepth(rowDepth)
            )
          ) : row.original.rowType === "uncategorized" ? (
            <UnCatSVG width={32} className="ms-xxx-lg expand_arrow_button" />
          ) : rowDepth > 0 ? (
            renderIconForRowDepth(rowDepth)
          ) : (
            renderIconBasedOnAccountType()
          )}
        </>
      );
    };

    return (
      <div>
        <div
          className="flex gap-md items-center cursor-pointer"
          onClick={() => {
            handleExpandButtonClick();
            if (row.original.rowType === "parent") {
              if (showParentArrow) row.toggleExpanded();
            } else {
              row.toggleExpanded();
            }
          }}
        >
          {renderCatSVG({ row })}
          <span className="label1 text-black">
            {cell.getValue()}{" "}
            {row.original.alias ? ` (${row.original.alias}) ` : ""}
          </span>
        </div>
      </div>
    );
  };

  const CatTableTemplate = (
    title,
    showCollapseButton,
    needMargin,
    options,
    enableSelection,
    selectAll
  ) => {
    return (
      <>
        {/*{title ?*/}
        <ProfileSection
          title={title}
          button={showCollapseButton ? CollapseAllButton() : null}
          t={t}
        >
          <DataTable
            columns={columns}
            data={rows}
            enableExpanding={true}
            enablePagination={false}
            enableSelection={enableSelection}
            selectAll={selectAll}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            showFooter={false}
          />
        </ProfileSection>
        {/*     : <PropertyTable columns={columns} data={rows} enableExpanding={true} enablePagination={false} needMargin={needMargin} options={options}/>*/}
        {/*}*/}
      </>
    );
  };

  const TableTreeCheckbox = (
    row,
    cell,
    setState,
    state,
    name,
    disabled,
    checked,
    options,
    multiple = true
  ) => {
    const id = row.original.id;

    const handleCheckboxChange = (id, children) => (e) => {
      const saveSelection = () => {
        const checked = e.target.checked;
        const newCheckboxState = { ...state };
        newCheckboxState[id] = checked;
        setState(newCheckboxState);
      };
      if (multiple) {
        saveSelection();
      } else if (!multiple && options) {
        options.forEach((option) => {
          if (name.startsWith(option.name)) {
            saveSelection();
          } else {
            option.stateName[id] = false;
          }
        });
      }
    };

    return (
      <RoundedCheckbox
        name={name ? name : id}
        data={state[id]}
        check={checked}
        action={handleCheckboxChange(id, row.original.children || [])}
        disabled={disabled}
      />
    );
  };

  const AccessIcon = (row, cell, shareCategory = false) => {
    const accessLevel = Number(cell.getValue());

    const accessStyle = (Icon, text) => (
      <div className="flex gap-sm">
        <div>
          <Icon width={24} />
        </div>
        <div className="label1 text-body">{text}</div>
      </div>
    );

    if (shareCategory) {
      PERMISSIONS.VIEW_ONLY = 1;
      PERMISSIONS.EDITABLE = 2;
    }

    const isSharedParent =
      row.original.rowType === "shared_parent" ||
      (shareCategory && row.original.rowType === "parent");
    const isFullAccess =
      accessLevel === PERMISSIONS.FULL_ACCESS &&
      (row.depth === 0 || row.original.rowType === "child") &&
      !shareCategory;
    const isShared = accessLevel === PERMISSIONS.SHARE && !shareCategory;
    const isEditable = accessLevel === PERMISSIONS.EDITABLE;
    const isViewOnly =
      (accessLevel === PERMISSIONS.VIEW_ONLY ||
        accessLevel === PERMISSIONS.DISPLAY_ONLY) &&
      row.original.rowType !== "uncategorized";

    if (isSharedParent) return accessStyle(SharedSVG, "Shared");
    if (isFullAccess) return accessStyle(FullAccessSVG, "Full Access");
    if (isShared) return accessStyle(SharedSVG, "Shared");
    if (isEditable) return accessStyle(EditableSVG, "Editable");
    if (isViewOnly) return accessStyle(ViewOnlySVG, "View Only");

    return null;
  };

  return {
    CatTableTemplate,
    CategoryColumn,
    TableTreeCheckbox,
    CollapseAllButton,
    AccessIcon,
  };
};

export default CategoryTable;
