import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import HomeLayout from "./layout/HomeLayout";
import LayoutBeforeLogin from "./layout/LayoutBeforeLogin";
import LayoutAfterLogin from "./layout/LayoutAfterLogin";
import PropertyLayout from "./layout/PropertyLayout";

import AdminRoute from "./context/AdminRoute";

import HomePage from "./pages/home/HomePage";
import QuickLinkPage from "./pages/home/QuickLinkPage";
import LoginPage from "./pages/login/LoginPage";
import RegistrationPage from "./pages/registration/RegistrationPage";
import ForgotPasswordPage from "./pages/forgot-password/ForgotPasswordPage";
import ForgotUsernamePage from "./pages/forgot-username/ForgotUsernamePage";
import ActivateAccountPage from "./pages/activate-account/ActivateAccountPage";
import SuccessfullyPage from "./pages/successfully/SuccessfullyPage";
import MyProfilePage from "./pages/my-profile/MyProfilePage";
import CorporateAccountPage from "./pages/corporate-accounts-management/CorporateAccountPage";
import CorporateAccountCreationPage from "./pages/corporate-accounts-management/CorporateAccountCreationPage";
import CorporateAccountDetailsPage from "./pages/corporate-accounts-management/CorporateAccountDetailsPage";
import AppointmentManagementPage from "./pages/appointment-management/AppointmemtManagementPage";
import AppointmentCreationPage from "./pages/appointment-management/AppointmentCreationPage";
import AppointmentDetailsPage from "./pages/appointment-management/AppointmentDetailsPage";
import PropertyListPage from "./pages/property-list/PropertyListPage";
import AllPropertyPage from "./pages/property-list/AllPropertyPage";
import ViewPropertyPage from "./pages/property-list/ViewPropertyPage";
import PersonalisationPage from "./pages/personalisation/PersonalisationPage";
import AddPropertyPage from "./pages/property-list/AddPropertyPage";
import PropertyDetailsPage from "./pages/property-list/PropertyDetailsPage";
import MyDocumentPage from "./pages/my-document/MyDocumentPage";
import ProposalSubmissionPage from "./pages/proposal-submission/ProposalSubmissionPage";
import TransactionHistoryPage from "./pages/transaction-history/TransactionHistoryPage";
import TransactionHistoryDetailsPage from "./pages/transaction-history/TransactionHistoryDetailsPage";
import SubmittedDocumentsPage from "./pages/submitted-documents/SubmittedDocumentsPage";
import SubmitDocumentPage from "./pages/submitted-documents/SubmitDocumentPage";
import NotificationCentrePage from "./pages/notification-centre/NotificationCentrePage";
import NotificationCentreDetailsPage from "./pages/notification-centre/NotificationCentreDetailsPage";
import HelpCentrePage from "./pages/help-centre/HelpCentrePage";
import GettingStartedPage from "./pages/help-centre/GettingStartedPage";
import FAQPage from "./pages/help-centre/FAQPage";
import SearchResult from "./pages/help-centre/SearchResult";
import ArticlePage from "./pages/help-centre/ArticlePage";
import ArticleDetailsPage from "./pages/help-centre/ArticleDetailsPage";

import NotFoundPage from "./pages/error/NotFoundPage";
import NoPermissionPage from "./pages/error/NoPermissionPage";
import IAmSmartLogin from "./iamsmart/login";

function App() {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Router basename="/portal">
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route path="/:language" element={<HomePage lng={language} />} />
          <Route index element={<HomePage lng={language} />} />
          <Route
            path="/:language/quick-links"
            element={<QuickLinkPage lng={language} />}
          />
        </Route>

        <Route
          path={`/:language/login`}
          element={<LoginPage lng={language} />}
        />

        <Route path="/" element={<LayoutBeforeLogin />}>
          <Route
            path="/:language/registration"
            element={<RegistrationPage lng={language}/>}
          />
          <Route
            path="/:language/forgot-password"
            element={<ForgotPasswordPage lng={language} />}
          />
          <Route
            path="/:language/forgot-username"
            element={<ForgotUsernamePage lng={language} />}
          />
          <Route
            path="/:language/activate-account"
            element={<ActivateAccountPage lng={language} />}
          />
          <Route
            path="/:language/successfully"
            element={<SuccessfullyPage lng={language} />}
          />
        </Route>

        <Route path="/" element={<LayoutAfterLogin />}>
          <Route
            path="/:language/my-profile"
            element={<MyProfilePage lng={language} />}
          />
          <Route
            path="/:language/corporate-accounts-management"
            element={<AdminRoute />}
          >
            <Route index element={<CorporateAccountPage lng={language} />} />
            <Route
              path="corporate-account-creation"
              element={<CorporateAccountCreationPage lng={language} />}
            />
            <Route path="account-details">
              <Route
                index
                element={
                  <Navigate to={`/${language}/corporate-accounts-management`} />
                }
              />
              <Route
                path=":accountNo"
                element={<CorporateAccountDetailsPage lng={language} />}
              />
            </Route>
          </Route>

          <Route path="/:language/property-list" element={<PropertyLayout />}>
            <Route index element={<PropertyListPage lng={language} />} />
            <Route
              path="all-properties"
              element={<AllPropertyPage lng={language} />}
            />
            <Route path="view-property">
              <Route
                index
                element={<Navigate to={`/${language}/property-list`} />}
              />
              <Route path=":id" element={<ViewPropertyPage lng={language} />} />
              <Route path="add">
                <Route
                  index
                  element={<Navigate to={`/${language}/property-list`} />}
                />
                <Route
                  path=":id"
                  element={<AddPropertyPage lng={language} />}
                />
              </Route>
              <Route path="edit">
                <Route
                  index
                  element={<Navigate to={`/${language}/property-list`} />}
                />
                <Route
                  path=":pid"
                  element={<PropertyDetailsPage lng={language} />}
                />
              </Route>
            </Route>
          </Route>

          <Route
            path="/:language/personalisation"
            element={<PersonalisationPage lng={language} />}
          />

          <Route path="/:language/appointment-management">
            <Route
              index
              element={<AppointmentManagementPage lng={language} />}
            />
            <Route
              path="appointment-creation"
              element={<AppointmentCreationPage lng={language} />}
            />
            <Route path="appointment-details">
              <Route
                index
                element={
                  <Navigate to={`/${language}/appointment-management`} />
                }
              />
              <Route
                path=":id"
                element={<AppointmentDetailsPage lng={language} />}
              />
            </Route>
          </Route>

          {/*not yet*/}

          <Route path="/:language/help-centre">
            <Route index element={<HelpCentrePage lng={language} />} />
            <Route
              path="getting-started"
              element={<GettingStartedPage lng={language} />}
            />
            <Route path="faqs" element={<FAQPage lng={language} />} />
            <Route
              path="search-result"
              element={<SearchResult lng={language} />}
            />
            <Route path="articles">
              <Route index element={<ArticlePage lng={language} />} />
              <Route path="details">
                <Route
                  index
                  element={<Navigate to={`/${language}/help-centre`} />}
                />
                <Route
                  path=":id"
                  element={<ArticleDetailsPage lng={language} />}
                />
              </Route>
            </Route>
          </Route>

          <Route path="/:language/proposal-submission">
            <Route index element={<ProposalSubmissionPage lng={language} />} />
          </Route>

          <Route path="/:language/notification-centre">
            <Route index element={<NotificationCentrePage lng={language} />} />
            <Route path="details">
              <Route
                index
                element={<Navigate to={`/${language}/notification-centre`} />}
              />
              <Route
                path=":id"
                element={<NotificationCentreDetailsPage lng={language} />}
              />
            </Route>
          </Route>

          <Route path="/:language/transaction-history">
            <Route index element={<TransactionHistoryPage lng={language} />} />
            <Route path="details">
              <Route
                index
                element={<Navigate to={`/${language}/transaction-history`} />}
              />
              <Route
                path=":id"
                element={<TransactionHistoryDetailsPage lng={language} />}
              />
            </Route>
          </Route>

          <Route path="/:language/my-document">
            <Route index element={<MyDocumentPage lng={language} />} />
          </Route>

          <Route path="/:language/submitted-documents">
            <Route index element={<SubmittedDocumentsPage lng={language} />} />
            <Route
              path="submit-document"
              element={<SubmitDocumentPage lng={language} />}
            />
          </Route>
        </Route>

        <Route path="/no-permission" element={<NoPermissionPage />} />
        <Route path="/404" element={<NotFoundPage />} />
        <Route
          path="*"
          element={<Navigate to={`/${language}`} replace={true} />}
        />
        <Route path={"iamsmart/testing"} element={<IAmSmartLogin />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
