import React, { useState, useEffect } from "react";
import { ReactComponent as SearchSVG } from "../../assets/images/others/search.svg";
import appointments_status from "../../data/appointments_status.json";
import SmallFunction from "../../components/function/SmallFunction";

const SearchBar = ({
  setList,
  data,
  setSearchData,
  setLoading,
  setIsFetching,
}) => {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  const [selectStatus, setSelectStatus] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!query.search && !query.status) {
      if (setList) setList(data);
    }
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    if (debouncedQuery) {
      if (setIsFetching) setIsFetching(true);
      setSearchData(search);
    }
  }, [debouncedQuery]);

  const handleSearch = async (event) => {
    if (setLoading) setLoading(true);
    setQuery((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    setSearch(event.target.value);
  };

  return (
    <>
      <div className="relative w-full">
        <SearchSVG
          style={{
            fill: "var(--Neutral-600)",
            top: "15px",
            width: "18px",
            left: "20px",
          }}
          className="absolute"
        />
        <input
          className="default_input body1 w-full ps-x-lg"
          name="search"
          placeholder="Search"
          style={{ paddingLeft: "var(--spacing-x-lg)" }}
          onChange={handleSearch}
        />
      </div>
      {/*{filter_option &&*/}
      {/*    <div className="flex gap-x-big flex-wrap">*/}
      {/*        {filter_option && Object.entries(filter_option).length && filter_option.map((item, index) => {*/}
      {/*            const status = getCategoryLabels(item.value, appointments_status)*/}
      {/*            const style = {*/}
      {/*                backgroundColor: `${status.color}`*/}
      {/*            };*/}
      {/*            return (*/}
      {/*                <label key={item.value} className='cursor-pointer'>*/}
      {/*                    <div*/}
      {/*                        style={Number(selectStatus) === Number(item.value) ? {border:' 1px solid var(--border-highlight) !important'} : {}}*/}
      {/*                        className="border border-light bg-white rounded-[6px] w-[260px] body2 text-body flex items-center gap-sm py-x-sm pl-sm pr-xx-sm">*/}
      {/*                        <span className="rounded-full w-sm h-sm block" style={style}></span>*/}
      {/*                        <span className='font-medium block w-[52px] py-xx-sm px-x-sm text-center rounded-x-sm'*/}
      {/*                              style={{boxShadow: 'var(--box-shadow-inner)'}}>{item.number}</span>*/}
      {/*                        <span>{item.label}</span>*/}
      {/*                    </div>*/}
      {/*                    <input type="checkbox" name='status' className="hidden" value={item.value} onChange={handleCheckboxSearch}/>*/}
      {/*                </label>*/}
      {/*            )*/}
      {/*        })}*/}
      {/*    </div>*/}
      {/*}*/}
    </>
  );
};

export default SearchBar;
