import React, { useEffect, useState } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl, inputLabelClasses } from "@mui/material";
import dayjs from 'dayjs';
import CalendarMonthSharpIcon from '@mui/icons-material/CalendarMonthSharp';
import ErrorMessage from "../../components/error/ErrorMessage";
// import {filledInputClasses} from "@node_modules/@mui/material";
const MyDatePicker = ({
                          name,
                          inputLabel,
                          action,
                          updateFields,
                          data,
                          disabled,
                          error,
                          required,
                          format,
                          t
                      }) => {
    const [value, setValue] = useState( data ? data : '' );

    const handleChange = (newValue) => {
        let dateValue = ''
        if (newValue) {
            dateValue = newValue.format(format ? format : 'YYYY-MM-DD');
        }
        setValue(newValue);
        if (action) action(dateValue, setValue);
        if (updateFields) updateFields({ [name]: dateValue });
    };

    useEffect(() => {
        if (!data) setValue( null);
        if (data) setValue( data);
    }, [data]);

    return (
        <FormControl variant="filled" className="w-full">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    name={name}
                    label={inputLabel}
                    value={value || data ? dayjs(value || data) : null}
                    format="DD/MM/YYYY"
                    slots={{
                        openPickerIcon: CalendarMonthSharpIcon
                    }}
                    onChange={handleChange}
                    disableOpenPicker={disabled}
                    disabled={disabled}
                    slotProps={{
                        day: {
                            sx: {
                                '&:hover': {
                                    backgroundColor: 'var(--surface-secondary)',
                                },
                                '&:focus': {
                                    backgroundColor: 'var(--surface-secondary)',
                                },
                                '&.Mui-selected': {
                                    backgroundColor: 'var(--surface-primary) !important',
                                    color: 'white',
                                },
                                '&.MuiPickersDay-today': {
                                    backgroundColor: 'var(--surface-secondary)',
                                },
                            },
                        },
                        yearButton: {
                            sx: {
                                '&:hover': {
                                    backgroundColor: 'var(--surface-secondary)',
                                },
                                '&:focus': {
                                    backgroundColor: 'var(--surface-secondary)',
                                },
                                '&.Mui-selected': {
                                    backgroundColor: 'var(--surface-primary) !important',
                                    color: 'white',
                                },
                                '&.MuiPickersDay-today': {
                                    backgroundColor: 'var(--surface-secondary)',
                                },
                            },
                        },
                        textField: {
                            variant: 'filled',
                            className: "input bg-surface-base",
                            sx: {
                                "& .MuiInputBase-root.Mui-disabled": {
                                    paddingRight: '0',
                                },

                                "& .MuiInputBase-root": {
                                    background: 'var(--surface-base)',
                                    color: 'var(--text-body)',
                                    "& fieldset": { border: 'none' },
                                    ':before': {
                                        borderBottomColor: 'var(--Neutral-300)'
                                    },
                                    ':after': {
                                        borderBottomColor: 'var(--surface-primary)'
                                    },
                                },

                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "var(--text-body)",
                                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
                                    paddingRight: '0',
                                },

                                "& .MuiInputBase-root.Mui-disabled:before": {
                                    content: 'none'
                                },
                                "& .MuiInputBase-root.Mui-error": {
                                    ':after': {
                                        borderBottomColor: 'var(--text-warning)'
                                    },
                                    ':before': {
                                        borderBottomColor: 'var(--text-warning)'
                                    },

                                },

                                "& .MuiFormLabel-root.Mui-error.Mui-focused": {
                                    [`&.MuiInputLabel-shrink`]: {
                                        color: "var(--text-warning)"
                                    }
                                },
                                "& .MuiFormLabel-root.Mui-focused": {
                                    [`&.MuiInputLabel-shrink`]: {
                                        color: "var(--label-icon-on-light)"
                                    },
                                },
                                "& .MuiFormLabel-root": {
                                    color: "var(--text-body)"
                                },
                                "& .MuiInputBase-root.MuiInputLabel-shrink": {
                                    color: "var(--text-caption)"
                                },
                                "& .MuiInputBase-root.Mui-error.MuiInputLabel-shrink": {
                                    color: "var(--text-warning)"
                                }
                            }
                        }
                    }}
                />
            </LocalizationProvider>
            <ErrorMessage error={error} t={t}/>
        </FormControl>
    );
};

export default MyDatePicker;
