import test2 from "../../assets/images/home/image_57.png";
import {LoadingImageSkeleton} from "../loading/LoadingSkeleton";
import React from "react";
import { Link } from "react-router-dom";

const QuickLinkCard = ({ image, title, link, alt, children, class_name }) => {
    const CardContent = () => (
        <div className={`card-image rounded-t-big bg-surface-base ${class_name}`}>
            {children}
            <img src={image || test2 } alt={alt} className='h-full'/>
        </div>
    );
    return (
        <>
            { link ? (
                <Link to={link} className="quick-link-card ">
                    <CardContent/>
                    <div className="card-image-subtitle py-x-sm">
                        <span className="subtitle">{title}</span>
                    </div>
                </Link>
            ) : (

                <div className="quick-link-card">
                    <CardContent />
                    <div className="card-image-subtitle py-x-sm">
                        <span className="subtitle">{title}</span>
                    </div>
                </div>
            )}
        </>
    );
};

export default QuickLinkCard;
