import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BasicTag from "../../tags/BasicTag";

const ChipStatusSelect = ({
  multiple = false,
  data,
  updateFields,
  name,
  options,
  t,
  lang,
  disabled,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    data ? data.value : ""
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (data) {
      setSelectedOptions(data.value);
    } else {
      setSelectedOptions("");
    }
  }, [data]);

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedOptions(value);
    if (updateFields) updateFields({ [name]: event.target });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleUnselect = () => {
    handleChange({ target: { value: "" } });
    setSelectedOptions("");
    if (updateFields) updateFields({ [name]: "" });
  };

  const renderValue = (selected, action) => {
    if (!selected || selected.length === 0) {
      return (
        <div className="text-body label1-400 text-nowrap overflow-hidden text-ellipsis">
          {t("Select Status")}
        </div>
      );
    } else {
      const selectedData = options?.find((item) => item.id === selectedOptions);
      return (
        <BasicTag
          label={
            selectedData
              ? selectedData?.[`name${lang}`]
              : data
              ? data?.[`label${lang}`]
              : ""
          }
          color={
            data?.color ? data?.color : selectedData ? selectedData?.color : ""
          }
          action={action}
        />
      );
    }
  };

  return (
    <FormControl className="w-full">
      <Select
        IconComponent={KeyboardArrowDownIcon}
        name="property_category_id"
        className="body1 text-body w-full"
        value={selectedOptions}
        onChange={handleChange}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        displayEmpty
        renderValue={renderValue}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            className: "custom-scrollbar",
            style: {
              padding: "var(--spacing-x-sm)",
              maxHeight: "auto",
              marginTop: "var(--spacing-sm)",
              overflowY: "auto",
              background: "var(--surface-card)",
              borderRadius: "var(--radius-sm)",
              boxShadow: "var(--box-shadow-lg)",
            },
          },
          MenuListProps: {
            style: { padding: "0" },
          },
        }}
        sx={{
          "&.MuiInputBase-root": {
            border: "0",
            borderBottom: "1px solid var(--Neutral-100)",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": { border: "0" },
          "&.hover": {
            "&.MuiOutlinedInput-notchedOutline": { border: "0" },
          },
          ".MuiOutlinedInput-notchedOutline": { border: "0" },
          background: "var(--surface-base)",
          borderBottom: "1px solid var(--Neutral-100)",
          borderRadius: "var(--radius-sm)",
        }}
      >
        {selectedOptions && (
          <MenuItem
            value={selectedOptions}
            sx={{
              "&.MuiButtonBase-root": { padding: "0" },
              "&.Mui-selected": { background: "var(--Neutral-50)" },
            }}
          >
            <span className="p-sm">
              {renderValue(selectedOptions, handleUnselect)}
            </span>
          </MenuItem>
        )}

        {options &&
          options.map((item) => (
            <MenuItem
              key={item.value || item.id}
              value={item.value || item.id}
              sx={{
                "&.MuiList-root": { padding: "0" },
                "&:hover": {
                  "&.Mui-selected": {
                    background: "var(--surface-secondary)",
                  },
                  background: "var(--surface-secondary)",
                },
                "&.Mui-selected": {
                  color: "var(--text-body) !important",
                  background: "none",
                },
                maxWidth: "100%",
                display: "flex",
                padding: "0",
                gap: "var(--spacing-md)",
              }}
            >
              <span key={item.status || item.label} className="p-sm">
                <BasicTag label={item?.[`name${lang}`]} color={item?.color} />
              </span>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ChipStatusSelect;
