import { useEffect, useState } from "react";
import { ReactComponent as TickSVG } from "../../assets/images/others/tick.svg";

const RoundedCheckbox = ({
  name,
  inputLabel,
  check,
  setDisableBtn,
  disabledBtn,
  updateFields,
  data,
  t,
  action,
  style,
  disabled,
}) => {
  const [value, setValue] = useState(check ? check : data ? data : false);

  const handleChange = (e) => {
    if (disabled) return false;
    if (setDisableBtn) {
      setDisableBtn(!disabledBtn);
    }
    setValue(e.target.checked);

    if (action) action(e, setValue);

    if (updateFields) {
      updateFields({ [name]: e.target.checked });
    }
  };

  useEffect(() => {
    setValue(data ? data : false);
    if (!setDisableBtn && !data) {
      return;
    }
    if (setDisableBtn && data) {
      setDisableBtn(false);
    }
  }, [data]);

  return (
    <div
      className={`flex gap-sm items-baseline lg:items-center ${
        disabled ? "pointer-events-none cursor-not-allowed" : "cursor-pointer"
      }`}
    >
      <label
        className="checkbox-container label1 text-800 cursor-pointer relative overflow-hidden flex items-center"
        htmlFor={name}
        style={style}
      >
        <input
          name={name}
          id={name}
          type="checkbox"
          style={{ top: "-100px", left: "-100px" }}
          className="checkbox align-top me-sm border-default"
          checked={value}
          onChange={handleChange}
          disabled={disabled}
        />
        <span
          className={`checkmark_round flex justify-center items-center relative`}
        >
          <TickSVG className="absolute" width={12} height={12} />
        </span>
        {t ? t(inputLabel) : inputLabel}
      </label>
    </div>
  );
};

export default RoundedCheckbox;
