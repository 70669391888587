const SectionTemplate = ({ title, buttonBar, children, t }) => {
    return (
        <div className="bg-surface-card px-huge py-lg gap-x-big flex flex-col rounded-big" style={{boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.18)'}}>
            {(title || buttonBar) &&
                <div className="label1 text-body flex justify-between items-center">
                    {t(title)}
                    {buttonBar &&
                        <div>
                            {buttonBar}
                        </div>
                    }
                </div>
            }
            { children }
        </div>

    );
};

export default SectionTemplate;
