import DoneIcon from "@mui/icons-material/Done";
import { useTranslation } from "react-i18next";

const VerifiedButton = ({ data }) => {
  // const verified = data === "1" || data === true || data === 1;
  const { t } = useTranslation();
  return (
    <button
      className={`px-big text-center ${
          data
          ? "verified-btn flex items-center"
          : "not_verified-btn flex items-center"
      }`}
      style={{ minWidth: "130px", height: "56px" }}
    >
      <div className="flex gap-md items-center mx-auto">
        {data ? (
          <DoneIcon
            sx={{ height: "18px", width: "18px" }}
            style={{ marginRight: "-5px" }}
          />
        ) : null}
        <span className="w-fit">{data ? t("Verified") : t("Verify")}</span>
      </div>
    </button>
  );
};

export default VerifiedButton;
