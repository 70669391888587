import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  OutlineBackButton,
  ArrowBackButton,
  BlueForwardButton,
  BlueForwardLink,
  PrimaryButton,
} from "../button/SingleButton";
import { useTranslation } from "react-i18next";
import { ScrollToError } from "../../assets/js/global";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import SmallFunction from "../function/SmallFunction";

const StepFormTemplate = ({
  formData,
  setError,
  currentStepIndex,
  successAction,
  failAction,
  isFirstStep,
  isLastStep,
  next,
  step,
  slug,
  disabledBtn,
  back,
  api_name,
  back_path,
  type,
  redirect_path,
  lng,
  updateStep2Fields,
  captchaStepData,
  updateFields,
  getCode,
  appendFile,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useUserLoginContext();
  const { sweetFailAlert, sweetSuccessAlert } = SmallFunction();
  const currentStep = currentStepIndex + 1;

  const getApiUrl = (api_url = null) => {
    const baseUrl = `${process.env.REACT_APP_PUBLIC_API_URL}/v1/${
      api_url ? api_url : api_name
    }${slug ? `/${slug}` : ""}`;
    if (getCode) return `${baseUrl}/send`;
    if (isLastStep && type === "forgot") return `${baseUrl}/reset`;
    if (isLastStep) return baseUrl;
    if (api_url) return baseUrl;
    return `${baseUrl}/step${currentStep}`;
  };

  const api_url = getApiUrl();

  const prepareFormData = (event) => {
    const dataObject = {};
    if (isLastStep) {
      Object.entries(formData).forEach(([key, value]) => {
        dataObject[key] = value;
      });
    } else {
      const dataFormData = new FormData(event.target);
      dataFormData.forEach((value, key) => {
        dataObject[key] = value;
      });
    }
    return dataObject;
  };

  const prepareRequestBody = (dataObject) => {
    const myHeaders = new Headers();
    if (token) myHeaders.append("Authorization", token);
    myHeaders.append("Accept-Language", lng);

    if (appendFile && appendFile?.length) {
      const body = new FormData();
      appendFile?.forEach((key) => {
        const file =
          formData[key] ||
          new File([""], "", { type: "application/octet-stream" });

        delete dataObject[key];
        body.append(key, file);
      });
      body.append("data", JSON.stringify(dataObject));
      return { body, headers: myHeaders };
    } else {
      myHeaders.append("Content-Type", "application/json");
      return { body: JSON.stringify(dataObject), headers: myHeaders };
    }
  };

  const handleSuccessResponse = (resData) => {
    if (updateFields) updateFields({ captcha_verified: "1" });
    if (successAction) successAction(resData);
    setError("");
    if (resData.data && updateFields) {
      updateFields(resData.data);
      if (type === "forgot" || type === "corporate/account") {
        updateFields({ verification_code: "" });
      }
    }
    if (isLastStep) {
      setLoading(true);
      sweetSuccessAlert(resData, t).then(() =>
        navigate(`/${lng}${redirect_path}`)
      );
    } else {
      next();
    }
  };

  const handleErrorResponse = (resData) => {
    setLoading(false);
    if (failAction) {
      failAction(resData);
    } else if (setError && resData.errors) {
      setError(resData.errors);
    } else if (setError && resData.message) {
      setError({ message: resData.message });
    } else {
      sweetFailAlert(resData, t);
    }
  };

  const postForm = async (event, step_api_url = null) => {
    const dataObject = prepareFormData(event);
    const { body, headers } = prepareRequestBody(dataObject);

    try {
      const res = await fetch(step_api_url ? step_api_url : api_url, {
        method: "POST",
        headers,
        body,
      });
      const resData = await res.json();

      if (resData.code === 200) {
        handleSuccessResponse(resData);
      } else {
        handleErrorResponse(resData);
      }
    } catch (error) {
      sweetFailAlert(error, t);
      setLoading(false);
    } finally {
      if (!isLastStep) setLoading(false);
      ScrollToError();
    }
  };

  const checkCapthcha = (update, action, event) => {
      const data = new FormData(event.target);
      let verifiedtoken = data.get("mtcaptcha-verifiedtoken");
      let captchaData = "";

      switch (type) {
          case 'registration':
              captchaData = captchaStepData["captcha_verify_code"];
              break;
          default:
              captchaData = formData["captcha_verified"];
              break;
      }
      const yes = () => {
      update({["captcha_verify_code"]: verifiedtoken})
      setError({['captcha_verify_code']: ""});
      return action(event ? event : null);
    }
    const no = () => {
        update({["captcha_verify_code"]: ""})
        setLoading(false);
        setError({captcha_verify_code: 'please enter the captcha.'})
    }

    if (captchaData) {
        action(event ? event : null);
    } else {
        if (verifiedtoken) {
            yes()
        } else {
            no()
        }
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (type === "registration") {
      if (isFirstStep) return next();
      if (!isFirstStep && currentStepIndex !== 1) {
        setLoading(true);
        await postForm(event);
      }
      if (currentStepIndex === 1) {
        checkCapthcha(updateStep2Fields, next, event);
      }
      return;
    }

    if (type === "forgot" || type === "corporate/account") {
      if (isFirstStep) {
        setLoading(true);
        checkCapthcha(updateFields, postForm, event);
      } else if (!isLastStep) {
        if (formData["verification_code"]) return next();
      } else {
        setLoading(true);
        await postForm(event);
      }
    }

    if (type === "submit-documents") {
      if (isFirstStep) {
        return next();
      } else {
        setLoading(true);
        await postForm(event);
      }
    }

    if (type === "proposal-submission") {
      if (isFirstStep) {
        await postForm(event, getApiUrl("proposal/check"));
      } else {
        setLoading(true);
        await postForm(event);
      }
    }
  };

  const renderBackButton = () => {
    if (type === "registration") {
      return isFirstStep || currentStepIndex === 1 ? (
        <OutlineBackButton t={t} href={`/${lng}${back_path}`} label="Cancel" />
      ) : (
        <ArrowBackButton t={t} href="" action={back} />
      );
    } else if (type === "forgot" || type === "corporate/account") {
      return isFirstStep ? (
        <OutlineBackButton t={t} href={`/${lng}${back_path}`} label="Cancel" />
      ) : (
        <ArrowBackButton t={t} href="" action={back} />
      );
    } else if (type === "proposal-submission") {
      return !isFirstStep ? (
        <ArrowBackButton t={t} href="" action={back} />
      ) : null;
    } else if (type === "submit-documents") {
      return (
        <OutlineBackButton t={t} href={`/${lng}${back_path}`} label="Cancel" />
      );
    }
    return null;
  };

  const renderForwardButton = () => {
    const getButtonLabel = () => {
      if (loading) return "Loading";

      switch (type) {
        case "registration":
          return isFirstStep
            ? "Create Account"
            : isLastStep
            ? "Submit"
            : "Continue";
        case "forgot":
          if (isFirstStep) return "Get Code";
          if (isLastStep)
            return slug === "password" ? "Reset Password" : "Login";
          return "Continue";
        case "corporate/account":
          return isFirstStep ? "Get Code" : isLastStep ? "Confirm" : "Continue";
        case "proposal-submission":
          return isLastStep ? "Proceed to Form Filling" : "Continue";
        case "submit-documents":
          return isFirstStep ? "Continue" : "Submit";
        default:
          return "";
      }
    };

    const label = getButtonLabel();

    if (type === "forgot" && slug === "username" && isLastStep) {
      return <BlueForwardLink t={t} label={label} href={`/${lng}/login`} />;
    }

    const ButtonComponent =
      type === "submit-documents" ? PrimaryButton : BlueForwardButton;

    return (
      <ButtonComponent t={t} label={label} type="submit" loading={loading} />
    );
  };

  return (
    <form onSubmit={onSubmit}>
      {step}
      <div className={`flex justify-between items-center mt-xxx-big`}>
        {renderBackButton()}
        {!disabledBtn && renderForwardButton()}
      </div>
    </form>
  );
};

export default StepFormTemplate;
