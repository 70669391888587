import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import SearchBar from "../../components/input/SearchBar";
import AccountManagementTemplate from "../../components/account_management/Template";
import LoadingUI from "../../components/loading/LoadingUI";
import StatusTag from "../../components/tags/StatusTag";
import RoleTag from "../../components/tags/RoleTag";
import DataTable from "../../components/table/DataTable";
import { AddButton, EditButton } from "../../components/button/SingleButton";
import Breadcrumb from "../../components/header/Breadcrumb";
import moment from "moment";

const CorporateAccounts = ({ lng }) => {
  const { userLoading, account_type } = useUserLoginContext();
  const { t } = useTranslation();
  const [data, setDate] = useState({});
  const [searchData, setSearchData] = useState("");
  const api = "corporate/accounts";
  const lang = lng[0].toUpperCase() + lng.slice(1);
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const tableDataProps = {
    setState: setDate,
    searchData,
    data,
    api,
    isFetching,
    setIsFetching,
    DefaultSorting: [{ id: "accountType", desc: true }],
    appandData: {
      keyword: searchData,
    },
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "username",
        header: "User",
        minSize: 260,
        grow: false,
        objKey: "username",
        Cell: ({ cell, row }) => (
          <div className="flex flex-col justify-center">
            <div>{cell.getValue()}</div>
            <div>{row.original.email}</div>
          </div>
        ),
      },
      {
        accessorKey: "accountType",
        header: "User Role",
        minSize: 150,
        grow: false,
        objKey: "account_type",
        Cell: ({ renderedCellValue }) => (
          <RoleTag
            role={renderedCellValue.value}
            label={renderedCellValue?.[`label${lang}`]}
            color={renderedCellValue.color}
          />
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        minSize: 170,
        grow: false,
        objKey: "status",
        Cell: ({ renderedCellValue }) => (
          <StatusTag
            status={renderedCellValue.value}
            label={renderedCellValue?.[`label${lang}`]}
            color={renderedCellValue.color}
          />
        ),
      },
      {
        accessorKey: "lastLoginTime",
        header: "Last Login Time",
        grow: true,
        objKey: "login_time",
        Cell: ({ cell, row }) => (
          <span className="body2 flex items-center">
            {cell.getValue()
              ? moment(cell.getValue()).local().startOf("minutes").fromNow()
              : "--"}
          </span>
        ),
      },
      {
        accessorKey: "accountNo",
        header: "",
        size: 80,
        enableSorting: false,
        grow: false,
        Cell: ({ cell, row }) => (
          <EditButton
            t={t}
            href={`/${lng}/corporate-accounts-management/account-details/${row.original.accountNo}`}
          />
        ),
      },
    ],
    []
  );

  const CalladdBtn = () => {
    return (
      <div className="w-fit">
        <AddButton
          t={t}
          href={`/${lng}/corporate-accounts-management/corporate-account-creation`}
        />
      </div>
    );
  };

  if (userLoading) return <LoadingUI />;

  return (
    <>
      <Breadcrumb lng={lng} />
      <AccountManagementTemplate
        lng={lng}
        accountType={account_type}
        title="Corporate Accounts"
        button_bar={CalladdBtn()}
      >
        <div className="flex flex-col gap-xx-big">
          <SearchBar
            setLoading={setLoading}
            data={data}
            setSearchData={setSearchData}
            setIsFetching={setIsFetching}
          />
          <DataTable
            columns={columns}
            border={false}
            apiProps={tableDataProps}
            setCustomLoading={setLoading}
            customLoading={loading}
            pageSizeOptions={[10, 20, 50]}
          />
        </div>
      </AccountManagementTemplate>
    </>
  );
};

export default CorporateAccounts;
