import {useEffect} from "react";

export default function IAmSmartLogin() {

    const REQUEST_SOURCE = {
        INDIVIDUAL_REGISTRATION: "INDIVIDUAL_REGISTRATION",
        LOGIN: "LOGIN",
        CONNECT: "CONNECT",
    }

    const EME_FIELDS = ['chName', 'enName', 'idNo', 'mobileNumber', 'emailAddress', 'postalAddress'];

    const urlPrefix = `${process.env.REACT_APP_PUBLIC_API_URL}/v1/iAmSmart`

    // useEffect(() => {
    //     async function isFormRequest() {
    //         const response = await fetch(`${urlPrefix}/emeInfo/checkFormFillingReq`)
    //
    //         const data = await response.json();
    //
    //         const result = data?.content?.result
    //
    //         if (result != true) {
    //             console.log('there is no form filling request')
    //             return false;
    //         }
    //
    //         return true;
    //     }
    //
    //     async function getProfile() {
    //         const response = await fetch(`${urlPrefix}/emeInfo/getProfile`)
    //
    //         const data = await response.json()
    //
    //         console.log(data)
    //     }
    //
    //
    //     if (!isFormRequest()) {
    //         console.log('there is no form filling request')
    //         return;
    //     }
    //
    //
    //
    // }, []);

    const getBrowserType = () => {
        const userAgent = navigator.userAgent;

        if (/android/i.test(userAgent)) {
            if (/SamsungBrowser/i.test(userAgent)) return 'androidSamsung';
            if (/MiuiBrowser|XiaoMi/i.test(userAgent)) return 'androidXiaomi';
            if (/HUAWEI|HONORHRY/i.test(userAgent)) return 'androidHuawei';
            if (/EdgA/i.test(userAgent)) return 'androidEdge';
            if (/Firefox/i.test(userAgent)) return 'androidFirefox';
            if (/Chrome/i.test(userAgent) && /Safari/i.test(userAgent)) return 'androidChrome';
            return 'androidOther';
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            if (/FxiOS/i.test(userAgent)) return 'iosFirefox';
            if (/EdgiOS/i.test(userAgent)) return 'iosEdge';
            if (/CriOS/i.test(userAgent)) return 'iosChrome';
            if (/Safari/i.test(userAgent)) return 'iosSafari';
            return 'iosOther';
        } else {
            return 'pcBrowser';
        }
    };

    const handleClickFormFilling = async () => {
        const response = await fetch(`${urlPrefix}/emeInfo/formFillingWithServiceLogin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                eMEFields: EME_FIELDS,
                source: getBrowserType(),
                requestSource: REQUEST_SOURCE.INDIVIDUAL_REGISTRATION
            })
        })

        const data = await response.json();

        const isSuccess = data.code === 'D00000'

        if (!isSuccess) {
            console.log('response return is not success code')
            return;
        }

        const redirectUrl = data?.content?.redirectURI;

        if (!redirectUrl) {
            console.log('redirectUrl is not exist')
        }

        window.location.href = redirectUrl;
    }

    return (
        <button className={'p-2 bg-amber-200 rounded-2xl'} onClick={handleClickFormFilling}>form filling</button>
    )
}