import Select from "../../components/input/Select";
import MyDatePicker from "../../components/input/DatePicker";
import Input from "../../components/input/Input";
import Switch from "../../components/input/Switch";
import ProfileSection from "../../components/my-profile/ProfileSection";
import Switcher from "../../components/button/Switcher";

const agent_type_option = [
  { value: 1, label: "New Agent" },
  { value: 2, label: "Connected Agent" },
];

const principal_type_option = [
  { value: 1, label: "New Principal User" },
  { value: 2, label: "Connected Principal User" },
];

export const Name = ({ inputProps, t, company_name, user_name, title }) => (
  <ProfileSection title={title} t={t}>
    <div className="flex gap-x-big">
      {company_name && (
        <Input {...inputProps(company_name, "Company Name")} disabled={true} />
      )}
      {user_name && (
        <Input {...inputProps(user_name, "Officer Name")} disabled={true} />
      )}
    </div>
  </ProfileSection>
);

export const AgentSelect = ({ inputProps, is_principal, disabled, data }) => {
  const connectedSelectItem = (item) => {
    return (
      <div key={item.id} className="flex justify-between w-full">
        <div className="flex-1">{item.corporateName}</div>
        <div className="flex-1">{item.userName}</div>
      </div>
    );
  };

  const connectedAgentRenderValue = (selected, option) => {
    if (!disabled) {
      const selectedItem = option.find((item) => item.userEmail === selected);

      return selectedItem
        ? `${selectedItem.corporateName} - ${selectedItem.userName}`
        : null;
    } else {
      return `${data?.assign_company_name} - ${data?.assign_officer_name} (${data?.assign_email})`;
    }
  };

  return (
    <Select
      {...inputProps(
        "email",
        is_principal ? "Select Agent" : "Select Principal"
      )}
      customDisplay={connectedSelectItem}
      options={
        is_principal ? data?.connectedAgentList : data?.connectedPrincipalList
      }
      customRender={connectedAgentRenderValue}
      disabled={disabled}
    />
  );
};

export const EffectPeriod = ({
  inputProps,
  noEndDate,
  setNoEndDate,
  t,
  showEndDateButton,
  disabled,
  updateFields,
}) => {
  const toggleNoEndDate = () => {
    setNoEndDate(!noEndDate);
    updateFields({ ["end_date"]: "" });
  };

  return (
    <ProfileSection title="Effective Period" t={t}>
      <div className="flex gap-x-big items-baseline">
        <div className="flex flex-1 gap-x-big items-center">
          <MyDatePicker
            {...inputProps("start_date", "Start Date", 0, disabled)}
            format="YYYY-MM-DD"
          />
          <span>to</span>
        </div>
        <div className="flex flex-col flex-1 gap-x-big">
          {noEndDate ? (
            <Input
              name="end_at"
              inputLabel="End Date"
              data="N/A"
              disabled={true}
            />
          ) : (
            <MyDatePicker
              {...inputProps("end_date", "End Date", 0, disabled)}
              format="YYYY-MM-DD"
            />
          )}
          {showEndDateButton && (
            <Switch
              name="no_end_date"
              data={noEndDate}
              checked={noEndDate}
              action={toggleNoEndDate}
              inputLabel="This appointment have no end date"
            />
          )}
        </div>
      </div>
    </ProfileSection>
  );
};

export const SwitchAgent = ({
  data,
  inputProps,
  is_principal,
  t,
  updateFields,
}) => {
  return (
    <ProfileSection title={is_principal ? "Agent" : "Principal User"} t={t}>
      {updateFields ? (
        <Switcher
          {...inputProps("type")}
          options={is_principal ? agent_type_option : principal_type_option}
          action={() => {
            updateFields({ ["email"]: "" });
            updateFields(
              is_principal
                ? { ["agent_id"]: "" }
                : { ["principal_user_id"]: "" }
            );
          }}
        />
      ) : null}
      {data?.type === 1 ? (
        <Input {...inputProps("email", "Email Address")} />
      ) : (
        <AgentSelect
          inputProps={inputProps}
          is_principal={is_principal}
          data={data}
          disabled={!updateFields}
        />
      )}
    </ProfileSection>
  );
};
