import {Chip} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


export const WhiteChips = ({ dataKey, label, data, setState, updateFields, action }) => {
    const handleDelete = (key, data, setState) => {
        const newStatus = {...data}
        delete newStatus[key];
        setState(newStatus)
        updateFields({ [key]: '' });
        if (action) action();
    };

    return (
        <Chip
            className='caption1 text-body'
            key={dataKey}
            label={label}
            onDelete={() => handleDelete(dataKey, data, setState) }
            deleteIcon={<CloseIcon style={{ color: 'var(--Neutral-600)'}} fontSize="inherit"/>}
            sx={{
                width: 'fit-content',
                background: 'var(--surface-card)',
                border: '1px solid var(--border-default)',
                borderRadius: 'var(--radius-lg)',
                "& .MuiChip-label": {
                    padding: '0',
                },
                '& .MuiChip-deleteIcon': {
                    marginLeft: 'var(--spacing-xx-sm)',
                },
                padding: "var(--spacing-x-sm) var(--spacing-sm) var(--spacing-x-sm) var(--spacing-md)",
            }}
        />
    )
}

