import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const BasicTag = ({ label, color, action }) => {
    const style = {
        backgroundColor: color,
    };

    return (
        <div className="bg-surface-base rounded-big border border-default label2 text-body w-fit px-x-sm py-xx-sm gap-x-sm flex items-center tracking-normal" style={{lineHeight: 'normal'}}>
            <span className='rounded-full w-sm h-sm block' style={style}></span>
            <span className='text-nowrap'>{label}</span>
            {action && <CloseIcon style={{ color: 'var(--label-icon-default)', width: '12px', height: '12px'}} onClick={() => action()}/>}
        </div>
    )
}

export default BasicTag;