import React from "react";

const RoleTag = ({ role, account_type, label, color }) => {
    const style = {
        backgroundColor: color,
        lineHeight: 'normal'
    };
    return (
        <>
            <div className="bg-blue-600 rounded-md label2 text-label-icon-on-primary w-fit px-sm py-xx-sm flex items-center" style={style}>{label ? label : ''}</div>
        </>
    )
}

export default RoleTag;