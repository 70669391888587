import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import HeaderAndNavBar from "../components/header/HeaderAndNavBar";
function LayoutBeforeLogin() {
    const {i18n: {language}} = useTranslation();
    return (
        <>
            <HeaderAndNavBar lng={language}/>
            <main>
                <Outlet />
            </main>
            <Footer/>
        </>
    );
}

export default LayoutBeforeLogin;