import React from "react";

const EmptyDataText = ({ t, text}) => {
    return (
        <div className='flex justify-center py-lg'>
            <em className='text-caption'> -- {text} --</em>
        </div>
    )
}

export default EmptyDataText;