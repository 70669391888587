import React, { useState, useEffect, useRef } from "react";
import LanguageAndFontSize from "../../components/header/LanguageAndFontSize";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/images/header/en_logo.svg";
import { ReactComponent as TCLogo } from "../../assets/images/header/tc_logo.svg";
import { ReactComponent as SCLogo } from "../../assets/images/header/sc_logo.svg";
import { ReactComponent as WPLogo } from "../../assets/images/header/en_wplogo.svg";
import { ReactComponent as WPTCLogo } from "../../assets/images/header/tc_wplogo.svg";
import { ReactComponent as WPSCLogo } from "../../assets/images/header/sc_wplogo.svg";
import { ReactComponent as BellIcon } from "../../assets/images/header/bell_icon.svg";
import { ReactComponent as DownArrow } from "../../assets/images/arrow/down_arrow.svg";
import { ReactComponent as UnreadICON } from "../../assets/images/header/notification_unread.svg";
import { ReactComponent as ReadICON } from "../../assets/images/header/notification_read.svg";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import SmallFunction from "../../components/function/SmallFunction";
import { AddDataToHTML } from "../../assets/js/global";
import USER_TYPE from "../../data/user_type.json";

const Header = ({ showNavBar, handleToggle, lng, childern }) => {
  const { profileData, account_type, icon, notification, haveNoRead } =
    useUserLoginContext();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [showOptions, setShowOptions] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const iconRef = useRef(null);
  const optionRef = useRef(null);
  const notificationIconRef = useRef(null);
  const notificationRef = useRef(null);
  const iconRef_m = useRef(null);
  const optionRef_m = useRef(null);
  const individual = profileData?.accountType?.value === "1";
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const { changeDate } = SmallFunction();
  const navigate = useNavigate();
  const lang = language.charAt(0).toUpperCase() + language.slice(1);

  const options = [
    { label: t("My Profile"), link: `/${lng}/my-profile` },
    account_type === USER_TYPE.CORPORATE && {
      label: t("Corporate Accounts"),
      link: `/${lng}/corporate-accounts-management`,
    },
    !individual && {
      label: t("Appointment"),
      link: `/${lng}/appointment-management`,
    },
    { label: t("Personalisation"), link: `/${lng}/personalisation` },
  ];

  const Navoptions = [
    { label: "Home", link: `/${lng}/` },
    { label: "Property List", link: `/${lng}/property-list/` },
    { label: "Proposal Submission", link: `/${lng}/proposal-submission/` },
    { label: "Transaction History", link: `/${lng}/transaction-history` },
    { label: "Submitted Documents", link: `/${lng}/submitted-documents` },
    { label: "My Documents", link: `/${lng}/my-document` },
    { label: "Help Centre", link: `/${lng}/help-centre` },
  ];

  const getLogo = (language) => {
    const rvd_home_url = t("rvd_home_url");
    switch (language) {
      case "tc":
        return (
          <>
            <Link to={rvd_home_url}>
              <TCLogo alt="差餉物業估價署" width="370px" />
            </Link>
            {/*<Link to={`/${lng}`}>*/}
            {/*  <WPTCLogo alt="差餉物業估價署電子服務平台" width="100%" height="10px" />*/}
            {/*</Link>*/}
          </>
        );
      case "sc":
        return (
          <Link to={rvd_home_url}>
            <SCLogo alt="差饷物业估价署电子服务平台" width="370px" />
          </Link>
        );
      default:
        return (
          <Link to={rvd_home_url}>
            <Logo alt="Rating and Valuation Department Web Portal" width="370px" />
          </Link>
        );
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (showOptions) {
        if (
          !optionRef_m.current.contains(event.target) &&
          !iconRef_m.current.contains(event.target) &&
          !optionRef.current.contains(event.target) &&
          !iconRef.current.contains(event.target)
        ) {
          onClickOutside();
        }
      }
      if (showNotification) {
        if (
          !notificationIconRef.current.contains(event.target) &&
          !notificationRef.current.contains(event.target)
        ) {
          onClickOutside();
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOptions, showNotification]);

  const onClickOutside = () => {
    setShowOptions(false);
    setShowNotification(false);
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const toggleNotificationMenu = () => {
    setShowNotification(!showNotification);
  };

  const handleLogout = () => {
    removeCookie("access_token", { path: "/" });
    localStorage.removeItem("userData");
    AddDataToHTML("data-theme", "blue");
    navigate(`/${lng}/login`);
  };

  const bellIcon = () => {
    return (
       <>
         <BellIcon width="20px" />
         {haveNoRead && (
             <span className="w-[7px] h-[8px] bg-red-600 absolute rounded-full top-[10px] right-[10px] border border-1 border-surface-secondary "></span>
         )}
       </>
    )
  }

  return (
    <>
      {/*    desktop    */}
      <header className="bg-white">
        <div className="head-foot-container layout-container hidden lg:block">
          <div className="header flex justify-between items-center text-neutral-800">
            <div className="header-logo">{getLogo(lng)}</div>
            <div className="header-setting flex items-center gap-lg">
              <LanguageAndFontSize lng={lng} />
              {profileData && Object.entries(profileData).length ? (
                <div className="flex gap-xxx-big items-center relative">
                  <div className="header-bell">
                    <button
                      className="bg-surface-secondary p-x-sm rounded-sm hover:bg-surface-secondary relative"
                      onClick={toggleNotificationMenu}
                      ref={notificationIconRef}
                    >
                      {bellIcon()}
                    </button>
                    <div
                      className={`notification-model text-body ${
                        showNotification ? "active" : ""
                      }`}
                      ref={notificationRef}
                    >
                      <div className="bg-surface-primary-hover label1 text-label-icon-on-primary py-md px-x-big flex justify-between items-center">
                        {t("Notification")}
                        <CloseIcon
                          className="hover:cursor-pointer"
                          onClick={toggleNotificationMenu}
                        />
                      </div>
                      {notification &&
                        notification.map((option, index) => {
                          const {
                            id,
                            [`subject${lang}`]: subject,
                            date,
                            isRead,
                          } = option;
                          const created_at = changeDate(date);
                          const time = changeDate(null, date);
                          return (
                            <Link
                              to={`/${lng}/notification-centre/details/${id}`}
                              className="items-start p-x-big label1 text-left flex gap-sm bg-surface-card hover:bg-surface-secondary"
                              key={index}
                            >
                              {isRead ? (
                                <ReadICON width={24} />
                              ) : (
                                <UnreadICON width={24} />
                              )}
                              <div className="flex flex-col gap-[9px] font-medium">
                                <span className="text-title">{subject}</span>
                                <div className="flex gap-x-sm text-placeholder items-center">
                                  {created_at}
                                  <span className="hidden md:block w-[5px] h-[5px] bg-placeholder rounded-full"></span>
                                  {time}
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                      {notification.length ? (
                        <Link
                          className="bg-surface-card label1 text-label-icon-on-light py-big px-x-big text-center w-full hover:bg-surface-base hover:underline"
                          to={`/${lng}/notification-centre`}
                        >
                          {t("View All")}
                        </Link>
                      ) : (
                        <div className="bg-surface-card label1 text-800 py-big px-lg text-center w-full">
                          {t("No notification")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="header-user-icon">
                    <button
                      className="flex items-center"
                      ref={iconRef}
                      onClick={toggleOptions}
                    >
                      <div className="w-42px">
                        <img src={icon} alt="icon" />
                      </div>
                      <div className="w-x-big">
                        <DownArrow />
                      </div>
                    </button>
                    <div
                      className={`header-options header-user-options text-body ${
                        showOptions ? "active" : ""
                      }`}
                      ref={optionRef}
                    >
                      {options &&
                        options.map((option, index) => {
                          if (!option) return null;
                          const { label, link } = option;
                          return (
                            <Link
                              to={link}
                              className="header-options-items py-sm px-md label1 text-left"
                              key={index}
                            >
                              {label}
                            </Link>
                          );
                        })}
                      <hr className="my-2" />
                      <span
                        onClick={handleLogout}
                        className="header-options-items py-sm px-md label1 text-left cursor-pointer"
                      >
                        {t("Logout")}
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </header>
      {/*    mobile    */}
      <header className="head-foot-container block lg:hidden sticky z-30 top-0 bg-white w-full border-b">
        <div className="header flex items-center justify-between text-neutral-800">
          <div className="header-logo w-218px md:w-300px">{getLogo(lng)}</div>
          <div className="flex gap-sm items-center">
            {profileData && Object.entries(profileData).length ? (
              <div className="header-user-icon relative">
                <button
                  className="flex items-center"
                  ref={iconRef_m}
                  onClick={toggleOptions}
                >
                  <div className="w-xxx-big lg:w-lg">
                    <img src={icon} alt="" />
                  </div>
                </button>
                <div
                  className={`header-options header-user-options z-30 text-body ${
                    showOptions ? "active" : ""
                  }`}
                  ref={optionRef_m}
                >
                  {options &&
                    options.map((option, index) => {
                      if (!option) return null;
                      const { label, link } = option;
                      return (
                        <Link
                          to={link}
                          className="header-options-items py-x-sm px-sm lg:py-sm lg:px-md label1 text-left text-body"
                          key={index}
                        >
                          {label}
                        </Link>
                      );
                    })}
                  <hr className="my-2" />
                  <span
                    onClick={handleLogout}
                    className="header-options-items py-x-sm px-sm lg:py-sm lg:px-md label1 text-left text-body cursor-pointer"
                  >
                    {t("Logout")}
                  </span>
                </div>
              </div>
            ) : null}

            <button
              onClick={handleToggle}
              className="bg-surface-secondary w-xxx-big h-xxx-big lg:w-lg lg:h-lg rounded-x-sm"
            >
              <MenuIcon
                style={{ fill: "var(--label-icon-on-light)", width: "18px" }}
              />
            </button>
          </div>
        </div>
      </header>

      <nav
        className={`mobile-nav fixed flex flex-col w-full sm:w-400px h-full bg-surface-primary z-20 overflow-y-auto py-x-big px-md gap-x-big ${
          showNavBar && "active"
        } lg:hidden`}
        style={{ zIndex: "20" }}
      >
        {profileData && Object.entries(profileData).length ? (
            <Link to={`/${lng}/notification-centre`}>
              <div className="bg-surface-secondary w-full rounded-sm flex items-center">
                <div className="p-x-sm relative">
                  {bellIcon()}
                </div>
                <span className="label1 text-label-icon-default">{t("Notification")}</span>
              </div>
            </Link>
        ) : null}
        <ul className="flex flex-col text-white">
          {profileData &&
          Object.entries(profileData).length &&
          Navoptions &&
          Navoptions.length
              ? Navoptions.map((option, index) => {
                const {label, link} = option;
                return (
                  <li
                    className="rounded-sm w-full flex items-center mobile-nav-links"
                    key={index}
                  >
                    <Link to={link} className="w-full py-md px-x-sm label1">
                      {t(label)}
                    </Link>
                  </li>
                );
              })
            : null}
        </ul>
        <div className="mobile-nav-lang-fs">
          <LanguageAndFontSize lng={lng} headVanMobile={true} />
        </div>
      </nav>
      {showNavBar && (
        <div
          className="pop-up-layout fixed z-10 w-full h-full top-0 lg:hidden"
          style={{ zIndex: "10" }}
          onClick={handleToggle}
        ></div>
      )}
    </>
  );
};

export default Header;
