import NoteTemplate from '../../components/registration_form/NoteTemplate'
import FormHeaderTemplate from '../../components/form/FormHeaderTemplate';
import Checkbox from "../../components/input/Checkbox";
import MtCaptcha from "../../components/MtCaptcha/MtCaptcha";

const StepTwo = ({ disabledBtn, setDisableBtn, updateFields, data, error, loadingCapthcha, t }) => {

    return (
        <>
            <FormHeaderTemplate title="Account Registration" subtitle="Please read the important notes before register a new account" t={t}/>
            <NoteTemplate title="Terms and Conditions" t={t}>
                <p>Last Revised: December 16, 2023</p>
                <br/>
                <p>Welcome to RVD. This site is provided as a service to our visitors and may be used for informational purposes only. Because the Terms and Conditions contain legal obligations, please read them carefully.</p>
                <br/>
                <p>The information provided by you will be used for the purpose of carrying out the provisions of the Rating Ordinance, the Landlord and Tenant (Consolidation) Ordinance and the Government Rent (Assessment and Collection) Ordinance; and may be forwarded to the Commissioner of Inland Revenue for the assessment and collection of tax imposed under the Inland Revenue Ordinance.</p>
                <br/>
                <p>Apart from the purpose stated above, personal information will not be transferred to any other parties, unless such parties are authorised by law and request the information.</p>
                <br/>
                <p>Under the Personal Data (Privacy) Ordinance, you have a right to request access to and correction of your personal data. Such requests may be made in writing to the {`department's`} Customer Service Officer at 15/F, Cheung Sha Wan Government Offices, 303 Cheung Sha Wan Road, Kowloon or by email to <a href="mailto: enquiries@rvd.gov.hk" className="text-surface-primary font-[700] hover:underline">enquiries@rvd.gov.hk</a>.</p>
                <br/>
                <p>More terms and conditions text here lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                <div className="mt-x-big pt-x-big">
                    <Checkbox itemStart={true} labelClass='label1' inputLabel="I confirm that I have read and accepted the terms and conditions and privacy policy." name="privacy_policy" setDisableBtn={setDisableBtn} disabledBtn={disabledBtn} updateFields={updateFields} data={data['privacy_policy']} check={data['privacy_policy'] === '1'} t={t}/>
                </div>
                <MtCaptcha loadingCapthcha={loadingCapthcha} error={error['captcha_verify_code']}/>
            </NoteTemplate>
        </>
    )
}

export default StepTwo;
