import React, { useState } from "react";
import { Select, MenuItem, Divider } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";

const WhiteStatueSelect = ({
  name,
  placeholder,
  options,
  defaultValue,
  action,
  disabled,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || "");
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const lang = language.charAt(0).toUpperCase() + language.slice(1);

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    if (action) action(newValue);
  };

  const renderValue = (selected) => {
    if (!selected) {
      return <span className="label1-400 text-body">{placeholder}</span>;
    }
    const option_value = options.find(
      (option) => option.value === selected || option.id === selected
    );
    if (option_value) {
      return option_value.label || option_value?.[`name${lang}`] || "";
    }
    return "";
  };

  const renderMenuItem = (value, label) => (
    <MenuItem
      key={value}
      value={value}
      onClick={() => handleValueChange({ target: { value } })}
      sx={{
        "&:hover": {
          "&.Mui-selected": {
            background: "var(--surface-secondary)",
          },
          background: "var(--surface-secondary)",
        },
        "&.MuiList-root": { padding: "0" },
        "&.Mui-selected": {
          color: "var(--text-body) !important",
          background: "none",
        },
        maxWidth: "100%",
        whiteSpace: "normal",
        display: "flex",
        gap: "var(--spacing-md)",
        padding: "var(--spacing-x-sm)",
        borderRadius: "var(--radius-x-sm)",
      }}
    >
      {label}
    </MenuItem>
  );

  return (
    <Select
      IconComponent={KeyboardArrowDownIcon}
      name={name}
      className={`body1 text-body w-full text-body"`}
      value={selectedValue || ""}
      displayEmpty
      disabled={disabled}
      renderValue={renderValue}
      MenuProps={{
        PaperProps: {
          className: "custom-scrollbar p-x-sm",
          style: {
            maxHeight: "322px",
            marginTop: "var(--spacing-sm)",
            overflowY: "auto",
            background: "var(--surface-card)",
            borderRadius: "var(--radius-sm)",
            boxShadow: "var(--box-shadow-lg)",
          },
        },
        MenuListProps: { style: { padding: "0" } },
      }}
      sx={{
        "&.MuiInputBase-root": { border: "0" },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "1px solid var(--surface-primary)",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--border-default)",
        },
        ".MuiOutlinedInput-notchedOutline": {
          border: "1px solid var(--border-default)",
        },
        background: "var(--surface-card)",
        height: "48px",
        borderRadius: "var(--radius-sm)",
      }}
    >
      {renderMenuItem("", "All")}
      {options.map((item) =>
        renderMenuItem(
          item?.value ? item?.value : item?.id,
          item?.label ? item?.label : item?.[`name${lang}`]
        )
      )}
    </Select>
  );
};

export default WhiteStatueSelect;
