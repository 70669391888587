import { useState, createContext, useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import { AddDataToHTML } from "../assets/js/global";

const FontSizeContext = createContext({
    fontSize: '',
    setSmall: () => {},
    setMiddle: () => {},
    setLarge: () => {},
});

const FontSizeProvider = ({ children }) => {
    const [cookies, setCookie] = useCookies(["fontSize"]);
    const [fontSize, setFontSize] = useState(cookies["fontSize"] || '');

    useEffect(() => {
        if (fontSize) {
            AddDataToHTML("data-fs", fontSize);
            setCookie("fontSize", fontSize, { path: '/' });
        }
    }, [fontSize, setCookie]);

    const setDatatoCookie = (fs) => {
        setCookie("fontSize", fs, { path: '/' });
        AddDataToHTML("data-fs", fs);
    };

    const setSmall = () => {
        setFontSize("small_size");
        setDatatoCookie("small_size");
    };

    const setMiddle = () => {
        setFontSize("middle_size");
        setDatatoCookie("middle_size");
    };

    const setLarge = () => {
        setFontSize("large_size");
        setDatatoCookie("large_size");
    };

    return (
        <FontSizeContext.Provider value={{ fontSize, setSmall, setMiddle, setLarge }}>
            {children}
        </FontSizeContext.Provider>
    );
};

export const useFontSizeContext = () => useContext(FontSizeContext);
export default FontSizeProvider;
