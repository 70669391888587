const NoteTemplate = ({ title, children, t }) => {
    return (
        <div className="notes text-justify">
            <p className="subtitle">
                { t(title) }
            </p>
            <article className="py-x-big body1">
                { children }
            </article>
        </div>
    )
}

export default NoteTemplate;
