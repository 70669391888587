import TextHeadline from "../../components/TextHeadline";
import { useTranslation } from "react-i18next";
import { ReactComponent as LeftBg } from '../../assets/images/help-centre/leftBg.svg';
import { ReactComponent as RightBg } from '../../assets/images/help-centre/rightBg.svg';
import ProfileSection from "../../components/my-profile/ProfileSection";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import LoadingUI from "../../components/loading/LoadingUI";
import React, {useEffect, useState} from "react";
import ArticlesTemplate from "../../components/help-centre/ArticlesTemplate";
import Breadcrumb from "../../components/header/Breadcrumb";
import { BigHelpCentreQuickLinkCard } from "../../components/help-centre/HelpCentreQuickLink";
import HelpCentreSearchBar from "../../components/help-centre/HelpCentreSearchBar";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import useDocumentTitle from "../../hooks/useDocument";

const HelpCentrePage = ({ lng }) => {
    const { t } = useTranslation();
    const { userLoading, token } = useUserLoginContext();
    const [articlesHighlight, setArticlesHighlight] = useState([]);
    const lang = lng ? lng[0].toUpperCase() + lng.slice(1) : '';
    const { bigSearchBar } = HelpCentreSearchBar({ t, lng });
    const { fetchData } = SubmitAndFetch({ lng, t});
    useDocumentTitle("Help Centre");
    const handleSubmit = (event) => {
        event.preventDefault();
    }

    useEffect(() => {
        const fetchArticles = async () => {
            await fetchData('article/highlight', null, setArticlesHighlight, null, lng, token)
        }
        if (token) {
            fetchArticles();
        }
    }, [token]);

    if (userLoading) return <LoadingUI/>;

    return (
        <>
            <Breadcrumb
                lng={lng}
                homeElement={'Home'}
                capitalizeLinks
            />
            <div className="layout-container content-container flex flex-col gap-lg">
                <TextHeadline title="Help Centre" />
                <div className="help-centre-banner bg-surface-primary flex justify-center text-center flex-col items-center rounded-big py-x-lg px-[100px] relative" style={{
                    backgroundImage: `url(${<LeftBg />}), url(${<RightBg />})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left bottom, right bottom',
                }}>
                    <h1 className='h1 text-white'>{t('Help, Support, and Documentation')}</h1>
                    <p className='body1 text-white mt-x-sm'>{t('Learn how to use RVD e-Services to complete your tasks efficiently and enjoyably.')}</p>
                    {bigSearchBar()}
                </div>
                <ProfileSection title='Quick Link' subtitle='Learn more about this web portal in a glance.' t={t}>
                    <BigHelpCentreQuickLinkCard lng={lng} t={t}/>
                </ProfileSection>
                <ProfileSection t={t} title='Articles Highlights'>
                    <div className='grid-cols-3 grid gap-x-big'>
                        <ArticlesTemplate data={articlesHighlight} lang={lang} lng={lng}/>
                    </div>
                </ProfileSection>
            </div>
        </>
    )
}

export default HelpCentrePage;
