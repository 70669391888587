import SectionTemplate from "../../components/proposal-submission-form/sectionTemplate";
import { ImportButton, UploadButton } from "../button/SingleButton";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ModelMenu from "../../components/menu/ModelMenu";
import ErrorMessage from "../../components/error/ErrorMessage";
import UPLOAD_DOC_TYPE from "../../data/upload_doc_type.json";
import { useDropzone } from "react-dropzone";
import UploadFilePreview from "../../components/upload/UploadFilePreview";
import documentTypeOption from "../../data/document_type_options.json";
import SmallFunction from "../../components/function/SmallFunction";
import UploadPopup from "../../components/popup/UploadPopup";
import ImportPopup from "../../components/popup/ImportPopup";
import fileFormatJSON from "../../data/file_format.json";
import BasicTag from "../../components/tags/BasicTag";
import searchByOptions from "../../data/file_searchbar_option.json";
import authorSearchByOptions from "../../data/author_searchbar_option.json";

const StepTwo = ({ data, t, error, setError, updateFields, lng, setData }) => {
  const [searchData, setSearchData] = useState([
    {
      original_name: "authorisation_22345.doc",
      source: "876543 - ABC Development Limited - DEF Agency Limited ",
      type: 1,
      size: "128 KB",
      created_at: "2024/01/04 09:41:45",
    },
  ]);
  const [docSearchSelection, setDocSearchSelection] = useState([]);
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [fileSize, setFileSize] = useState(null);
  const [numberOfUploading, setNumberOfUploading] = useState(null);
  const [acceptType, setAcceptType] = useState({});
  const [uploadedFile, setUploadedFile] = useState({});
  const [documentType, setDocumentType] = useState(null);
  const [showImport, setShowImport] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const { getCategoryLabels } = SmallFunction();

  const searchList = {
    setSearchData,
    searchData,
    setDocSearchSelection,
    docSearchSelection,
  };

  const PopupProps = (api_name, setState) => ({
    setState,
    api_name,
    t,
    setError,
  });
  const uploadPopupProps = (
    name,
    title,
    subtitle,
    downloadText,
    downloadPath,
    submit_api_name,
    type
  ) => ({
    uploadedFile: data,
    setUploadFile: setData,
    handleSubmit: () => {
      setShowUpload(false);
      setUploadedFile((prevState) => ({ ...prevState, [name]: data[name] }));
    },
    showUpload,
    setShowUpload,
    updateFields,
    PopupProps,
    name,
    title,
    subtitle,
    downloadText,
    downloadPath,
    submit_api_name,
    progressBar: true,
    type,
    t,
    lng,
  });

  const uploadFiles = (value) => {
    setError({ ...error, ["supplementary_documents"]: "" });
    const selectedOption = documentTypeOption.find(
      (option) => option.value === value
    );
    if (selectedOption.upload_info) {
      const documentInfo = selectedOption.upload_info;
      setAcceptType(documentInfo.type);
      setFileSize(documentInfo.file_size);
      setNumberOfUploading(documentInfo.number_of_uploading);

      setOpenFileDialog(true);
    }
    setDocumentType(value);
  };

  const importFiles = (value) => {
    setError({ ...error, ["supplementary_documents"]: "" });
    setShowImport(true);
    setDocumentType(value);
  };

  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      accept: acceptType,
      maxSize: fileSize * 1024 * 1024,
      multiple: numberOfUploading > 1,
      maxFiles: numberOfUploading,
    });

  useEffect(() => {
    if (openFileDialog) {
      open();
      setOpenFileDialog(false);
    }
  }, [openFileDialog]);

  useEffect(() => {
    const document_info = documentTypeOption.find(
      (option) => option.value === documentType
    );
    if (fileRejections.length > 0) {
      const fileRejection = fileRejections[0];
      const { file, errors } = fileRejection;
      let errorMessage = "";
      errors.forEach((err) => {
        switch (err.code) {
          case "file-too-large":
            errorMessage = `${t(
              "File size exceeded the limit. Maximum file size allowed is "
            )} ${fileSize} MB`;
            break;
          case "too-many-files":
            errorMessage = `${t(
              "You can only upload "
            )} ${numberOfUploading}  ${t(" file(s) for the ")}  ${
              document_info.label
            }`;
            break;
          default:
            errorMessage = err.message;
            break;
        }
      });
      setError({ ...error, supplementary_documents: errorMessage });
    }
    if (acceptedFiles.length > 0) {
      acceptedFiles.map((item) => {
        const uploadDateTime = new Date().toLocaleString();
        item["updated_at"] = uploadDateTime;
        item["document_type"] = document_info;
        if (documentType === 5) {
          if (
            !uploadedFile[document_info.label] ||
            (uploadedFile[document_info.label] &&
              acceptedFiles.length + uploadedFile[document_info.label].length <=
                10)
          ) {
            setUploadedFile((prevState) => {
              const updatedFileArray = prevState[document_info.label]
                ? [...prevState[document_info.label]]
                : [];
              updatedFileArray[updatedFileArray.length] = item;
              return { ...prevState, [document_info.label]: updatedFileArray };
            });
          } else {
            setError({
              ...error,
              ["supplementary_documents"]: `${t("You can only upload ")} 10 ${t(
                " file(s) for the "
              )} ${document_info.label}`,
            });
          }
        } else {
          if (!uploadedFile[document_info.label]) {
            setUploadedFile((prevState) => ({
              ...prevState,
              [document_info.label]: item,
            }));
          } else {
            setError({
              ...error,
              ["supplementary_documents"]: `${t("You can only upload ")} 1 ${t(
                " file(s) for the "
              )} ${document_info.label}`,
            });
          }
        }
      });
    }
  }, [acceptedFiles, fileRejections]);

  const uploadRentInfo = () => {
    setShowUpload(true);
  };

  const uploadOptions = [
    // {value: 1, label: 'Identity Proof Document', action: uploadFiles, disabled: uploadedFile['Identity Proof Document']},
    {
      value: 2,
      label: "Authorisation Letter",
      action: uploadFiles,
      disabled: uploadedFile["Authorisation Letter"],
    },
    {
      value: 3,
      label: "Rental Information",
      action: uploadRentInfo,
      disabled: uploadedFile["Rental Information"],
    },
    {
      value: 4,
      label: "Tenancy Particulars",
      action: uploadFiles,
      disabled: uploadedFile["Tenancy Particulars"],
    },
    {
      value: 5,
      label: "Other Supporting Documents",
      action: uploadFiles,
      disabled:
        uploadedFile["Other Supporting Documents"] &&
        uploadedFile["Other Supporting Documents"].length === 10,
    },
  ];

  const importOptions = [
    // {value: 1, label: 'Identity Proof Document', action: importFiles, disabled: uploadedFile['Identity Proof Document']},
    {
      value: 2,
      label: "Authorisation Letter",
      action: importFiles,
      disabled: uploadedFile["Authorisation Letter"],
    },
    {
      value: 3,
      label: "Rental Information",
      action: importFiles,
      disabled: uploadedFile["Rental Information"],
    },
    {
      value: 4,
      label: "Tenancy Particulars",
      action: importFiles,
      disabled: uploadedFile["Tenancy Particulars"],
    },
    {
      value: 5,
      label: "Other Supporting Documents",
      action: importFiles,
      disabled:
        uploadedFile["Other Supporting Documents"] &&
        uploadedFile["Other Supporting Documents"].length === 10,
    },
  ];

  const searchColumns = useMemo(
    () => [
      {
        accessorKey: "original_name",
        header: "Document Name",
        minSize: 300,
        grow: false,
        enableSorting: false,
      },
      {
        accessorKey: "source",
        header: "Source",
        minSize: 286,
        grow: false,
        enableSorting: false,
      },
      {
        accessorKey: "type",
        header: "Format",
        minSize: 100,
        grow: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          let format = {};
          if (cell.getValue()) {
            format = getCategoryLabels(
              cell.getValue(),
              fileFormatJSON,
              "label"
            );
          }
          return (
            <span className="label2">
              {Object.entries(format).length > 0 && (
                <span className="body2">
                  <BasicTag
                    key={format.id}
                    label={format.label}
                    color={format.color}
                  />
                </span>
              )}
            </span>
          );
        },
      },
      {
        accessorKey: "size",
        header: "Size",
        minSize: 100,
        grow: false,
        enableSorting: false,
      },
      {
        accessorKey: "created_at",
        header: "Uploaded Date ",
        minSize: 200,
        grow: true,
      },
    ],
    [searchList.searchData]
  );

  const buttonSet = () => {
    return (
      <div className="flex gap-sm">
        <ModelMenu
          options={importOptions}
          buttons={<ImportButton t={t} selectIcon={true} />}
        />
        <ModelMenu
          options={uploadOptions}
          buttons={<UploadButton t={t} selectIcon={true} />}
        />
      </div>
    );
  };

  return (
    <>
      {showImport && (
        <ImportPopup
          setState={setShowImport}
          PopupProps={PopupProps}
          searchColumns={searchColumns}
          searchByOptions={
            documentType === 2 ? authorSearchByOptions : searchByOptions
          }
          searchList={searchList}
          multi={documentType === 3 || documentType === 5}
          title={`Upload ${
            documentTypeOption.find((option) => option.value === documentType)
              .label
          }`}
          handleSubmit={() => {
            setShowImport(false);
          }}
          t={t}
        />
      )}
      {showUpload && (
        <UploadPopup
          {...uploadPopupProps(
            "rental_information",
            "Upload Rental Information",
            "You can utilise this rental information template to provide supplementary information for your properties. Support maximum 2,000 properties rental information for each file.",
            "Download Rental Information Template",
            "/assets/upload/upload_property_template.csv",
            UPLOAD_DOC_TYPE.RENTAL_INFORMATION
          )}
        />
      )}

      <SectionTemplate
        t={t}
        title="Upload Supplementary Documents"
        buttonBar={buttonSet()}
      >
        {Object.entries(uploadedFile).length > 0 &&
          Object.entries(uploadedFile).map(([key, item], index) => {
            return (
              <React.Fragment key={index}>
                {key === "Other Supporting Documents" ? (
                  item.map((i, i_index) => {
                    return (
                      <div key={i.document_type.label + i_index}>
                        <UploadFilePreview
                          t={t}
                          data={i}
                          document_type={i.document_type}
                          handleDeleteFile={() => {
                            setError({
                              ...error,
                              ["supplementary_documents"]: "",
                            });
                            const newArray = [...item];
                            newArray.splice(i_index, 1);
                            setUploadedFile((prevState) => ({
                              ...prevState,
                              [key]: newArray,
                            }));
                          }}
                        />
                      </div>
                    );
                  })
                ) : (
                  <UploadFilePreview
                    t={t}
                    data={item}
                    document_type={item.document_type}
                    handleDeleteFile={() => {
                      if (key === "rental_information") {
                        const newDataObject = { ...data };
                        delete newDataObject[key];
                        setData(newDataObject);
                      }
                      setError({ ...error, ["supplementary_documents"]: "" });
                      const newObject = { ...uploadedFile };
                      delete newObject[key];
                      setUploadedFile(newObject);
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}
        {error ? (
          <ErrorMessage message={error["supplementary_documents"]} t={t} />
        ) : null}
      </SectionTemplate>
    </>
  );
};

export default StepTwo;
