import Input from "../../components/input/Input";
import FieldCard from "../../components/form/FieldCard";
import PasswordTemplate from "../../components/form/password/template";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../components/error/ErrorMessage";

const StepThree = ({ props, updateFields, PasswordData, PasswordError, ConfirmData, ConfirmError, api_name, data, slug, lng, setError, timeLeft, setTimeLeft, t}) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (slug && slug === 'username' && data['username']) {
            const intervalId = setInterval(() => {
                setTimeLeft((t) => {
                    if (t <= 1) {
                        navigate(`/${lng}/login`);
                    }
                    return t - 1;
                });
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [slug, data['username'], navigate, lng]);


    return (
        <>
            {api_name === 'forgot' ?
                slug && slug === 'password' ?
                    <div className="gap-md flex flex-col">
                        <FieldCard title="Reset Your Password" t={t}>
                            <PasswordTemplate
                                t={t}
                                setError={setError}
                                updateFields={updateFields}
                                passwordData={PasswordData}
                                passwordError={PasswordError}
                                confirmData={ConfirmData}
                                confirmError={ConfirmError}/>
                        </FieldCard>
                    </div>
                    :
                    <FieldCard title={`Your Username:`} t={t}>
                        <p className='font-[700] label1'>{data['username']}</p>
                        <p>{t('You will be redirected to the login page in')} {timeLeft} {t('seconds.')}</p>
                    </FieldCard>
                :
                <div className="gap-md flex flex-col">
                    <FieldCard title="Create Your Account" t={t}>
                        <Input {...props('username', 'Username')} />
                        <PasswordTemplate
                            t={t}
                            setError={setError}
                            updateFields={updateFields}
                            passwordData={PasswordData}
                            passwordError={PasswordError}
                            confirmData={ConfirmData}
                            confirmError={ConfirmError}/>
                    </FieldCard>
                </div>
            }
        </>
    )
}

export default StepThree;
