import Skeleton from "@mui/material/Skeleton";

export function LoadingTextSkeleton() {
    return (
        <div>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
        </div>
    );
}

export function LoadingImageSkeleton({w, h, containerClassName}) {
    return (
        <div className={containerClassName}>
            <Skeleton variant="rectangular" width={w} height={h}/>
            <Skeleton animation="wave" />
        </div>
    );
}