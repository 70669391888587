import { useEffect, useState } from "react";
import Popup from "../../components/popup/Popup";
import ProfileSection from "../../components/my-profile/ProfileSection";
import CateSelect2 from "../../components/input/portal/CateSelect2";
import FileDropzone from "../../components/upload/FileDropzone";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import { Link } from "react-router-dom";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import { useTranslation } from "react-i18next";

const UploadPopup = ({
  uploadedFile,
  name,
  apiProps,
  updateFields,
  SelectCateProps, //name, cateId, setSelectCate
  UploadContextProps, //title, subtitle,downloadText,downloadPath,
  stateProps, //filterOption.assignCategoryList
  DropzoneProps, //progressBar, numberOfUploading, fileSize, type
  PopupProps, // function
  showUpload,
  setShowUpload,
  t,
  lng,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [disabledContinue, setDisabledContinue] = useState(true);
  const [onReset, setOnReset] = useState(false);
  const { downloadData } = SubmitAndFetch({ lng: language, t });
  const { token } = useUserLoginContext();

  useEffect(() => {
    switch (name) {
      case "upload_property_file":
      case "support_documents":
      case "rental_information":
      case "import_property_file":
      case "upload_rent_file":
        if (
          Object.entries(uploadedFile)?.length &&
          uploadedFile?.[name] &&
          (uploadedFile?.[name]?.length ||
            Object.entries(uploadedFile?.[name])?.length)
        ) {
          setDisabledContinue(false);
        } else {
          setDisabledContinue(true);
        }
        break;
    }
  }, [uploadedFile, name]);

  const reUpload = (event) => {
    setOnReset(true);
    updateFields({ [name]: {} });
  };

  const handleDownloadTemplate = async () => {
    await downloadData(
      apiProps?.downloadTemplateAPI,
      token,
      apiProps?.appendData,
      apiProps?.withFile
    );
  };

  let backLabel = null;
  let reupload = null;
  if (
    Object.entries(uploadedFile).length &&
    uploadedFile?.[name] &&
    (uploadedFile?.[name].length ||
      Object.entries(uploadedFile?.[name])?.length)
  ) {
    backLabel = "Re-upload File";
    reupload = reUpload;
  }

  const buttonProps = {
    backLabel: backLabel ?? null,
    forwardLabel: "Continue",
    disabled: disabledContinue,
    reupload: reupload,
  };

  return (
    <Popup
      {...PopupProps(setShowUpload)}
      buttonProps={buttonProps}
      classes="w-[1150px]"
      sx={{ padding: "var(--spacing-xx-lg) var(--spacing-xxx-lg)" }}
    >
      <div className="flex flex-col gap-md md:gap-xxx-big flex-1 overflow-y-auto md:custom-scrollbar">
        <div className="flex flex-col gap-sm sticky top-0 bg-white z-10">
          <h3 className="h3 text-title">{UploadContextProps?.title ?? ""}</h3>
          <div className="flex gap-sm flex-col">
            <span className="body1 text-title">
              {UploadContextProps?.subtitle ?? ""}
            </span>
            <Link
              title={UploadContextProps?.downloadText}
              className="label1 text-label-icon-on-light"
              onClick={handleDownloadTemplate}
            >
              {UploadContextProps?.downloadText ?? ""}
            </Link>
          </div>
        </div>
        {SelectCateProps && (
          <div className="flex flex-col gap-sm">
            <ProfileSection title="Select Category" t={t} />
            <CateSelect2
              updateFields={updateFields}
              name={SelectCateProps?.name}
              cateId={SelectCateProps?.cateId}
              categories_options={SelectCateProps?.cateListOption}
              setSelectCate={SelectCateProps?.setSelectCate}
              selectEmpty={true}
              error={apiProps?.error?.[SelectCateProps?.name]}
              setError={apiProps?.setError}
              changeCateAction={() => setOnReset(true)}
            />
          </div>
        )}
        <div className="flex flex-col gap-sm flex-1 w-full">
          <ProfileSection title="Upload File" t={t} />
          <input value={uploadedFile[name] || ""} name={name} type="hidden" />
          <div className="flex-1 flex flex-col gap-sm">
            <FileDropzone
              uploadedFile={uploadedFile}
              setUploadedFile={DropzoneProps?.setUploadedFile}
              setError={apiProps?.setError}
              apiProps={apiProps}
              title=""
              fileSize={DropzoneProps?.fileSize}
              updateFields={updateFields}
              name={name}
              data={DropzoneProps?.data}
              type={DropzoneProps?.type}
              progressBar={DropzoneProps?.progressBar}
              numberOfUploading={DropzoneProps?.numberOfUploading}
              status={showUpload}
              t={t}
              current_file_length={DropzoneProps?.current_file_length}
              onResetState={{
                setOnReset: setOnReset,
                onReset: onReset,
              }}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default UploadPopup;
