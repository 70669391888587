import AccountManagementTemplate from "../../components/account_management/Template";
import React, { useEffect, useMemo, useRef, useState } from "react";
import TabTemplate from "../../components/tab/TabTemplate";
import {
  ArrowBackButton,
  ImportButton,
  UploadButton,
  DeleteWithTextButton,
  DiscardButton,
  RemoveButton,
} from "../../components/button/SingleButton";
import CategoryTable from "../../components/table/CategoryTable";
import Input from "../../components/input/Input";
import ProfileSection from "../../components/my-profile/ProfileSection";
import MySelect from "../../components/input/Select";
import Checkbox from "../../components/input/Checkbox";
import MyDatePicker from "../../components/input/DatePicker";
import ChipStatusSelect from "../../components/input/portal/ChipStatusSelect";
import { useTranslation } from "react-i18next";
import UploadPopup from "../../components/popup/UploadPopup";
import BasicTag from "../../components/tags/BasicTag";
import ImportPopup from "../../components/popup/ImportPopup";
import { useDeepCompareEffect } from "react-use";
import { DeepEqual } from "../../assets/js/global";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import { useParams, Link, useLocation } from "react-router-dom";
import UPLOAD_DOC_TYPE from "../../data/upload_doc_type.json";
import DOC_TYPE_OPTION from "../../data/document_type_options.json";
import searchOptions from "../../data/file_searchbar_option.json";
import LoadingUI from "../../components/loading/LoadingUI";
import dayjs from "dayjs";
import UploadFilePreview from "../../components/upload/UploadFilePreview";
import ModelMenu from "../../components/menu/ModelMenu";
import Popup from "../../components/popup/Popup";
import useDocumentTitle from "../../hooks/useDocument";

const uploadFileType = UPLOAD_DOC_TYPE.OTHER_SUPPORTING_DOCUMENTS;

const PropertyEdit = ({ lng }) => {
  const { t } = useTranslation();
  const { pid } = useParams();
  const { state } = useLocation();
  const cid = state?.cid ?? "";
  const { account_type, userLoading, profileData, token } =
    useUserLoginContext();
  const [data, setData] = useState({}); //save or update user data
  const [propertyData, setPropertyData] = useState({}); //save or update user data
  const [originalData, setOriginalData] = useState({});
  const [filterOption, setFilterOption] = useState({});
  const [rows, setRows] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [originalRowSelection, setOriginalRowSelection] = useState({});
  const [enableEdit, setEnableEdit] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const [docSearchSelection, setDocSearchSelection] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [error, setError] = useState([]);
  const [showSupportingUpload, setShowSupportingUpload] = useState(false);
  const [showDeletePropertyPopup, setShowDeletePropertyPopup] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [uploadedFile, setUploadFile] = useState([]);
  const [importFileIds, setImportFileIds] = useState([]);
  const [changed, setChanged] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const lang = lng[0].toUpperCase() + lng.slice(1);

  const assessment_number = originalData?.assessmentNo;
  const address_or_desc_of_tenement = originalData?.address;
  const change_of_rv = originalData?.changeRv;
  const api_name = "property/update";

  useDocumentTitle(`Property Details | ${originalData ? originalData?.assessmentNo : ''}`);

  const { postForm, loading } = SubmitAndFetch({ lng, t });
  const searchList = {
    setSearchData,
    searchData,
    setDocSearchSelection,
    docSearchSelection,
    updateFields,
    data,
  };

  useEffect(() => {
    if (originalData?.assessmentNo) {
      document.title = `${t("web_portal_title_text")} - ${t("Property Details")} | ${originalData?.assessmentNo}`;
    }
  }, [originalData?.assessmentNo]);

  useEffect(() => {
    const fetchData = async () => {
      await postForm(
        `property/${pid}`,
        null,
        null,
        null,
        null,
        false,
        saveData,
        token,
        null,
        null,
        false
      );
      await postForm(
        `property/filter`,
        null,
        null,
        null,
        null,
        false,
        null,
        token,
        setFilterOption,
        null,
        false
      );
    };
    fetchData();
  }, [token]);

  useEffect(() => {
    if (!showSupportingUpload) {
      setUploadFile([]);
    }
  }, [showSupportingUpload]);

  const saveData = (resData) => {
    setError({});
    setChanged(false);
    if (resData) {
      setOriginalData(resData);
      const propertyObject = {
        status_occupation: resData?.statusOccupation,
        status_physical: resData?.statusPhysical,
        remarks: resData?.remarks,
        supporting_doc_list: resData?.supportingDocList,
        rent_monthly: resData?.rentMonthly,
        rent_lease_commenced_date: resData?.rentLeaseCommencedDate,
        rent_lease_years: resData?.rentLeaseYears,
        rent_lease_months: resData?.rentLeaseMonths,
        rent_free_months: resData?.rentFreeMonths,
        rent_free_days: resData?.rentFreeDays,
        additional_payment_is_rates: resData?.additionalPaymentIsRates,
        additional_payment_rates: resData?.additionalPaymentRates,
        additional_payment_is_gov_rent: resData?.additionalPaymentIsGovRent,
        additional_payment_gov_rent: resData?.additionalPaymentGovRent,
        additional_payment_is_mgt_fee: resData?.additionalPaymentIsMgtFee,
        additional_payment_mgt_fee: resData?.additionalPaymentMgtFee,
        additional_payment_is_air_con_fee:
          resData?.additionalPaymentIsAirConFee,
        additional_payment_air_con_fee: resData?.additionalPaymentAirConFee,
        is_rent: resData?.isRent,
      };

      setData(propertyObject);
      setPropertyData(propertyObject);
      setEnableEdit(resData?.canEdit);
      setIsShared(resData?.isShared);
      setRows(resData?.categoryList);
      if (Object.entries(resData?.categoryList).length) {
        const initialCheckboxState = {};
        const setInitialCheckboxState = (row) => {
          if (row.isIn) {
            initialCheckboxState[row.id] = row.isIn;
          }
          if (row.children) {
            row.children.forEach((subRow) => setInitialCheckboxState(subRow));
          }
        };
        resData?.categoryList.forEach((row) => setInitialCheckboxState(row));
        setRowSelection(initialCheckboxState);
        setOriginalRowSelection(initialCheckboxState);
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("id", pid);
    let slug = "";
    let withFile = false;
    switch (currentTab) {
      case 0:
        slug = "";
        withFile = true;
        let dataObj = {
          id: pid,
          status_occupation: data?.status_occupation?.value,
          status_physical: data?.status_physical?.value,
          remarks: data?.remarks,
          import_file_ids: importFileIds.join(","),
        };
        formData.append("data", JSON.stringify(dataObj));

        Object.entries(data?.supporting_doc_list).map((item, index) => {
          if (item[1] instanceof File)
            formData.append(`supporting_docs`, item[1]);
        });
        break;
      case 1:
        slug = "category";
        const newSelectData = Object.keys(rowSelection)
          .map((key) => [key])
          .join(",");
        formData.set("category_ids", newSelectData);
        break;
      case 2:
        slug = "rent";
        if (formData.get("rent_lease_commenced_date")) {
          const newDateData = dayjs(
            formData.get("rent_lease_commenced_date")
          ).format("YYYY-MM-DD");
          formData.set("rent_lease_commenced_date", newDateData);
        }
        break;
      default:
        slug = "";
        break;
    }
    postForm(
      api_name,
      slug,
      formData,
      setError,
      null,
      false,
      saveData,
      token,
      null,
      null,
      true,
      true,
      null,
      withFile
    );
  };

  const handleSaveSupportingDoc = () => {
    setData((prev) => ({
      ...prev,
      supporting_doc_list: uploadedFile.support_documents,
    }));
    setShowSupportingUpload(false);
  };

  const setToDefaultValue = () => {
    setUploadFile([]);
    setChanged(false);
    setData(propertyData);
    setRowSelection(originalRowSelection);
  };

  function updateFields(fields, setState) {
    setData((prev) => ({ ...prev, ...fields }));
  }

  useDeepCompareEffect(() => {
    if (
      !DeepEqual(data, propertyData) ||
      !DeepEqual(rowSelection, originalRowSelection)
    ) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [data, rowSelection]);

  const updateUploadFields = (fields) =>
    setUploadFile((prev) => ({ ...prev, ...fields }));

  const monthOption = () => {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({ value: i, label: i });
    }
    return options;
  };

  const dayOption = () => {
    const options = [];
    for (let i = 1; i <= 31; i++) {
      options.push({ value: i, label: i });
    }
    return options;
  };

  const uploadPopupProps = (
    name,
    showUpload,
    setShowUpload,
    UploadContextProps,
    DropzoneProps,
    apiProps,
    SelectCateProps
  ) => ({
    name,
    uploadedFile,
    showUpload,
    setShowUpload,
    stateProps,
    apiProps,
    PopupProps,
    updateFields: updateUploadFields,
    UploadContextProps,
    DropzoneProps,
    SelectCateProps,
    t,
    lng,
  });

  const stateProps = {
    setError,
    error,
  };

  const PopupProps = (setState) => ({
    id: pid,
    setState,
    t,
    apiProps: {
      setError,
      lng,
      loading,
      customSubmit: handleSaveSupportingDoc,
    },
  });

  const inputProps = (name, label, type) => {
    return {
      name,
      inputLabel: label,
      data: data ? data[name] : "",
      error: error ? error[name] : "",
      type,
      disabled: !enableEdit,
      updateFields,
      t,
      check: data ? data[name] : "",
      itemStart: false,
    };
  };

  const setColorToChangeOfRV = (change_of_rv) => {
    if (change_of_rv) {
      const numericValue = parseFloat(change_of_rv);

      if (numericValue > 0) {
        return <span className="text-red-600">{change_of_rv}</span>;
      }
      if (numericValue < 0) {
        return <span className="text-green-600">{change_of_rv}</span>;
      }
    }
  };

  const details = [
    {
      label: "Assessment No.",
      data: assessment_number,
    },
    {
      label: "Address or Description of Tenement",
      data: address_or_desc_of_tenement,
      borderTop: true,
    },
    {
      label: "% Change in RV",
      data: setColorToChangeOfRV(change_of_rv),
      borderTop: true,
    },
  ];

  const handleDeleteFile = (event, path) => {
    event.preventDefault();
    const newSupportingDocList = data?.supporting_doc_list;
    delete newSupportingDocList[path];
    setData((prev) => ({ ...prev, supporting_doc_list: newSupportingDocList }));
  };

  const uploadOptions = [
    {
      value: 1,
      label: "Tenancy Particulars",
      action: () => true,
      disabled: false,
    },
    {
      value: 2,
      label: "Other Supporting Documents",
      action: () => setShowSupportingUpload(true),
      disabled: data?.supporting_doc_list
        ? Object.entries(data?.supporting_doc_list).length >= 10
        : false,
    },
  ];

  const supportingDocumentField = () => {
    return (
      <div className="flex flex-col gap-big">
        {enableEdit ? (
          <div className="flex gap-sm">
            <ImportButton t={t} setState={setShowImport} />
            <ModelMenu
              options={uploadOptions}
              buttons={<UploadButton t={t} selectIcon={true} />}
            />
          </div>
        ) : null}
        {data &&
          data?.supporting_doc_list &&
          Object.entries(data?.supporting_doc_list).length > 0 &&
          Object.entries(data?.supporting_doc_list).map(([key, value]) => {
            return (
              <UploadFilePreview
                data={value}
                t={t}
                document_type={{
                  label: value?.[`label${lang}`]
                    ? value?.[`label${lang}`]
                    : "Other Supporting Documents",
                  color: value?.color ? value?.color : "#60A5FA",
                }}
                handleDeleteFile={handleDeleteFile}
                downloadApiProps={{
                  downloadApi: "property/document/download",
                  appendData: {
                    id: pid,
                    doc_id: value?.id,
                  },
                }}
              />
            );
          })}
      </div>
    );
  };

  const PropertyDetailsField = [
    {
      label: "Occupation Status",
      field: (
        <ChipStatusSelect
          {...inputProps("status_occupation")}
          t={t}
          options={filterOption ? filterOption?.statusOccupationList : []}
          lang={lang}
          disabled={!enableEdit}
        />
      ),
    },
    {
      label: "Physical Status",
      field: (
        <ChipStatusSelect
          {...inputProps("status_physical")}
          t={t}
          options={filterOption ? filterOption?.statusPhysicalList : []}
          lang={lang}
          disabled={!enableEdit}
        />
      ),
      borderTop: true,
    },
    {
      label: "Others",
      field: <Input {...inputProps("remarks", "Remarks")} />,
      borderTop: true,
    },
    {
      label: "Supporting Document",
      field: supportingDocumentField(),
      borderTop: true,
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Category",
        minSize: 600,
        grow: true,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <div className={row.original.parent ? "ml-[-40px]" : ""}>
              {CategoryColumn(
                row,
                cell,
                account_type,
                row.original.rowType !== "parent",
                false
              )}
            </div>
          );
        },
      },
    ],
    [rows]
  );

  const searchColumns = useMemo(
    () => [
      {
        accessorKey: "fileName",
        header: "Document Name",
        minSize: 286,
        grow: false,
        enableSorting: false,
      },
      {
        accessorKey: "source",
        header: "Source",
        minSize: 220,
        grow: false,
        enableSorting: false,
      },
      {
        accessorKey: "documentType",
        header: "Document Type",
        minSize: 300,
        grow: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <span className="label2">
              {cell.getValue() ? (
                <BasicTag
                  label={cell.getValue()?.[`label${lang}`]}
                  color={cell.getValue()?.color}
                />
              ) : (
                "-"
              )}
            </span>
          );
        },
      },
      {
        accessorKey: "uploadTime",
        header: "Uploaded Date ",
        objKey: "upload_date",
        minSize: 240,
        grow: true,
      },
    ],
    [searchData]
  );

  const uploadPopup = () => {
    return (
      <UploadPopup
        {...uploadPopupProps(
          "support_documents",
          showSupportingUpload,
          setShowSupportingUpload,
          {
            title: "Upload Supporting Documents",
            subtitle:
              "You can utilise various supporting document files to help you better manage related rates and government rent assessments for each property.",
          },
          {
            progressBar: false,
            type: uploadFileType,
            data: data?.supporting_doc_list,
            current_file_length: data?.supporting_doc_list
              ? Object.entries(data?.supporting_doc_list).length
              : 0,
          },
          {
            setError,
          }
        )}
      />
    );
  };

  const { CatTableTemplate, CategoryColumn } = CategoryTable({
    rows,
    columns,
    rowSelection: enableEdit ? rowSelection : false,
    setRowSelection,
    t,
  });

  const propertyCategoryList = () => {
    return CatTableTemplate(
      "Property Categories",
      true,
      false,
      null,
      !isShared,
      false
    );
  };
  const propertyDetails = () => {
    return (
      <div className="flex flex-col gap-xx-sm">
        {PropertyDetailsField.map((item, index) => {
          return (
            <div
              className={`flex items-center gap-big py-md justify-between md:justify-start max-w-full ${
                item.borderTop
                  ? "border-t border-neutral-100 border-dashed"
                  : ""
              }`}
              key={index}
            >
              <div className="w-xx-huge min-w-[128px] max-w-[128px] md:min-w-240px md:max-w-240px body2 text-title inline-block">
                {item.label}
              </div>
              <div className="text-body label1 break-words whitespace-normal flex-1">
                {item.field ?? "--"}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const rentDetails = () => {
    return (
      <div className="flex flex-col gap-x-big">
        {data?.is_rent ? (
          <>
            <ProfileSection title="Let Rent" t={t}>
              <Input
                {...inputProps("rent_monthly", "Monthly Rent (HKD)", "number")}
              />
            </ProfileSection>
            <div className="border-t border-100 border-dashed"></div>
            <ProfileSection title="Rental Period" t={t}>
              <MyDatePicker
                {...inputProps(
                  "rent_lease_commenced_date",
                  "Lease Commenced Date"
                )}
              />
              <Input {...inputProps("rent_lease_years", "Years", "number")} />
              <Input
                min={1}
                max={12}
                {...inputProps("rent_lease_months", "Months", "number")}
              />
            </ProfileSection>
            <div className="border-t border-100 border-dashed"></div>
            <ProfileSection title="Rent Free Period" t={t}>
              <div className="flex gap-x-big">
                <MySelect
                  options={monthOption()}
                  {...inputProps("rent_free_months", "Months")}
                />
                <MySelect
                  options={dayOption()}
                  {...inputProps("rent_free_days", "Days")}
                />
              </div>
            </ProfileSection>
            <div className="border-t border-100 border-dashed"></div>
            <ProfileSection
              t={t}
              title="Additional Payment"
              subtitle="Please state the following payment amounts and tick the appropriate box(es) if the TENANT is responsible for these payments in addition to the rent stated above."
            >
              <div className="flex gap-x-big items-center pl-sm">
                <div className="min-w-280px">
                  <Checkbox
                    {...inputProps("additional_payment_is_rates", "Rates")}
                  />
                </div>
                <Input
                  {...inputProps("additional_payment_rates", "Per Month (HKD)")}
                />
              </div>
              <div className="flex gap-x-big items-center pl-sm">
                <div className="min-w-280px">
                  <Checkbox
                    {...inputProps(
                      "additional_payment_is_gov_rent",
                      "Government Rent"
                    )}
                  />
                </div>
                <Input
                  {...inputProps(
                    "additional_payment_gov_rent",
                    "Per Month (HKD)"
                  )}
                />
              </div>
              <div className="flex gap-x-big items-center pl-sm">
                <div className="min-w-280px">
                  <Checkbox
                    {...inputProps(
                      "additional_payment_is_mgt_fee",
                      "Management Fee"
                    )}
                  />
                </div>
                <Input
                  {...inputProps(
                    "additional_payment_mgt_fee",
                    "Per Month (HKD)"
                  )}
                />
              </div>
              <div className="flex gap-x-big items-center pl-sm">
                <div className="min-w-280px">
                  <Checkbox
                    {...inputProps(
                      "additional_payment_is_air_con_fee",
                      "Air-conditioning Fee"
                    )}
                  />
                </div>
                <Input
                  {...inputProps(
                    "additional_payment_air_con_fee",
                    "Per Month (HKD)"
                  )}
                />
              </div>
            </ProfileSection>
          </>
        ) : (
          <span className="label1 text-tiitle">
            Rent details are not applicable
          </span>
        )}
      </div>
    );
  };

  const submitHistory = () => {
    return (
      <div className="flex flex-col">
        <div className="label1 text-title">no record</div>
          {/*<div className="flex gap-big py-md">*/}
        {/*  <div className="label2 text-title flex flex-col w-200px">*/}
        {/*    <div>2025/06/04</div>*/}
        {/*    <div>09:41:45</div>*/}
        {/*  </div>*/}
        {/*  <div className="flex flex-col">*/}
        {/*    <div className="label1 text-title">R20A withdraw successfully</div>*/}
        {/*    <ul className="text-body body2 list-disc list-inside ml-x-sm">*/}
        {/*      <li>Transaction Reference No.: 9999 9999 9999 9999</li>*/}
        {/*      <li>Withdraw by: username</li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="flex gap-big py-md">*/}
        {/*  <div className="label2 text-title flex flex-col w-200px">*/}
        {/*    <div>2025/06/04</div>*/}
        {/*    <div>09:41:45</div>*/}
        {/*  </div>*/}
        {/*  <div className="flex flex-col">*/}
        {/*    <div className="label1 text-title">R20A withdraw successfully</div>*/}
        {/*    <ul className="text-body body2 list-disc list-inside ml-x-sm">*/}
        {/*      <li>Transaction Reference No.: 9999 9999 9999 9999</li>*/}
        {/*      <li>Withdraw by: username</li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="flex gap-big py-md">*/}
        {/*  <div className="label2 text-title flex flex-col w-200px">*/}
        {/*    <div>2025/06/04</div>*/}
        {/*    <div>09:41:45</div>*/}
        {/*  </div>*/}
        {/*  <div className="flex flex-col">*/}
        {/*    <div className="label1 text-title">R20A withdraw successfully</div>*/}
        {/*    <ul className="text-body body2 list-disc list-inside ml-x-sm">*/}
        {/*      <li>Transaction Reference No.: 9999 9999 9999 9999</li>*/}
        {/*      <li>Withdraw by: username</li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    );
  };

  const submitDelete = async (event) => {
    event.preventDefault();
    await postForm(
      `property/delete/${pid}`,
      null,
      null,
      setError,
      `/${lng}/property-list/view-property/${cid ? cid : ""}`,
      false,
      () => setShowDeletePropertyPopup(false),
      token,
      null,
      setDeleteLoading,
      true,
      true,
      () => setShowDeletePropertyPopup(false)
    );
  };

  const apiProps = {
    loading: deleteLoading,
    customSubmit: submitDelete,
  };

  const handleRemove = () => {
    setShowDeletePropertyPopup(true);
  };

  const deleteBtn = () => {
    return <DeleteWithTextButton t={t} action={handleRemove} />;
  };

  const tab = [
    {
      label: "Property Details",
      field: propertyDetails(),
    },
    {
      label: "Property Categories",
      field: propertyCategoryList(),
    },
    {
      label: "Rent Details",
      field: rentDetails(),
    },
    {
      label: "Submission History",
      field: submitHistory(),
    },
  ];

  const handleSubmitImportDocument = () => {
    const selectedDocuments = Object.keys(docSearchSelection).map((key) =>
      searchData.list.find((doc) => doc.id === key)
    );
    setImportFileIds(Array.from(new Set(Object.keys(docSearchSelection))));
    updateFields({
      supporting_doc_list: {
        ...data.supporting_doc_list,
        ...selectedDocuments.reduce((acc, doc) => {
          acc[doc.id] = {
            ...doc,
            import: true,
          };
          return acc;
        }, {}),
      },
    });
    setShowImport(false);
  };

  const importApi = {
    api_name: "document/search/property-import",
    submit_action: handleSubmitImportDocument,
  };

  const handleIsDisabledImportCheckbox = () => {
    const doc_length = Object.entries(data?.supporting_doc_list).length || 0;
    const Limited_length = DOC_TYPE_OPTION.find(
      (option) => option.value === uploadFileType
    )?.upload_info?.number_of_uploading;
    if (
      doc_length >= Limited_length ||
      doc_length + Object.entries(docSearchSelection).length >= Limited_length
    ) {
      return true;
    }
    return false;
  };

  if (userLoading) return <LoadingUI />;

  const showButtons =
    enableEdit &&
    (currentTab === 0 ||
      (currentTab === 2 && data?.is_rent) ||
      (currentTab === 1 && !isShared));

  return (
    <AccountManagementTemplate
      lng={lng}
      title={`Property: ${assessment_number}`}
      button_bar={enableEdit ? deleteBtn() : null}
      noMenu={true}
    >
      {showSupportingUpload && uploadPopup()}
      {showDeletePropertyPopup && (
        <Popup
          t={t}
          lng={lng}
          setState={setShowDeletePropertyPopup}
          confirm_popup={true}
          apiProps={apiProps}
          title="Confirm to delete Property?"
          text="Are you sure you want to delete this property? Once deleted, it cannot be undone."
        />
      )}
      {showImport && (
        <ImportPopup
          setState={setShowImport}
          PopupProps={PopupProps}
          searchColumns={searchColumns}
          searchByOptions={searchOptions}
          searchList={searchList}
          t={t}
          title="Import Supporting Documents"
          apiProps={importApi}
          disableSelection={handleIsDisabledImportCheckbox()}
        />
      )}
      <div className="bg-white flex-col flex gap-xx-sm profile-field w-full rounded-big">
        {details.map((item, index) => {
          return (
            <div
              className={`flex items-center gap-big py-md justify-between md:justify-start max-w-full ${
                item.borderTop
                  ? "border-t border-neutral-100 border-dashed"
                  : ""
              }`}
              key={index}
            >
              <div className="w-xx-huge min-w-[128px] max-w-[128px] md:min-w-240px md:max-w-240px body2 text-title">
                {item.label}
              </div>
              <div className="text-body label1 break-words whitespace-normal text-end md:text-start">
                {item.data ?? "--"}
              </div>
            </div>
          );
        })}
      </div>
      <form onSubmit={handleSubmit} className="w-full">
        <TabTemplate
          setToDefaultValue={setToDefaultValue}
          lng={lng}
          tab={tab}
          changed={changed}
          backButton={
            <ArrowBackButton
              t={t}
              href={`/${lng}/property-list/view-property/${cid ? cid : ""}`}
            />
          }
          discardButton={
            showButtons && <DiscardButton t={t} action={setToDefaultValue} />
          }
          saveButton={showButtons && !loading && "Save Changes"}
          setCurrentTab={setCurrentTab}
          setError={setError}
        />
      </form>
    </AccountManagementTemplate>
  );
};

export default PropertyEdit;
