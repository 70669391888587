import React, { useState, useEffect } from "react";
import { Select, MenuItem, Checkbox, ListItemText } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import WhiteInput from "../../input/portal/WhiteInput";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../error/ErrorMessage";

const CateSelect2 = ({
  multiple = false,
  cateId,
  updateFields,
  name,
  setSelectCate,
  categories_options,
  changeCateAction,
  selectEmpty,
  error,
  setError,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    multiple ? [] : cateId
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [value, setValue] = useState(multiple ? [] : cateId);

  useEffect(() => {
    if (cateId !== undefined) {
      setSelectedOptions(cateId);
      setValue(cateId);
    }
  }, [cateId]);

  const handleChange = (event) => {
    const { value } = event.target;
    if (setSelectCate) setSelectCate(value);
    setValue(value);
    setSelectedOptions(value);
    if (setError) setError((prev) => ({ ...prev, [name]: "" }));
    if (updateFields) updateFields({ [name]: value });
    if (changeCateAction) changeCateAction(event);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const filteredOptions = categories_options?.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderValue = (selected) => {
    if (multiple) {
      return (
        <div className="text-body label1-400 text-nowrap overflow-hidden text-ellipsis">
          Selected Category: {selected?.length}
        </div>
      );
    } else {
      const selectedCategory =
        selectedOptions === "0"
          ? categories_options?.find((item) => item.id === "") ||
            categories_options?.find((item) => item.id === "0")
          : categories_options?.find((item) => item.id === selectedOptions);

      return selectedCategory ? (
        <div className="text-body label1-400 text-nowrap overflow-hidden text-ellipsis">
          {selectedCategory.name}
        </div>
      ) : (
        categories_options?.find((item) => item.id === "")?.name || (
          <div className="text=body label1-400">Select Category</div>
        )
      );
    }
  };

  const menuItemStyle = {
    "&:hover": {
      "&.Mui-selected": {
        background: "var(--surface-secondary)",
      },
      background: "var(--surface-secondary)",
    },
    "&.MuiList-root": { padding: "0" },
    "&.Mui-selected": {
      color: "var(--text-body) !important",
      background: "none",
    },
    marginTop: "var(--spacing-sm)",
    maxWidth: "100%",
    whiteSpace: "normal",
    display: "flex",
    gap: "var(--spacing-md)",
    padding: "0",
  };

  return (
    <>
      <Select
        multiple={multiple}
        IconComponent={KeyboardArrowDownIcon}
        name={name}
        className={`body1 text-body w-full`}
        value={value}
        onChange={handleChange}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        displayEmpty
        renderValue={renderValue}
        MenuProps={{
          PaperProps: {
            className: "custom-scrollbar",
            style: {
              padding: "var(--spacing-x-sm)",
              maxHeight: "322px",
              marginTop: "var(--spacing-sm)",
              overflowY: "auto",
              background: "var(--surface-card)",
              borderRadius: "var(--radius-sm)",
              boxShadow: "var(--box-shadow-lg)",
            },
          },
          MenuListProps: { style: { padding: "0" } },
        }}
        sx={{
          "&.MuiInputBase-root": { border: "0" },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid var(--surface-primary)",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--border-default)",
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--border-default)",
          },
          background: "var(--surface-card)",
          borderRadius: "var(--radius-sm)",
          height: "48px",
        }}
      >
        <WhiteInput
          placeholder="Search Category"
          data={searchTerm}
          onKeyDown={(e) => e.stopPropagation()}
          action={handleSearchChange}
          onClick={(e) => e.stopPropagation()}
        />
        {filteredOptions && filteredOptions?.length ? (
          [
            selectEmpty && (
              <MenuItem key="select-all" value="" sx={menuItemStyle}>
                <ListItemText
                  primary="Select Category"
                  sx={{ padding: "var(--spacing-sm) var(--spacing-md)" }}
                />
              </MenuItem>
            ),
            ...filteredOptions.map((item) => (
              <MenuItem key={item.id} value={item.id} sx={menuItemStyle}>
                {multiple && (
                  <Checkbox
                    checked={selectedOptions.indexOf(item.id) > -1}
                    sx={{
                      "&.Mui-checked": {
                        color: "var(--surface-primary) !important",
                      },
                      color: "var(--Neutral-300)",
                      borderRadius: "var(--radius-x-sm)",
                    }}
                  />
                )}
                <ListItemText
                  primary={item.name}
                  sx={{
                    padding: "var(--spacing-sm) var(--spacing-md)",
                    color:
                      item.id === selectedOptions
                        ? "var(--surface-primary)"
                        : "",
                  }}
                />
              </MenuItem>
            )),
          ]
        ) : (
          <div className="p-md">{t("No category found")}</div>
        )}
      </Select>
      <ErrorMessage error={error} />
    </>
  );
};

export default CateSelect2;
