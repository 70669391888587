import TextHeadline from "../../components/TextHeadline";

const SubmissionTemplate = ({ children, Headline,title, subtitle, currentStepIndex, steps, subtitleWidth }) => {
    return (
            <>
                <div className="bg-white">
                    <div className="poposal-header head-foot-container layout-container flex justify-center" style={{ paddingTop: 'var(--spacing-lg)', paddingBottom: 'var(--spacing-lg)'}}>
                        <div className="text-center" style={{ maxWidth: '854px' }}>
                            <TextHeadline title={Headline} />
                        </div>
                    </div>
                </div>
                <div className='layout-container content-container'>
                    <div className="flex flex-col gap-x-sm items-center text-justify mb-xx-lg" style={{ textAlignLast: "center" }}>
                        {((currentStepIndex !== '' || currentStepIndex !== null) && steps) &&
                            <span className="label1 text-label-icon-on-light">{currentStepIndex + 1} of {steps.length} Steps</span>
                        }
                        <h2 className="h2 text-800">{title}</h2>
                        <div className='body1 text-600' style={{ width: subtitleWidth ? subtitleWidth : 'auto' }}>{subtitle}</div>
                    </div>
                    { children }
                </div>
            </>

    );
};

export default SubmissionTemplate;
