import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import changeStepForms from "../../components/step/ChangeStepForms";
import StepOne from "../../components/submitted-documents-form/StepOne";
import StepTwo from "../../components/submitted-documents-form/StepTwo";
import StepThree from "../../components/submitted-documents-form/StepThree";
import StepFormTemplate from "../../components/form/StepFormTemplate";
import Breadcrumb from "../../components/header/Breadcrumb";
import SubmissionTemplate from "../../components/proposal-submission-form/Template";
import LoadingUI from "../../components/loading/LoadingUI";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import useDocumentTitle from "../../hooks/useDocument";

const SubmitDocument = ({ lng }) => {
  const { userLoading } = useUserLoginContext();
  const { t } = useTranslation();
  const [data, setData] = useState({ proposal_submission_channel: 1 });
  const [error, setError] = useState({});
  const api_name = "submit-documents";
  useDocumentTitle("Supplementary Document");
  const updateFields = (fields) => setData((prev) => ({ ...prev, ...fields }));
  const props = {};

  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    changeStepForms([
      <StepOne
        key="1"
        data={data}
        error={error}
        setError={setError}
        updateFields={updateFields}
        t={t}
      />,
      <StepTwo
        key="2"
        props={props}
        data={data}
        setData={setData}
        error={error}
        setError={setError}
        updateFields={updateFields}
        t={t}
        lng={lng}
      />,
      <StepThree key="2" t={t} lng={lng} />,
    ]);

  if (userLoading) return <LoadingUI />;

  return (
    <>
      <Breadcrumb
        lng={lng}
        currentElement="Supplementary Document Submission"
      />
      <SubmissionTemplate
        subtitle={
          isFirstStep
            ? "After submitting your proposal, you can enhance your submission by providing supplementary documents to RVD that support your proposal. These supplementary documents serve as valuable resources, allowing you to provide additional context or detailed information related to your proposal."
            : !isLastStep
            ? "These supplementary documents serve as valuable resources, allowing you to provide additional context or detailed information related to your proposal."
            : ""
        }
        title="Supplementary Documents Submission"
        Headline="Submit Supplementary Documents for Proposal for Alteration of Valuation List or Government Rent Roll (FORM R20A)"
        subtitleWidth="900px"
      >
        <div className="flex flex-col gap-xxx-big max-w-900px mx-auto">
          <div className="bg-50">
            <StepFormTemplate
              formData={data}
              setError={setError}
              step={step}
              isLastStep={isLastStep}
              back={back}
              next={next}
              currentStepIndex={currentStepIndex}
              isFirstStep={isFirstStep}
              api_name={api_name}
              back_path={`/submitted-documents`}
              type={api_name}
              lng={lng}
              redirect_path={`/submitted-documents`}
              updateFields={updateFields}
            />
          </div>
        </div>
      </SubmissionTemplate>
    </>
  );
};
export default SubmitDocument;
