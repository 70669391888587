import DataTable from "../../components/table/DataTable";
import PropertyTemplate from "../../components/property/Template";
import { useTranslation } from "react-i18next";
import {
  UploadButton,
  AdvanceButton,
  ArrowOutwardButton,
} from "../../components/button/SingleButton";
import WhiteSelect from "../../components/input/portal/WhiteSelect";
import WhiteDatePicker from "../../components/input/portal/WhiteDatePicker";
import React, { useState, useEffect, useMemo } from "react";
import CustomSearchBar from "../../components/search/CustomSearchBar";
import searchByOptions from "../../data/file_searchbar_option.json";
import BasicTag from "../../components/tags/BasicTag";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import LoadingUI from "../../components/loading/LoadingUI";
import Breadcrumb from "../../components/header/Breadcrumb";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import SmallFunction from "../../components/function/SmallFunction";
import useDocumentTitle from "../../hooks/useDocument";

const SubmittedDocuments = ({ lng }) => {
  const { userLoading, token } = useUserLoginContext();
  const { changeDate } = SmallFunction();
  const [showAdvance, setShowAdvance] = useState(false);
  const [searchBy, setSearchBy] = useState(1);
  const [error, setError] = useState({});
  const [searchData, setSearchData] = useState("");
  const [tableLoading, setTableLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [data, setData] = useState({});
  const [filtersData, setFiltersData] = useState({});
  const [filters, setFilters] = useState([]);
  const { t } = useTranslation();
  const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
  const { postForm } = SubmitAndFetch({ lng, t });
  useDocumentTitle("Submitted Documents")

  useEffect(() => {
    const fetchFiltersData = async () => {
      await postForm(
        "document/submitted/filter",
        null,
        null,
        setError,
        null,
        false,
        null,
        token,
        setFilters,
        null,
        false
      );
    };
    if (token) {
      fetchFiltersData();
    }
  }, [token]);

  const submitDocumentButton = () => {
    return (
      <UploadButton
        t={t}
        label="Supplementary Document"
        outline={true}
        href="submit-document"
      />
    );
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(filtersData);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [filtersData]);

  useEffect(() => {
    if (debouncedQuery) {
      if (setIsFetching) setIsFetching(true);
    }
  }, [debouncedQuery]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "fileName",
        header: "Document Name",
        size: 240,
        grow: false,
        enableSorting: false,
      },
      {
        accessorKey: "source",
        header: "Source",
        size: 260,
        grow: false,
        enableSorting: false,
      },
      {
        accessorKey: "documentType",
        header: "Document Type",
        size: 260,
        grow: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          if (cell.getValue()) {
            return (
              <span className="label2">
                <span className="body2">
                  <BasicTag
                    key={row.original.id ?? ""}
                    label={row.original.documentType?.[`label${lang}`] ?? ""}
                    color={row.original.documentType?.color ?? ""}
                  />
                </span>
              </span>
            );
          }
        },
      },
      {
        accessorKey: "fileFormat",
        header: "Format",
        size: 140,
        grow: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          if (cell.getValue()) {
            return (
              <span className="label2">
                <span className="body2">
                  <BasicTag
                    key={row.original.id ?? ""}
                    label={row.original.fileFormat?.[`label${lang}`] ?? ""}
                    color={row.original.fileFormat?.color ?? ""}
                  />
                </span>
              </span>
            );
          }
        },
      },
      {
        accessorKey: "fileSize",
        header: "Size",
        size: 130,
        grow: false,
        enableSorting: false,
      },
      {
        accessorKey: "period",
        header: "Retention Period",
        size: 180,
        grow: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          if (cell.getValue()) {
            return cell.getValue();
          } else {
            return "-";
          }
        },
      },
      {
        accessorKey: "uploadTime",
        objKey: "uploadTime",
        header: "Submitted Date",
        size: 200,
        grow: false,
        Cell: ({ cell, row }) => {
          if (cell.getValue()) {
            return changeDate(null, null, cell.getValue());
          } else {
            return "-";
          }
        },
      },
      {
        accessorKey: "button",
        header: "",
        size: 140,
        enableSorting: false,
        grow: true,
        Cell: ({ cell, row }) => (
          <div className="flex justify-center w-full">
            <ArrowOutwardButton
              t={t}
              href={`/${lng}/appointment-management/appointment-details/${row.original.id}`}
            />
          </div>
        ),
      },
    ],
    [data]
  );

  const tableDataProps = {
    columns,
    border: false,
    customLoading: tableLoading,
    setCustomLoading: setTableLoading,
    apiProps: {
      setError,
      isFetching,
      setIsFetching,
      setState: setData,
      data: data,
      api: "document/submitted",
      appandData: {
        keyword: searchData,
        search_type: searchBy,
        ...filtersData,
      },
      DefaultSorting: [{ id: "uploadTime", desc: true }],
    },
    pageSize: 10,
    pageSizeOptions: [10, 20, 50],
  };

  const inputProps = (name, placeholder) => ({
    name,
    placeholder,
    action: (data) => {
      setTableLoading(true);
      setFiltersData((prevState) => ({ ...prevState, [name]: data }));
    },
    t,
  });

  if (userLoading) return <LoadingUI />;

  return (
    <>
      <Breadcrumb lng={lng} />
      <PropertyTemplate
        lng={lng}
        title="Submitted Documents"
        button_bar={submitDocumentButton}
      >
        <form className="flex-col flex gap-x-big">
          <div className="flex gap-md w-full">
            <div className="flex-1">
              <WhiteSelect
                {...inputProps("formats", "Format")}
                options={filters?.fileFormatList ?? []}
              />
            </div>
            <div className="flex-1">
              <WhiteSelect
                {...inputProps("document_types", "Document Type")}
                options={filters?.documentTypeList ?? []}
              />
            </div>
            <div className="flex-1">
              <WhiteDatePicker
                {...inputProps("upload_date")}
                format="YYYY-MM-DD"
                data=""
              />
            </div>
            <div className="md:w-fit">
              <AdvanceButton t={t} setShowAdvance={setShowAdvance} />
            </div>
          </div>
          <div className="flex flex-col gap-sm mb-xxx-big">
            <CustomSearchBar
              searchButton={true}
              setSearchBy={setSearchBy}
              searchBy={searchBy}
              t={t}
              options={searchByOptions}
              title={searchByOptions}
              showAdvance={showAdvance}
              searchbar={true}
              customSearchAction={(search_data) => {
                setSearchData(search_data);
                setIsFetching(true);
              }}
            />
            <DataTable {...tableDataProps} />
          </div>
        </form>
      </PropertyTemplate>
    </>
  );
};

export default SubmittedDocuments;
