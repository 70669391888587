import React, { useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const selectStyles = {
  padding: "var(--spacing-x-sm) var(--spacing-x-md)",
  background: "var(--Neutral-white)",
  width: 81,
  height: 40,
  border: "1px solid var(--Neutral-200)",
  borderRadius: "6px",
  boxShadow: "none",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&& .Mui-selected": {
    backgroundColor: "var(--surface-secondary)",
    color: "var(--surface-primary)",
  },
};

const Pagination = ({
  setPaginationModel,
  paginationModel,
  totalPages,
  totalRows,
  pageSizeOptions,
  setIsRefetching,
  isServerSide,
}) => {
  const handlePageChange = (event) => {
    if (isServerSide && setIsRefetching) setIsRefetching(true);
    const page = Math.max(
      0,
      Math.min(Number(event.target.value) - 1, totalPages - 1)
    );
    setPaginationModel((prevModel) => ({ ...prevModel, pageIndex: page }));
    setInputPage(page + 1);
  };

  const handlePageSizeChange = (event) => {
    if (isServerSide && setIsRefetching) setIsRefetching(true);
    setPaginationModel((prevModel) => ({
      ...prevModel,
      pageSize: event.target.value,
    }));
  };

  const from = paginationModel.pageSize
    ? paginationModel.pageIndex * paginationModel.pageSize + 1
    : 0;
  const to = totalRows
    ? Math.min(
        totalRows,
        (paginationModel.pageIndex + 1) * paginationModel.pageSize
      )
    : 1;

  const PageSizeSelector = () =>
    pageSizeOptions && (
      <FormControl>
        <Select
          sx={selectStyles}
          value={paginationModel.pageSize}
          onChange={handlePageSizeChange}
        >
          {pageSizeOptions.map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );

  const PageNavigation = () => (
    <div className="flex items-center gap-sm">
      <button
        className="bg-white p-xx-sm md:p-10px"
        onClick={() =>
          handlePageChange({ target: { value: paginationModel.pageIndex } })
        }
        disabled={paginationModel.pageIndex === 0}
      >
        <KeyboardArrowLeftIcon width={24} />
      </button>
      <button
        className="bg-white p-xx-sm md:p-10px"
        onClick={() =>
          handlePageChange({ target: { value: paginationModel.pageIndex + 2 } })
        }
        disabled={!totalPages || paginationModel.pageIndex === totalPages - 1}
      >
        <KeyboardArrowRightIcon width={24} />
      </button>
    </div>
  );

  const [inputPage, setInputPage] = useState(paginationModel.pageIndex + 1);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputPage(newValue);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      applyPageChange(isServerSide ? inputPage : Number(inputPage) + 1);
    }
  };

  const applyPageChange = (value) => {
    const pageNumber = parseInt(value, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      handlePageChange({ target: { value: pageNumber } });
    } else {
      setInputPage(
        paginationModel.pageIndex === 0
          ? paginationModel.pageIndex + 1
          : paginationModel.pageIndex
      );
    }
  };

  const handleStepperChange = (step) => {
    const newPage = inputPage + step;
    if (newPage >= 1 && newPage <= totalPages) {
      applyPageChange(newPage);
      setInputPage(newPage);
    }
  };

  return (
    <div className="custom-footer flex justify-between mt-x-big bg-transparent flex-col md:flex-row">
      <div className="flex items-center gap-md">
        <PageSizeSelector />
        <div className="text-body label2">
          Show {from} - {to} of {totalRows || 1}
        </div>
      </div>
      <div className="flex items-center gap-sm mt-sm md:mt-0">
        <PageNavigation />
        <div className="px-x-sm md:px-big border-e border-300 h-lg flex items-center">
          <span className="text-body label2">
            {paginationModel.pageIndex + 1} of {totalPages || 1}
          </span>
        </div>
        <div className="flex gap-sm items-center">
          <span className="md:pl-md text-body label2">Go to page:</span>
          <div className="relative">
            <input
              className="default-number-input focus:outline-none w-[81px] pr-8 py-2 border border-gray-300 rounded-sm text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              type="number"
              value={inputPage}
              onChange={handleInputChange}
              onKeyPress={handleInputKeyPress}
              min="1"
              max={totalPages || 1}
            />
            <div className="absolute right-0 top-0 bottom-0 flex flex-col go-to-page-btn">
              <button
                type="button"
                className="flex-1 px-2 text-gray-600 hover:bg-gray-100 focus:outline-none go-to-page-btn-up"
                onClick={() => handleStepperChange(1)}
                disabled={paginationModel.pageIndex + 1 >= totalPages}
              >
                <svg
                  className="w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <button
                type="button"
                className="flex-1 px-2 text-gray-600 hover:bg-gray-100 focus:outline-none go-to-page-btn-down"
                onClick={() => handleStepperChange(-1)}
                disabled={paginationModel.pageIndex + 1 <= 1}
              >
                <svg
                  className="w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
