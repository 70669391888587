import { ReactComponent as CelebrationSVG } from "../../assets/images/others/celebration.svg";
import FieldCard from "../form/FieldCard";
import {PrimaryButton} from "../button/SingleButton";

const StepOne = ({ t, lng }) => {
    return (
        <>
            <FieldCard t={t}>
                <div className="flex flex-col justify-center items-center text-center">
                    <div
                        className="bg-white rounded-big flex flex-col justify-center items-center">
                        <div className="bg-label-icon-positive w-[56px] h-[56px] rounded-full mb-sm">
                            <div className="w-full h-full  flex justify-center items-center">
                                <CelebrationSVG style={{ width: '32px', height: '32px'}}/>
                            </div>
                        </div>
                        <h3 className="text-label-icon-positive h3 mb-x-big">
                            {t('Supplementary Documents Submitted')}
                        </h3>
                        <p className="body1 text-body">{t('Thank you for providing supplement document for the proposal. We have received your information and it is now being processed.')}</p>
                    </div>

                </div>
            </FieldCard>
            <div className='mt-xx-lg'>
                <PrimaryButton t={t} href={`/${lng}/submitted-documents`} label={'Done'} className='mx-auto'/>
            </div>
        </>
    )
}

export default StepOne;