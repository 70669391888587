import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
    SortableContext,
    rectSortingStrategy,
    arrayMove,
    useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import QuickLinkCard from "../../components/home/QuickLinkCard";
import { OutlineBackButton, PrimaryButton } from "../../components/button/SingleButton";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import LoadingUI from "../../components/loading/LoadingUI";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import {LoadingImageSkeleton} from "../../components/loading/LoadingSkeleton";
import useDocumentTitle from "../../hooks/useDocument";

const QuickLink = ({ lng }) => {
    const { userLoading, token } = useUserLoginContext();
    const { t } = useTranslation();
    const [quickLinkList, setQuickLinkList] = useState([]);
    const [currentQuickLinks, setCurrentQuickLinks] = useState([]);
    const [linkList, setLinkList] = useState([]);
    const [quickLinkListLoading, setQuickLinkListLoading] = useState(true);
    const [error, setError] = useState({});
    const current_quick_link = useRef(null);
    const link_list = useRef(null);
    const { fetchData, postForm, loading } = SubmitAndFetch({ lng, t });
    const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
    useDocumentTitle("Edit Quick Link");

    useEffect(() => {
        const getData = async () => {
            await fetchData('quicklink/edit/list', null, setQuickLinkList, setQuickLinkListLoading, lng, token);
        }
        getData();
    }, [token]);

    useEffect(() => {
        if (quickLinkList && !quickLinkListLoading) {
            setCurrentQuickLinks(quickLinkList.currentList)
            setLinkList(quickLinkList.linkList)
        }
    }, [quickLinkList, quickLinkListLoading]);

    const handleSave = async () => {
        const formData = new FormData();
        if (currentQuickLinks) {
            const ids = currentQuickLinks?.map(item => item.id);
            formData.append('ids', ids ? ids : []);
        }
        await postForm('quicklink', 'edit', formData, setError, null, null, null, token);
    };

    const handleRemove = (id) => {
        setCurrentQuickLinks((prev) => prev.filter(link => link.id !== id));
        setLinkList((prev) => [...prev, currentQuickLinks.find(link => link.id === id)]);
    };

    const handleAdd = (id) => {
        setLinkList((prev) => prev.filter(link => link.id !== id));
        setCurrentQuickLinks((prev) => [...prev, linkList.find(link => link.id === id)]);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setCurrentQuickLinks((items) => {
                const oldIndex = items.findIndex(item => item.id === active.id);
                const newIndex = items.findIndex(item => item.id === over.id);
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };

    const CurrentQuickLinkCard = ({ id, title, image }) => {
        const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: id });
        const style = {
            transform: CSS.Translate.toString(transform),
            transition,
            cursor: 'default',
            width: '100%',
        };
        return (
            <div ref={setNodeRef} style={style} {...attributes} className={`quick-link-card current-quick-link ${isDragging ? 'is-dragging' : ''}`}>
                <QuickLinkCard id={id} title={title} class_name='flex flex-col relative' image={image}>
                    <div className="absolute w-full top-0 my-md px-sm md:px-big justify-between z-10 hidden current-quick-link-icon">
                        <span {...listeners} className="h-[24px] w-[24px] md:h-42px md:w-42px flex justify-center items-center"><DragIndicatorIcon className="drag-icon hover:cursor-grab" /></span>
                        <span className="h-[24px] w-[24px] md:h-42px md:w-42px rounded-full border border-highlight flex justify-center items-center bg-surface-card hover:cursor-pointer hover:bg-surface-hover"><CloseIcon style={{ color: 'var(--surface-primary)' }} className="remove-icon" onClick={() => handleRemove(id)} /></span>
                    </div>
                </QuickLinkCard>
            </div>
        );
    };

    const LinkListCard = ({ id, title, image }) => {
        return (
            <div className="quick-link-card">
                <QuickLinkCard id={id} title={title} class_name='relative flex flex-col' image={image}>
                    <div className='absolute w-full z-10 top-0 flex justify-end my-md px-sm md:px-big'>
                        <span className="rounded-full bg-surface-primary w-[24px] h-[24px] md:w-42px md:h-42px flex justify-center items-center hover:cursor-pointer hover:bg-surface-primary-hover"><AddIcon className="add-quicklink-icon absoulte top-0 right-0" onClick={() => handleAdd(id)} style={{ color: 'var(--Neutral-white)' }} /></span>
                    </div>
                </QuickLinkCard>
            </div>
        );
    };

    if (userLoading) return <LoadingUI />

    return (
        <div className="layout-container content-container flex flex-col gap-xxx-big">
            <div className="home-card-tool">
                <h2 className="h2 text-headline">{t('Edit Quick Link')}</h2>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-xxx-big">
                <form className="col-span-1">
                    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                        <SortableContext items={currentQuickLinks ?? []} strategy={rectSortingStrategy}>
                            <div className="flex flex-col gap-big p-xx-sm md:bg-surface-card md:p-big rounded-big md:rounded-lg" ref={current_quick_link}>
                                <h3 className="h3 text-title">{t('Current Quick Link')}</h3>
                                <div className="grid grid-cols-2 lg:grid-cols-12 gap-x-sm md:gap-big">
                                    {quickLinkListLoading &&
                                        Array.from({ length: 8 }, (_, i) => (
                                            <div key={i} className='col-span-1 md:col-span-6'>
                                                <LoadingImageSkeleton h={'170px'} w={'100%'} containerClassName={'image-container'}/>
                                            </div>
                                        ))
                                    }
                                    {currentQuickLinks && currentQuickLinks?.map((item) => (
                                        <CurrentQuickLinkCard key={item.id} id={item.id} title={item?.[`title${lang}`]} image={item.iconPath} />
                                    ))}
                                </div>
                            </div>
                        </SortableContext>
                    </DndContext>
                </form>
                <div className="flex flex-col gap-big p-xx-sm md:bg-surface-card md:p-big rounded-big md:rounded-lg col-span-1" ref={link_list}>
                    <h3 className="h3 text-title">{t('Link List')}</h3>
                    <div className="grid grid-cols-2 lg:grid-cols-12 gap-x-sm md:gap-big">
                        {quickLinkListLoading &&
                            Array.from({ length: 8 }, (_, i) => (
                                <div key={i} className='col-span-1 md:col-span-6'>
                                    <LoadingImageSkeleton h={'170px'} w={'100%'} containerClassName={'image-container'}/>
                                </div>
                            ))
                        }
                        {linkList && linkList?.map((item) => (
                            <LinkListCard key={item.id} id={item.id} title={item?.[`title${lang}`]} image={item.iconPath} />
                        ))}
                    </div>
                </div>
            </div>
            <div className='flex justify-between'>
                <OutlineBackButton t={t} label='Cancel' href={`/${lng}`} />
                <PrimaryButton t={t} label='Save' type='submit' action={handleSave} disabled={loading}/>
            </div>
        </div>
    );
};

export default QuickLink;
