import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Pagination from "../table/Pagination";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { ReactComponent as SortingSVG } from "../../assets/images/arrow/sorting.svg";
import { tableStyles } from "./tableStyles";
import SubmitAndFetch from "../function/SubmitAndFetch";

const DataTable = ({
  columns,
  data,
  pageSize = 10,
  pageIndex = 0,
  pageSizeOptions = [10, 20],
  enablePagination = true,
  needMargin = false,
  enableSelection = false,
  multi = true,
  selectAll = false,
  setRowSelection,
  disableSelection,
  rowSelection,
  emptyMessage,
  customLoading,
  setCustomLoading,
  showFooter = true,
  border = true,
  apiProps,
  enableSelectionCheckPermission = false,
  enablePageSizeOptions = true,
  isServerSide = true,
}) => {
  const [cookies] = useCookies(["access_token"]);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const token = cookies["access_token"];
  const { postForm, loading } = SubmitAndFetch({ lng: language, t });
  const [sorting, setSorting] = useState(apiProps?.DefaultSorting || []);
  const [newData, setNewData] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageIndex,
    pageSize,
  });

  const tableData = useMemo(
    () =>
      data && Object.entries(data).length ? data : apiProps?.data?.list || [],
    [data, apiProps?.data?.list]
  );

  const totalRows = isServerSide ? apiProps?.data?.total : tableData.length;
  const totalPages = isServerSide
    ? apiProps?.data?.pages
    : Math.ceil(tableData.length / paginationModel.pageSize);

  const useDataFetching = (
    apiProps,
    sorting,
    paginationModel,
    columns,
    token,
    isServerSide
  ) => {
    const fetchData = useCallback(async () => {
      if (!isServerSide) return;
      if (setCustomLoading) {
        setCustomLoading(true);
      }

      if (!apiProps?.api) return;

      const formData = new FormData();
      if (apiProps?.appandData) {
        Object.entries(apiProps?.appandData).forEach(([key, value]) => {
          formData.append(key, value);
        });
      }
      if (sorting.length) {
        sorting.forEach((item) => {
          const column = columns.find((col) => col.accessorKey === item.id);
          formData.append(
            `sort_by[${column.objKey}]`,
            item.desc ? "desc" : "asc"
          );
        });
      }
      formData.append("page_size", paginationModel.pageSize);
      formData.append("page_num", paginationModel.pageIndex + 1);

      await postForm(
        apiProps?.api,
        null,
        formData,
        apiProps?.setError ? apiProps?.setError : null,
        null,
        null,
        null,
        token,
        apiProps?.setState,
        setCustomLoading,
        false,
        false,
        () => apiProps?.setState({})
      );
      if (apiProps?.setIsFetching) apiProps?.setIsFetching(false);
      if (setCustomLoading) {
        setCustomLoading(false);
      }
    }, [
      apiProps,
      sorting,
      paginationModel,
      columns,
      token,
      postForm,
      apiProps?.isFetching,
      isServerSide,
    ]);

    return { fetchData };
  };

  const getTableOptions = useCallback(
    (props) => {
      return {
        columns: props.columns,
        enableMultiSort: true,
        isMultiSortEvent: () => true,
        data: props.tableData,
        onSortingChange: (sorting) => {
          props?.setSorting(sorting);
          if (props.isServerSide) {
            apiProps?.setIsFetching(true);
          }
        },
        icons: {
          SortIcon: (props) => <SortingSVG {...props} />,
        },
        getSubRows: (row) => row.children,
        muiPaginationProps: {
          showRowsPerPage: false,
          showFirstButton: false,
          showLastButton: false,
          labelDisplayedRows: ({ from, to, count }) =>
            `Showing ${from}-${to} of ${count}`,
        },
        paginationDisplayMode: "custom",
        initialState: {
          columnVisibility: {
            "mrt-row-expand": false,
            ...(props.apiProps?.columnVisibility || {}),
          },
          expanded: props.tableData?.reduce((acc, row) => {
            if (row?.rowType === "parent") {
              acc[row.id] = true;
            }
            return acc;
          }, {}),
        },
        displayColumnDefOptions: {
          "mrt-row-select": {
            header: "",
          },
        },
        ...tableStyles,
        enableTopToolbar: false,
        enableColumnActions: false,
        enablePagination: props.enablePagination,
        enableExpanding: true,
        enableExpandAll: false,
        layoutMode: "grid",
        enableHiding: true,
        enableMultiRowSelection: props.multi,
        enableRowSelection: props.enableSelection
          ? (row) => {
              const baseCondition = props.enableSelectionCheckPermission
                ? props.selectionCheckPermission(row)
                : row.original.rowType !== "parent" && props.enableSelection;

              return baseCondition;
            }
          : false,
        muiSelectCheckboxProps: ({ row }) => {
          const isSelected = row.getIsSelected();
          return {
            disabled:
              (!isSelected && props.disableSelection) ||
              (props.enableSelectionCheckPermission &&
                !props.selectionCheckPermission(row)),
            sx: {
              color: "var(--border-default)",
              "&.Mui-checked": {
                color: "var(--surface-primary)",
              },
              "&.Mui-disabled": {
                display: "none",
              },
              padding: "0",
            },
          };
        },
        enableSelectAll: props.selectAll,
        enableSubRowSelection: false,
        enableColumnPinning: true,
        onRowSelectionChange: props.enableSelection
          ? props.setRowSelection
          : undefined,
        getRowId: props.enableSelection ? (row) => row.id : undefined,
        state: {
          isLoading: props.customLoading || props.loading || false,
          rowSelection: props.enableSelection ? props.rowSelection : {},
          sorting: props.sorting,
          pagination: paginationModel,
        },
        enableSorting: true,
        renderEmptyRowsFallback: () => (
          <div className="label1 text-title p-md">
            {props.emptyMessage || "No records"}
          </div>
        ),
        renderBottomToolbar: () =>
          props.showFooter && props.customBottomToolBar(),
        manualPagination: props.isServerSide,
        manualSorting: props.isServerSide,
        enableGlobalFilter: !props.isServerSide,
      };
    },
    [tableData, paginationModel]
  );

  const { fetchData } = useDataFetching(
    apiProps,
    sorting,
    paginationModel,
    columns,
    token,
    isServerSide
  );

  useEffect(() => {
    if (apiProps?.isFetching) fetchData();
  }, [apiProps?.isFetching]);

  const customBottomToolBar = useCallback(
    () => (
      <Pagination
        paginationModel={paginationModel}
        enablePageSizeOptions={enablePageSizeOptions}
        totalRows={totalRows}
        pageSizeOptions={enablePageSizeOptions ? pageSizeOptions : null}
        setPaginationModel={setPaginationModel}
        totalPages={totalPages}
        isServerSide={isServerSide}
        setIsRefetching={apiProps?.setIsFetching}
      />
    ),
    [
      enablePageSizeOptions,
      paginationModel,
      totalRows,
      pageSizeOptions,
      totalPages,
      isServerSide,
      setPaginationModel,
      apiProps,
    ]
  );

  const selectionCheckPermission = useCallback(
    (row) => {
      if (row?.original?.isShared) return false;
      if (row?.original?.categoryIds && row?.original?.permissions) {
        const id = apiProps?.id;
        const cateArray = row.original.categoryIds.split(",");
        const permissionArray = row.original.permissions.split(",");
        return !permissionArray.some(
          (item, index) =>
            item !== "1" &&
            item !== "4" &&
            item !== "0" &&
            id === cateArray[index]
        );
      }
      return false;
    },
    [apiProps?.id]
  );

  const table = useMaterialReactTable(
    getTableOptions({
      columns,
      tableData,
      loading,
      sorting,
      setSorting,
      enablePagination,
      needMargin,
      enableSelection,
      disableSelection,
      multi,
      selectAll,
      setRowSelection,
      rowSelection,
      setCustomLoading,
      customLoading,
      emptyMessage,
      showFooter,
      border,
      apiProps,
      enableSelectionCheckPermission,
      selectionCheckPermission,
      customBottomToolBar,
      isServerSide,
    })
  );

  return (
    <MaterialReactTable
      table={table}
      getRowCanExpand={(row) => row.original.rowType !== "parent"}
      onPaginationChange={setPaginationModel}
    />
  );
};

export default DataTable;
