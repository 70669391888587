import { ReactComponent as ErrorSVG } from "../../assets/images/upload/error.svg";
const ErrorMessage = ({
  error,
  message,
  help_text,
  optional,
  t,
  errorClass,
  downloadError,
}) => {
  return error || message || help_text || optional || downloadError ? (
    <div
      className={`caption1 ${
        !downloadError && !message ? "pt-xx-sm px-md" : ""
      } ${error || message || downloadError ? "text-warning" : "text-caption"}`}
    >
      {message || downloadError ? (
        <div
          className="errorMsg-icon flex gap-x-sm lg:items-center"
          style={{ lineHeight: "normal" }}
        >
          <ErrorSVG width={20} height={20} />
          {downloadError ? downloadError : message}
        </div>
      ) : error ? (
        <div className={errorClass ? errorClass : ""}>{error}</div>
      ) : help_text && optional ? (
        <span className="caption1">
          {t(help_text)} ({t("Optional field")})
        </span>
      ) : help_text ? (
        <span className="caption1">{t(help_text)}</span>
      ) : optional ? (
        <span className="caption1">{t("Optional field")}</span>
      ) : null}
    </div>
  ) : null;
};

export default ErrorMessage;
