export default function LoadingUI() {
    const styles = {
        loading: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60px',
            height: '60px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: '9999',
            pointerEvents: 'none',
            '--_g': 'no-repeat radial-gradient(circle closest-side, var(--blue-600) 90%, transparent)',
            background: `var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%`,
            backgroundSize: 'calc(100% / 3) 50%',
            animation: 'l3 1s infinite linear',
        },
    };

    return (
        <div className="flex justify-center items-center fixed top-0 left-0 w-screen h-screen bg-white" style={{ zIndex: '80'}}>
            <div className="loading" style={styles.loading}>
                <style>
                    {`
            @keyframes l3 {
              20% { background-position: 0% 0%, 50% 50%, 100% 50% }
              40% { background-position: 0% 100%, 50% 0%, 100% 50% }
              60% { background-position: 0% 50%, 50% 100%, 100% 0% }
              80% { background-position: 0% 50%, 50% 50%, 100% 100% }
            }
          `}
                </style>
            </div>
        </div>
    );
}