import { OutlineButton, PrimaryButton } from "./SingleButton";
const PopupButton = ({ t }) => {
  const popup_button = (
    backLabel = "Cancel",
    back_action,
    forwardLabel = "Save",
    loading,
    disabled,
    reupload,
    onClickInnerFormSubmit
  ) => {
    return (
      <div className="flex justify-between items-center mt-xxx-big">
        <OutlineButton
          t={t}
          label={backLabel ? backLabel : "Cancel"}
          action={reupload ? (event) => reupload(event) : back_action}
        />
        <PrimaryButton
          t={t}
          label={loading ? "Loading" : forwardLabel ? forwardLabel : "Save"}
          loading={loading}
          disabled={disabled}
          type={onClickInnerFormSubmit ? "button" : "submit"}
          action={onClickInnerFormSubmit}
        />
      </div>
    );
  };
  const import_button = (
    backLabel = "Cancel",
    back_action,
    submit_action,
    forwardLabel = "Confirm",
    loading,
    disabled
  ) => {
    return (
      <div className="flex justify-between items-center mt-xxx-big">
        <OutlineButton
          t={t}
          label={backLabel ? backLabel : "Cancel"}
          action={back_action}
        />
        <PrimaryButton
          t={t}
          label={loading ? "Loading" : forwardLabel ? forwardLabel : "Save"}
          loading={loading}
          disabled={disabled}
          action={submit_action}
        />
      </div>
    );
  };

  return {
    popup_button,
    import_button,
  };
};

export default PopupButton;
