import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { AddDataToHTML } from "../assets/js/global";
import SmallFunction from "../../src/components/function/SmallFunction";
import { useTranslation } from "react-i18next";
import USER_TYPE from "../data/user_type.json";

const UserLoginContext = createContext({
  profileData: {},
  userLoading: true,
  token: "",
  icon: "",
  account_type: null,
  notification: [],
  haveNoRead: false,
  admin: false,
  setProfileData: () => {},
});

const UserLoginProvider = ({ lng, children }) => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["access_token", "i18next"]);
  const [profileData, setProfileData] = useState(userData);
  const [notification, setNotification] = useState([]);
  const [haveNoRead, setHaveNoRead] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const [admin, setAdmin] = useState(false);
  const [icon, setIcon] = useState("");
  const [account_type, setAccountType] = useState(null);
  const token = cookies["access_token"];
  const { getIconValue, sweetFailAlert } = SmallFunction();
  let getLang = false;

  useEffect(() => {
    if (notification) {
      const hasUnread = notification.some((option) => !option.isRead);
      setHaveNoRead(hasUnread);
    }
  }, [notification]);

  const setHeaders = (lng, token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept-Language", lng);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    return myHeaders;
  };

  const fetchNotifications = async (
    myHeaders,
    setNotification,
    navigate,
    lng
  ) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_PUBLIC_API_URL}/v1/notification/badge`,
        {
          method: "POST",
          headers: myHeaders,
        }
      );
      const resData = await res.json();

      if (res.ok && resData.code === 200) {
        setNotification(resData.data.list);
      } else {
        if (
          resData.message === "Invalid or expired token" ||
          resData.message === "Invalid"
        ) {
          navigate(`/${lng}/login`);
        }
      }
    } catch (error) {
      sweetFailAlert(error, t)
    }
  };

  const fetchProfileData = async (
    myHeaders,
    setProfileData,
    setUserLoading,
    navigate,
    lng,
    saveUserDate
  ) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_PUBLIC_API_URL}/v1/profile`,
        {
          method: "POST",
          headers: myHeaders,
        }
      );
      const resData = await res.json();

      if (res.ok && resData.code === 200) {
        setProfileData(resData.data.profile);
        setUserLoading(false);
      } else {
        if (
          resData.message === "Invalid or expired token" ||
          resData.message === "Invalid"
        ) {
          navigate(`/${lng}/login`);
        }
      }
      saveUserDate(resData.data.profile);
    } catch (error) {
      sweetFailAlert(error, t)
    }
  };

  useEffect(() => {
    setUserLoading(true);
    const myHeaders = setHeaders(lng, token);

    fetchNotifications(myHeaders, setNotification, navigate, lng);

    if (!userData) {
      fetchProfileData(
        myHeaders,
        setProfileData,
        setUserLoading,
        navigate,
        lng,
        saveUserDate
      );
      getLang = true;
    } else {
      saveUserDate(userData);
      setUserLoading(false);
    }
  }, [userLoading, token]);

  const saveUserDate = (data) => {
    if (data) {
      setAdmin(Number(data?.accountType?.value) === USER_TYPE.CORPORATE);
      AddDataToHTML("data-theme", data?.themeColour?.description);
      setProfileData(data);
      setUserLoading(false);
      setAccountType(Number(data?.accountType?.value));
      if (data.avatarColour?.value) {
        getIconValue(data.avatarColour?.value, setIcon);
      }
    }
  };

  useEffect(() => {
    if (profileData) {
      saveUserDate(profileData);
    }
  }, [profileData]);

  useEffect(() => {
    if (!token) {
      AddDataToHTML("data-theme", "blue");
      // navigate(`/${lng}/login`);
    }
  }, []);

  return (
    <UserLoginContext.Provider
      value={{
        profileData,
        userLoading,
        token,
        icon,
        account_type,
        setProfileData: saveUserDate,
        notification,
        haveNoRead,
        admin,
      }}
    >
      {children}
    </UserLoginContext.Provider>
  );
};

export const useUserLoginContext = () => useContext(UserLoginContext);
export default UserLoginProvider;
