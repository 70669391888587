import { useEffect, useState } from "react";
import ErrorMessage from "../../components/error/ErrorMessage";
import { useTranslation } from "react-i18next";

const Radio = ({
  name,
  options,
  check,
  action,
  col,
  updateFields,
  data,
  disabled,
  error,
  required,
  gap,
  labelClass = "label1",
  className,
  setError,
  t,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [value, setValue] = useState(data);
  const lang = language.charAt(0).toUpperCase() + language.slice(1);

  const handleChange = (e) => {
    if (action) {
      if (name === "search_by") {
        action(e.target.value);
      } else {
        if (Number(e.target.value) === 1) {
          action(true);
        } else {
          action(false);
          if (name === "is_receive_sms") {
            if (updateFields)
              updateFields({ ["mobile_verification_code"]: "" });
          }
        }
      }
    }
    if (setError) setError((preError) => ({ ...preError, [name]: "" }));
    setValue(e.target.value);
    if (updateFields) {
      updateFields({ [name]: e.target.value });
    }
  };

  useEffect(() => {
    setValue(data);
  }, [data]);

  if (!options || !options.length) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col max-w-full md:ps-sm">
        <div
          className={
            className
              ? className
              : `${
                  gap
                    ? col
                      ? `md:gap-y-${gap}`
                      : `md:gap-y-${gap}`
                    : "gap-y-sm md:gap-y-sm"
                } 
                    flex-row items-baseline  flex-wrap flex ms:pl-md  md:justify-between
                    ${col ? " flex-col" : "flex-row md:items-center"}`
          }
        >
          {options &&
            options.map((option, index) => {
              return (
                <label
                  className={`${labelClass} flex text-800 gap-sm py-x-sm px-md relative ps-xxx-big ${
                    !disabled ? "cursor-pointer" : ""
                  } ${error ? "text-warning Mui-error" : ""}`}
                  key={option.value || option.id}
                  style={{ minWidth: "80px", fontWeight: "400" }}
                >
                  <input
                    name={name}
                    type="radio"
                    className="radio absolute opacity-0 w-0 h-0"
                    value={option.value || option.id}
                    onChange={handleChange}
                    checked={
                      Number(value) === Number(option.value) ||
                      Number(value) === Number(option.id)
                    }
                    disabled={disabled}
                    required={required}
                  />
                  <span className="checkmark absolute top-50 left-0 bg-surface-card border-2 border-default rounded-50% flex justify-center items-center">
                    <span className="checkmark__inner"></span>
                  </span>
                  <span className="align-middle label1">
                    {option.label
                      ? t(option.label)
                      : option.name
                      ? t(option.name)
                      : option[`name${lang}`]
                      ? t(option[`name${lang}`])
                      : ""}
                  </span>
                </label>
              );
            })}
        </div>
        {error && (
          <div>
            <ErrorMessage error={error} t={t} />
          </div>
        )}
      </div>
    </>
  );
};

export default Radio;
