import React, { useMemo, useState } from "react";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import { useTranslation } from "react-i18next";
import SearchBar from "../../components/input/SearchBar";
import AccountManagementTemplate from "../../components/account_management/Template";
import { ReactComponent as YesSVG } from "../../assets/images/others/yes.svg";
import { ReactComponent as NoSVG } from "../../assets/images/others/cancel.svg";
import LoadingUI from "../../components/loading/LoadingUI";
import DataTable from "../../components/table/DataTable";
import { AddButton, EditButton } from "../../components/button/SingleButton";
import BasicTag from "../../components/tags/BasicTag";
import SmallFunction from "../../components/function/SmallFunction";
import Breadcrumb from "../../components/header/Breadcrumb";

const AppointmentManagement = ({ lng }) => {
  const { account_type, userLoading } = useUserLoginContext();
  const { t } = useTranslation();
  const { changeDate } = SmallFunction();
  const [loading, setLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [checkedStatus, setCheckedStatus] = useState({});
  const [data, setData] = useState([]);
  const lang = lng ? lng[0].toUpperCase() + lng.slice(1) : "";

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 100,
        grow: false,
        enableSorting: false,
      },
      {
        accessorKey: "principalCorporateName",
        header: "Principal User",
        minSize: 240,
        grow: false,
        enableSorting: false,
      },
      {
        accessorKey: "agentCorporateName",
        header: "Agent",
        minSize: 240,
        grow: false,
        enableSorting: false,
      },
      {
        accessorKey: "startDate",
        header: "Effective Period",
        minSize: 180,
        grow: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          return (
            <span>
              {changeDate(cell.getValue())} -{" "}
              {row.original.endDate ? changeDate(row.original.endDate) : "N/A"}
            </span>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        minSize: 220,
        grow: true,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          const status_list = cell.getValue() ? cell.getValue() : "";
          return Object.entries(status_list)?.length ? (
            <div className="justify-between items-center flex me-10px w-full gap-md">
              <div className="gap-xx-sm flex-wrap flex">
                <span className="body2">
                  <BasicTag
                    key={status_list?.value}
                    label={status_list?.[`label${lang}`]}
                    color={status_list?.color}
                  />
                </span>
              </div>
            </div>
          ) : null;
        },
      },
      {
        accessorKey: "isAuthorised",
        header: "Authorisation Letter",
        size: 140,
        grow: true,
        enableSorting: false,
        Cell: ({ cell, row }) => (
          <div className="flex justify-center w-full">
            {cell.getValue() ? <YesSVG width={24} /> : <NoSVG width={24} />}
          </div>
        ),
      },
      {
        accessorKey: "button",
        header: "",
        size: 80,
        enableSorting: false,
        grow: false,
        Cell: ({ cell, row }) => (
          <EditButton
            t={t}
            href={`/${lng}/appointment-management/appointment-details/${row.original.id}`}
          />
        ),
      },
    ],
    [data]
  );

  const CalladdBtn = () => {
    return (
      <div className="w-fit">
        <AddButton
          t={t}
          href={`/${lng}/appointment-management/appointment-creation`}
        />
      </div>
    );
  };

  const handleCheckboxSearch = (event) => {
    const value = event.target.value;
    setCheckedStatus((prevState) => {
      const newState = { ...prevState };
      if (newState[value]) {
        delete newState[value];
        setSelectStatus("");
      } else {
        Object.keys(newState).forEach((key) => delete newState[key]);
        newState[value] = true;
        setSelectStatus(value);
      }
      setIsFetching(true);
      return newState;
    });
  };

  const tableDataProps = {
    columns,
    enableSelection: true,
    setRowSelection,
    rowSelection,
    setCustomLoading: setLoading,
    customLoading: loading,
    border: false,
    apiProps: {
      searchData,
      isFetching,
      setIsFetching,
      data,
      setState: setData,
      api: "appointment",
      appandData: {
        keyword: searchData,
        status: selectStatus,
      },
    },
    pageSizeOptions: [10, 20, 50],
  };

  if (userLoading) return <LoadingUI />;

  return (
    <>
      <Breadcrumb lng={lng} />
      <AccountManagementTemplate
        lng={lng}
        accountType={account_type}
        title="Appointments"
        button_bar={CalladdBtn()}
      >
        <div className="flex flex-col gap-xx-big">
          <div className="bg-surface-card p-xxx-big flex flex-col gap-x-big">
            <SearchBar
              setLoading={setLoading}
              setIsFetching={setIsFetching}
              data={data}
              setSearchData={setSearchData}
            />
            <div className="flex gap-x-big flex-wrap">
              {data?.statusList &&
                data?.statusList?.length &&
                data?.statusList?.map((item, index) => {
                  if (item.total < 1) return null;
                  const style = {
                    backgroundColor: `${item?.color}`,
                  };
                  return (
                    <label key={item?.value} className="cursor-pointer">
                      <div
                        style={
                          Number(selectStatus) === Number(item?.value)
                            ? {
                                border:
                                  " 1px solid var(--border-highlight) !important",
                              }
                            : {}
                        }
                        className={`border border-light bg-white rounded-[6px] w-[260px] body2 text-body flex items-center gap-sm py-x-sm pl-sm pr-xx-sm ${
                          checkedStatus[item?.value] ? "outline-primary" : ""
                        }`}
                      >
                        <span
                          className="rounded-full w-sm h-sm block"
                          style={style}
                        ></span>
                        <span
                          className="font-medium block w-[52px] py-xx-sm px-x-sm text-center rounded-x-sm"
                          style={{ boxShadow: "var(--box-shadow-inner)" }}
                        >
                          {item?.total}
                        </span>
                        <span>{item?.[`label${lang}`]}</span>
                      </div>
                      <input
                        type="checkbox"
                        name="status"
                        className="hidden"
                        value={item?.value}
                        onChange={handleCheckboxSearch}
                        checked={!!checkedStatus[item?.value]}
                      />
                    </label>
                  );
                })}
            </div>
          </div>
          <DataTable {...tableDataProps} />
        </div>
      </AccountManagementTemplate>
    </>
  );
};

export default AppointmentManagement;
