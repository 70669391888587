import FieldCard from "../../components/form/FieldCard";
import Input from "../../components/input/Input";
import PasswordTemplate from "../../components/form/password/template";

const StepFour = ({ active, updateFields, data, step, disabled, error, setError, t }) => {
    const isFinal = step === "final";
    const notFinal = !isFinal;

    const InputProps = (name, label, required = false, optional = false) => ({
        name,
        inputLabel: label,
        updateFields,
        data: data[name],
        disabled,
        error: error ? error[name] : '',
        required,
        optional,
        setError,
        t,
    });

    const PasswordProps = () => ({
        updateFields,
        passwordData: data["password"],
        confirmData: data["password2"],
        disabled,
        passwordError: error ? error["password"] : '',
        confirmError: error ? error["password2"] : '',
        autocomplete: false,
        setError,
        t,
    });

    const renderAccountFields = () => (
        <FieldCard title="Web Portal Account" t={t}>
            {(notFinal || (isFinal && data["username"])) && (
                <Input {...InputProps('username', 'Username')}/>
            )}
            {/*{(active > 1 || (isFinal && (data["name_en"] || data["name_zh"]))) && (*/}
            {/*    <div className="flex gap-sm">*/}
            {/*        {(notFinal || (isFinal && data["name_en"])) && (*/}
            {/*            <Input {...InputProps('name_en', 'English Name')} />*/}
            {/*        )}*/}
            {/*        {(notFinal || (isFinal && data["name_zh"])) && (*/}
            {/*            <Input {...InputProps('name_zh', 'Chinese Name', false, true)} />*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*)}*/}
            {(notFinal ? (
                <PasswordTemplate {...PasswordProps()} />
            ) : (
                <Input data="********" inputLabel="Password" disabled t={t} />
            ))}
        </FieldCard>
    );

    return (
        <>
            <div className="gap-md flex flex-col">
                {(notFinal || isFinal && (data["username"] || data["name_en"] || data["name_zh"] || data["password"] || data["password2"])) && (
                    renderAccountFields()
                )}
            </div>
        </>
    );
};

export default StepFour;
