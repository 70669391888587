import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import ProfileSection from "../../components/my-profile/ProfileSection";
import Input from "../../components/input/Input";
import Radio from "../../components/input/Radio";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import LoadingUI from "../../components/loading/LoadingUI";
import AccountManagementTemplate from "../../components/account_management/Template";
import { BlueForwardButton, ArrowBackButton } from "../../components/button/SingleButton";
import Breadcrumb from "../../components/header/Breadcrumb";
import ErrorMessage from "../../components/error/ErrorMessage";
import user_account_type from "../../data/user_account_type.json"

const CorporateAccountCreation = ({ lng }) => {
    const { t } = useTranslation();
    const { userLoading, token } = useUserLoginContext();
    const { postForm, loading } = SubmitAndFetch({ lng, t });
    const [data, setData] = useState([]);
    const [error, setError] = useState({});

    const updateFields = (fields) => {
        setData(prev => ({ ...prev, ...fields }));
    };

    const inputProps = (name, inputLabel = null, type = "text") => ({
        name,
        inputLabel,
        type,
        data: data ? data[name] : '',
        error: error ? error[name] : '',
        updateFields,
        t,
        setError,
    });

    const handleSubmit = async (event) => {
        setError({})
        event.preventDefault();
        const formData = new FormData(event.target);
        await postForm("corporate/account/add", null, formData, setError, `/${lng}/corporate-accounts-management`, false, null, token)
    };

    if (userLoading) return <LoadingUI/>;
    return (
        <>
            <Breadcrumb lng={lng}/>
            <AccountManagementTemplate lng={lng} title="Corporate Account Creation" noMenu={true}>
                <form onSubmit={handleSubmit}>
                    <div className="profile-field bg-white rounded-big flex flex-col gap-md md:gap-xxx-big">
                        <ProfileSection title="Personal Particulars" t={t}>
                            <div className="flex gap-sm flex-col lg:flex-row">
                                <Input {...inputProps("name_en", "English Name")}/>
                                <Input {...inputProps("name_zh", "Chinese Name")}/>
                            </div>
                            <Input {...inputProps("email", "Email Address")}/>
                        </ProfileSection>
                        <div className="mt-xxx-big">
                            <ProfileSection title="User Type" t={t}>
                                <Radio {...inputProps("account_type")} options={user_account_type} className={'w-fit flex-row flex gap-lg'}/>
                            </ProfileSection>
                        </div>
                        <ErrorMessage message={error?.message} t={t} />
                    </div>
                    <div className="flex justify-between items-center mt-xxx-big">
                        <ArrowBackButton t={t} href={`/${lng}/corporate-accounts-management`}/>
                        <BlueForwardButton t={t} label="Create Account" type="submit" loading={loading}/>
                    </div>
                </form>
            </AccountManagementTemplate>
        </>
    );
};

export default CorporateAccountCreation;
