import TextHeadline from "../../components/TextHeadline";
import React, {useEffect, useState} from "react";


import { useTranslation } from "react-i18next";
import Menu from "../../components/help-centre/Menu";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useUserLoginContext } from "../../context/UserLoginProvider";
import LoadingUI from "../../components/loading/LoadingUI";
import Breadcrumb from "../../components/header/Breadcrumb";
import HelpCentreSearchBar from "../../components/help-centre/HelpCentreSearchBar";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import useDocumentTitle from "../../hooks/useDocument";

const GettingStarted = ({ lng }) => {
    const { t } = useTranslation();
    const { userLoading, token } = useUserLoginContext();
    const [expanded, setExpanded] = useState(false);
    const [categoryId, setCategoryId] = useState('1');
    const [menuList, setMenuList] = useState([]);
    const [faqsList, setFaqsList] = useState([]);
    const { fetchData } = SubmitAndFetch({ lng, t});
    const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
    const { cannotFind } = HelpCentreSearchBar({ t, lng });
    useDocumentTitle("FAQs");

    const fetchFaqsList = async () => {
        await fetchData(`faq/list/${categoryId}`, null, setFaqsList, null, lng, token)
    }

    useEffect(() => {
        const fetchMenu = async () => {
            await fetchData('faq/category', null, setMenuList, null, lng, token)
        }
        if (token) {
            fetchMenu();
            fetchFaqsList()
        }
    }, [token]);

    useEffect(() => {
        fetchFaqsList();
    }, [categoryId]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    if (userLoading) return <LoadingUI/>;

    const NoDataText = () => (
        <div>{t('No data found')}</div>
    )

    return (
        <>
            <Breadcrumb
                lng={lng}
                homeElement={'Home'}
                currentElement={'FAQs'}
                capitalizeLinks
            />
            <div className="layout-container content-container flex flex-col gap-lg">
                <TextHeadline title={t('FAQs')} />
                {menuList.length ?
                    <div className="flex gap-xx-big flex-col md:flex-row w-full">
                        <Menu menuList={menuList} state={categoryId} setState={setCategoryId} t={t} lang={lang}/>
                        <div className='flex flex-col gap-xxx-big w-full'>
                            <div className="overflow-hidden rounded-big bg-surface-card p-xxx-big">
                                <div className="flex-1 overflow-auto">
                                    <h2 className="h2 text-title mb-x-big">{ menuList?.find((item) => item?.id === categoryId)?.[`name${lang}`] }</h2>
                                    <div className="flex flex-col gap-big">
                                        {faqsList.length ? faqsList.map((item, index) => (
                                            <Accordion
                                                expanded={expanded === index}
                                                onChange={handleChange(index)}
                                                key={item.id}
                                                sx={{
                                                    '&.MuiPaper-root': {
                                                        border: '1px solid var(--border-light)',
                                                        boxShadow: 'none',
                                                        borderRadius: 'var(--radius-sm)',
                                                        padding: 'var(--spacing-big) var(--spacing-md)',
                                                        '&:before': {
                                                            content: 'none',
                                                        },
                                                    },
                                                    '&.Mui-expanded': {
                                                        border: '1px solid var(--label-icon-on-light)',
                                                    },
                                                }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={expanded === item.id ?
                                                        <RemoveIcon style={{color: 'var(--surface-primary)'}}/> :
                                                        <AddIcon style={{color: 'var(--surface-primary)'}}/>}
                                                    sx={{
                                                        minHeight: '0',
                                                        color: 'var(--text-caption)',
                                                        padding: '0',
                                                        '&:hover': {
                                                            color: 'var(--surface-primary)',
                                                        },
                                                        '&.Mui-expanded': {
                                                            color: 'var(--surface-primary)',
                                                            minHeight: '0',
                                                            marginBottom: 'var(--spacing-sm)'
                                                        },
                                                        '&& .MuiAccordionSummary-content': {
                                                            margin: '0',
                                                        },
                                                    }}
                                                >
                                                    <span className='label1'>{item?.[`title${lang}`]}</span>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    sx={{
                                                        padding: '0',
                                                    }}
                                                >
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: item?.[`content${lang}`]}}
                                                        className="body2 text-body"></div>
                                                </AccordionDetails>
                                            </Accordion>
                                        )) : NoDataText()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : NoDataText()
                }
            </div>
            {cannotFind()}
        </>
    )
}

export default GettingStarted;