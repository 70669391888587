const MtCaptchaScript = ({ loadingCapthcha, setLoadingCapthcha }) => {
    const loadScript = (src, id) => {
        return new Promise((resolve, reject) => {
            if (document.getElementById(id)) {
                resolve();
                return;
            }
            const script = document.createElement("script");
            script.src = src;
            script.id = id;
            script.async = true;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error(`Failed to load script ${src}`));
            document.body.appendChild(script);
        });
    };

    const loadMtCaptcha = async () => {
        setLoadingCapthcha(true);
        window.mtcaptchaConfig = {
            sitekey: process.env.REACT_APP_PUBLIC_SITE_KEY,
            renderQueue: ["mtcaptcha"],
            isVisible: false,
            loadAnimation: false,
        };

        try {
            await loadScript(`${process.env.PUBLIC_URL}/static/js/mtcaptcha.min.js`, 'mtcaptcha-script');
            await loadScript(`${process.env.PUBLIC_URL}/static/js/mtcaptcha.min.js`, 'mtcaptcha-script2');
            setLoadingCapthcha(false);
            if (window.MTCaptcha) {
                setLoadingCapthcha(false);
                window.MTCaptcha.render('mtcaptcha');
            }
        } catch (error) {
            console.error("Failed to load mtcaptcha scripts", error);
        }
    };

    return {
        loadMtCaptcha,
    };
};

export default MtCaptchaScript;