import CircularProgress from "@mui/material/CircularProgress";
import ErrorMessage from "../../components/error/ErrorMessage";
import { useTranslation } from "react-i18next";

const MtCaptcha = ({ loadingCapthcha, error }) => {
    const { t} = useTranslation();
    return (
        <>
            {/*{loadingCapthcha ? <CircularProgress style={{marginTop: '10px', color: 'var(--surface-primary)'}}/> : <div className='mtcaptcha'></div>}*/}
            <div className='mtcaptcha'></div>
            {error &&  <ErrorMessage message={error} t={t}/>}
        </>
    )
}

export default MtCaptcha;