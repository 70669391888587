import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enJSON from "./locales/en/translation.json";
import tcJSON from "./locales/tc/translation.json";
import scJSON from "./locales/sc/translation.json";

const pathParts = window.location.pathname.split("/");
const lang_array = ["en", "tc", "sc"];
const language = lang_array.includes(pathParts[2]) ? pathParts[2] : "en";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enJSON },
      tc: { translation: tcJSON },
      sc: { translation: scJSON },
    },
    fallbackLng: "en",
    lng: language,
    detection: {
      order: ["cookie", "localStorage", "navigator"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
  });
