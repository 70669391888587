import React, { useEffect, useState } from "react";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import { useTranslation } from "react-i18next";
import ProfileSection from "../../components/my-profile/ProfileSection";
import SubmitAndFetch from "../../components/function/SubmitAndFetch";
import AccountManagementTemplate from "../../components/account_management/Template";
import LoadingUI from "../../components/loading/LoadingUI";
import {
  DiscardButton,
  PrimaryButton,
} from "../../components/button/SingleButton";
import blue from "../../assets/images/avatars/blue_avatars.png";
import green from "../../assets/images/avatars/green_avatars.png";
import grey from "../../assets/images/avatars/grey_avatars.png";
import cyan from "../../assets/images/avatars/cyan_avatars.png";
import orange from "../../assets/images/avatars/orange_avatars.png";
import pink from "../../assets/images/avatars/pink_avatars.png";
import purple from "../../assets/images/avatars/purple_avatars.png";
import yellow from "../../assets/images/avatars/yellow_avatars.png";
import Line from "../../components/line/Line";
import DoneIcon from "@mui/icons-material/Done";
import { AddDataToHTML } from "../../assets/js/global";
import Switcher from "../../components/button/Switcher";
import Switch from "../../components/input/Switch";
import Checkbox from "../../components/input/Checkbox";
import Tooltip from "../../components/tooltip/Tooltip";
import WhiteInput from "../../components/input/portal/WhiteInput";
import { Chip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Breadcrumb from "../../components/header/Breadcrumb";
import SmallFunction from "../../components/function/SmallFunction";
import ErrorMessage from "../../components/error/ErrorMessage";
import Popup from "../../components/popup/Popup";
import { useNavigate } from "react-router-dom";
const { getIconValue } = SmallFunction();

const PersonalisationPage = ({ lng }) => {
  const { t } = useTranslation();
  const { setProfileData, userLoading, token, icon, account_type } =
    useUserLoginContext();
  const navigate = useNavigate();
  const [userIcon, setUserIcon] = useState("");
  const [selectIcon, setSelectIcon] = useState("");
  const [selectTheme, setSelectTheme] = useState("");
  const [Datalist, setDataList] = useState([]);
  const { postForm, loading } = SubmitAndFetch({ lng, t });
  const [error, setError] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState({}); //save or update user data
  const [oldData, setOldData] = useState({}); //save or update user data
  const [addEmailLoading, setAddEmailLoading] = useState(false);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const [verifyType, setVerifyType] = useState(null);
  const [addEmail, setAddEmail] = useState({});
  const api_name = "personalisation";
  const lang = lng.charAt(0).toUpperCase() + lng.slice(1);
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    if (selectIcon) {
      getIconValue(selectIcon, setUserIcon);
    }
  }, [selectIcon]);

  useEffect(() => {
    const getData = async () => {
      await postForm(
        "/personalisation",
        null,
        null,
        setError,
        null,
        false,
        handleGetProfileData,
        token,
        null,
        null,
        null,
        false
      );
    };
    getData();
  }, []);

  const updateLocalStorage = (resData) => {
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    userData.themeColour = resData.themeColour;
    userData.avatarColour = resData.avatarColour;
    localStorage.setItem("userData", JSON.stringify(userData));
  };

  const setProfileDataState = (resData, table_data) => {
    setSelectIcon(resData.avatarColour?.value);
    setSelectTheme(resData.themeColour?.value);
    setProfileData((prevData) => ({
      ...prevData,
      themeColour: resData.themeColour,
      avatarColour: resData.avatarColour,
    }));
    setTableData(table_data);
    setDataList({
      themeColor: resData.themeColourList,
      avatarColor: [
        { icon: orange, value: "1", color: "#FFC27D", description: "orange" },
        { icon: yellow, value: "2", color: "#FFF394", description: "yellow" },
        { icon: green, value: "3", color: "#D1FF82", description: "green" },
        { icon: cyan, value: "4", color: "#89FFDD", description: "cyan" },
        { icon: blue, value: "5", color: "#8CD5FF", description: "blue" },
        { icon: purple, value: "6", color: "#E4C6FA", description: "purple" },
        { icon: pink, value: "7", color: "#FE9AB8", description: "pink" },
        { icon: grey, value: "8", color: "#E5E5E5", description: "grey" },
      ],
      langCommunicationList: resData.langCommunicationList,
    });
  };

  const handleGetProfileData = (resData) => {
    if (resData) {
      const table_data = updateNotificationList(resData.notificationList);
      updateLocalStorage(resData);
      setProfileDataState(resData, table_data);
      const profileObject = {
        lang_communication: resData.langCommunication?.value,
        showEmail: resData.showNotificationEmail,
        showSms: resData.showNotificationSms,
        showNotificationCenter: resData.showNotificationCenter,
        is_notification_sms: resData.isNotificationSms,
        is_notification_email: resData.isNotificationEmail,
        is_notification_center: resData.isNotificationCenter,
        isVerifiedEmail: resData.isVerifiedEmail,
        isVerifiedMobile: resData.isVerifiedMobile,
        avatar_colour: resData.avatarColour?.value,
        theme_colour: resData.themeColour,
        emailReceiverList: resData.emailReceiverList,
        table_data,
      };
      setAddEmail(resData.emailReceiverList);
      setOldData(profileObject);
      setData(profileObject);
    }
  };

  const updateFields = (fields) => setData((prev) => ({ ...prev, ...fields }));

  const inputProps = (
    name,
    label,
    optional = false,
    disabled = false,
    action = null,
    type = "text"
  ) => ({
    name,
    inputLabel: label,
    data: data ? data[name] : "",
    error: error ? error[name] : "",
    optional,
    action,
    type,
    disabled,
    lng,
    setError,
    updateFields,
    t,
  });

  const checkboxProps = (name, data, readOnly = false) => ({
    name,
    data: data,
    readOnly,
    lng,
    setError,
    t,
  });

  const popupProps = (title, setState, text) => ({
    apiProps: {
      setError,
      customSubmit: handlePopupSubmit,
      loading,
    },
    t,
    setState,
    title,
    text,
  });

  const columns = [
    {
      key: "showNotificationCenter",
      label: "Notification Center",
      fieldName: "is_select_notification_center",
      isNotification: "is_notification_center",
    },
    {
      key: "showSms",
      label: "SMS",
      fieldName: "is_select_sms",
      isNotification: "is_notification_sms",
    },
    {
      key: "showEmail",
      label: "Email",
      fieldName: "is_select_email",
      isNotification: "is_notification_email",
    },
  ];

  const handlePopupSubmit = () => {
    navigate(`/${lng}/my-profile`);
  };

  const verifyPopup = (type) => {
    return (
      <Popup
        confirm_popup={true}
        {...popupProps(
          `${type} Notification Services`,
          setShowVerifyPopup,
          `If you would like to receive notifications in ${type}, please go to “My Profile” to update and verify your mobile no.`
        )}
      />
    );
  };

  const processFormData = (formData) => {
    const items = {};
    formData.forEach((value, key) => {
      const prefix = columns.find((p) => key.startsWith(p.fieldName));
      if (prefix) {
        const id = key.slice(prefix.fieldName.length + 1);
        if (!items[id]) {
          items[id] = { id };
        }
        items[id][prefix.fieldName] =
          value === "true" || value === "0" ? "0" : "1";
      }
    });

    return Object.values(items);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const emailValues = Object.values(addEmail);
    const emailArray = Array.isArray(emailValues) ? emailValues : [emailValues];
    const formData = new FormData(event.target);
    const notificationList = processFormData(formData);
    const payload = {
      avatar_colour: selectIcon,
      theme_colour: selectTheme,
      email_receiver_list: emailArray,
      lang_communication: formData.get("lang_communication"),
      notification_list: notificationList,
    };

    columns.forEach((col) => {
      const fieldName = col.isNotification;
      payload[fieldName] = formData.get(fieldName) === "on" ? "1" : "0";
    });

    const slug = "update";
    postForm(
      api_name,
      slug,
      payload,
      setError,
      null,
      false,
      handleGetProfileData,
      token,
      null,
      null,
      true
    );
  };

  const handleAddEmailReceivers = () => {
    if (data["email"]) {
      const keys = Object.entries(addEmail);
      const lastKey = keys.length > 0 ? keys.length : 0;
      setAddEmail((prevState) => ({
        ...prevState,
        [lastKey ? lastKey : 0]: data["email"],
      }));
    }
  };

  const setToDefaultValue = () => {
    if (Object.entries(oldData).length) {
      setData(oldData);
      setAddEmail(oldData?.emailReceiverList);
      setSelectIcon(oldData?.avatar_colour);
      setSelectTheme(oldData?.theme_colour.value);
      AddDataToHTML("data-theme", oldData?.theme_colour.description);
      setAddEmail(
        oldData?.emailReceiverList
          ? oldData?.emailReceiverList.reduce((acc, email, index) => {
              acc[index] = email;
              return acc;
            }, {})
          : {}
      );
    }
    setError([]);
    setResetKey((prevKey) => prevKey + 1);
  };

  const updateNotificationList = (notificationList) => {
    return notificationList.map((category) => ({
      ...category,
      list: category.list.map((item) => {
        const updatedItem = { ...item };

        columns.forEach((col) => {
          const fieldName = `${col.fieldName}_${item.id}`;
          const isSelectKey = `isSelect${
            col.label === "SMS" ? "Sms" : col.label.replace(" ", "")
          }`;
          updatedItem[fieldName] = item[isSelectKey];
          delete updatedItem[isSelectKey];
        });
        return updatedItem;
      }),
    }));
  };

  const tableTemplate = (head, body, index) => {
    const header = [head];
    columns.forEach((col) => {
      if (body[col.key]) header.push(col.label);
    });

    return (
      <table className="w-full border-separate border-spacing-0" key={index}>
        <thead>
          <tr>
            {header.map((item, index) => (
              <th
                key={index}
                className="border-light border-t border-b text-center label1 text-body h-[56px] max-w-[100px] bg-surface-secondary px-big first:border-l first:text-start first:rounded-tl-sm last:rounded-tr-sm md:first:rounded-tl-big md:last:rounded-tr-big last:border-r"
              >
                {body.categoryId === "2" && index === 0 ? (
                  <span className="flex gap-sm">
                    {item}
                    <Tooltip t={t} title={body?.[`remark${lang}`]} />
                  </span>
                ) : (
                  item
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {body.list.map((item) => (
            <tr key={item.id} className="max-w-[100px] h-[56px]">
              <td className="pl-big border-light border-l border-b">
                {item?.[`name${lang}`]}
              </td>
              {columns.map((col) => {
                if (body[col.key]) {
                  const fieldKey = `${col.fieldName}_${item.id}`;
                  return (
                    <td
                      key={fieldKey}
                      className="text-center border-light border-b"
                    >
                      <div className="flex justify-center items-center">
                        <Checkbox
                          {...checkboxProps(
                            fieldKey,
                            item[fieldKey],
                            !data?.[col.isNotification]
                          )}
                          flex={false}
                          itemStart={false}
                        />
                      </div>
                    </td>
                  );
                }
                return null;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const color = () => (
    <div className="profile-field bg-white rounded-b-big">
      <ProfileSection title="Avatar Colour" t={t}>
        <div className="flex flex-col items-center md:flex-row gap-big md:gap-xx-lg">
          <img src={userIcon} alt="icon" width={120} height={120} />
          <div className="grid-cols-4 grid-rows-2 grid gap-sm">
            {Datalist?.avatarColor &&
              Datalist?.avatarColor.map((item, index) => {
                return (
                  <label
                    key={index}
                    title={item.description}
                    className="w-lg h-lg rounded-full cursor-pointer relative flex justify-center items-center checkboxContainer"
                    style={{ backgroundColor: item.color }}
                  >
                    <span
                      className={`h-full w-x-big ${
                        selectIcon === item.value ? "block" : "hidden"
                      } tickIcon`}
                    >
                      <DoneIcon
                        style={{
                          color: "var(--label-icon-default)",
                          height: "100%",
                        }}
                      />
                    </span>
                    <input
                      type="radio"
                      name="avatar_colour"
                      value={item.value}
                      onChange={() => {
                        setUserIcon(item.icon);
                        setSelectIcon(item.value);
                      }}
                      className="opacity-0 pointer-events-none w-0 h-0"
                    />
                  </label>
                );
              })}
            {error && <ErrorMessage error={error["avatar_colour"]} />}
          </div>
        </div>
      </ProfileSection>
      <Line my="my-x-big" />
      <ProfileSection title="Theme Colour" t={t}>
        <div className="flex gap-big">
          {Datalist?.themeColor &&
            Datalist?.themeColor.map((item, index) => {
              return (
                <label
                  title={item.description}
                  key={index}
                  className="w-[100px] h-[43px] rounded-sm cursor-pointer relative flex justify-center items-center checkboxContainer"
                  style={{ backgroundColor: item.color }}
                >
                  <span
                    className={`h-full w-x-big ${
                      selectTheme === item.value ? "block" : "hidden"
                    } tickIcon`}
                  >
                    <DoneIcon
                      style={{
                        color: "var(--label-icon-on-primary)",
                        height: "100%",
                      }}
                    />
                  </span>
                  <input
                    type="radio"
                    name="theme_colour"
                    value={item.value}
                    onChange={() => {
                      setSelectTheme(item.value);
                      AddDataToHTML("data-theme", item.description);
                    }}
                    className="opacity-0 pointer-events-none w-0 h-0"
                  />
                </label>
              );
            })}
          {error && <ErrorMessage error={error["theme_colour"]} />}
        </div>
      </ProfileSection>
    </div>
  );

  const setting = () => {
    return (
      <div className="profile-field bg-white rounded-b-big flex flex-col gap-x-big mt-xx-big">
        <ProfileSection title="Language Preference for Communication" t={t}>
          <Switcher
            {...inputProps("lang_communication")}
            options={Datalist?.langCommunicationList}
          />
        </ProfileSection>
        <ProfileSection title="Notification Preference" t={t}>
          <div className="flex flex-col items-start gap-big md:flex-row md:gap-xx-lg">
            {columns.map((col) => {
              const key = col.key.replace("show", "").toLowerCase();

              const isShow = data?.[col.key];
              const isVerified =
                data?.[
                  `isVerified${
                    col.label === "SMS" ? "Mobile" : col.label.replace(" ", "")
                  }`
                ] ?? true;

              return (
                isShow && (
                  <Switch
                    verified={isVerified}
                    key={key}
                    {...inputProps(col.isNotification, col.label, false)}
                    action={(event) => {
                      if (!isVerified) {
                        setVerifyType(col.label);
                        setShowVerifyPopup(true);
                      } else {
                        setData((prev) => ({
                          ...prev,
                          [col.isNotification]: event.target.checked,
                        }));
                      }
                    }}
                    labelPlacement="start"
                    labelClass="text-title label1"
                  />
                )
              );
            })}
          </div>
        </ProfileSection>

        {tableData?.map((item, index) => {
          return (
            <div key={`${item.categoryId}-${resetKey}`} className="w-full">
              {tableTemplate(item?.[`name${lang}`], item, index)}
            </div>
          );
        })}
        {error && <ErrorMessage error={error?.["notification_list"]} />}
        <Line />
        <ProfileSection
          t={t}
          title="Email Notification Receivers DataList"
          tooltop="Add others email address to receive notification even they don't have the Web Portal Account."
        >
          <div className="flex gap-sm flex-col md:flex-row">
            <WhiteInput {...inputProps("email")} placeholder="Email Address" />
            <div className="flex gap-sm me-auto md:me-0">
              <PrimaryButton
                t={t}
                label="Add"
                loading={addEmailLoading}
                action={handleAddEmailReceivers}
              />
              <DiscardButton
                action={() => updateFields({ email: "" })}
                label="Cancel"
                t={t}
              />
            </div>
          </div>
          <ErrorMessage error={error["email_receiver_list"]} />
        </ProfileSection>
        {addEmail && Object.entries(addEmail).length > 0 && (
          <div className="flex gap-sm items-center flex-wrap">
            <span className="w-full md:w-fit">Receivers:</span>
            {addEmail &&
              Object.entries(addEmail).map(([k, item]) => {
                return (
                  <Chip
                    className="label1 text-body"
                    key={k}
                    label={item}
                    onDelete={() => handleDelete(k, addEmail, setAddEmail)}
                    deleteIcon={
                      <CloseIcon
                        style={{ color: "var(--Neutral-600)" }}
                        width={20}
                        fontSize="inherit"
                      />
                    }
                    sx={{
                      width: "fit-content",
                      background: "var(--surface-card)",
                      border: "1px solid var(--border-default)",
                      borderRadius: "var(--radius-lg)",
                      "& .MuiChip-label": {
                        padding: "0",
                      },
                      "& .MuiChip-deleteIcon": {
                        marginLeft: "var(--spacing-xx-sm)",
                      },
                      padding:
                        "var(--spacing-x-sm) var(--spacing-sm) var(--spacing-x-sm) var(--spacing-md)",
                    }}
                  />
                );
              })}
          </div>
        )}
      </div>
    );
  };

  const handleDelete = (key, addEmail, setState) => {
    const newStatus = { ...addEmail };
    delete newStatus[key];
    setState(newStatus);
  };

  if (userLoading) return <LoadingUI />;

  return (
    <>
      {showVerifyPopup && verifyPopup(verifyType)}
      <Breadcrumb lng={lng} />
      <form onSubmit={handleSubmit} className="w-full personalisation_page">
        <AccountManagementTemplate
          lng={lng}
          title="Personalisation"
          accountType={account_type}
        >
          {color()}
          {setting()}
          <div className="flex justify-between mt-xx-big">
            <DiscardButton action={setToDefaultValue} label="Discard" t={t} />
            <PrimaryButton
              t={t}
              label="Save Changes"
              loading={loading}
              type="submit"
            />
          </div>
        </AccountManagementTemplate>
      </form>
    </>
  );
};

export default PersonalisationPage;
