import { ReactComponent as PdfSVG } from "../../assets/images/upload/pdf.svg";
import { ReactComponent as JpgSVG } from "../../assets/images/upload/imagery.svg";
import { ReactComponent as CSVIcon } from "../../assets/images/upload/csv.svg";
import { ReactComponent as VideoSVG } from "../../assets/images/upload/video.svg";
import { ReactComponent as XlsSVG } from "../../assets/images/upload/xls.svg";
import { DownloadButton, RemoveButton } from "../button/SingleButton";
import BasicTag from "../../components/tags/BasicTag";
import SmallFunction from "../../components/function/SmallFunction";
import { useTranslation } from "react-i18next";
import { useUserLoginContext } from "../../context/UserLoginProvider";
import SubmitAndFetch from "../function/SubmitAndFetch";
import { useEffect } from "react";

const UploadFilePreview = ({
  data,
  disabled,
  handleDeleteFile,
  t,
  document_type,
  downloadApiProps,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { handleUnits } = SmallFunction();
  const { token } = useUserLoginContext();
  const { downloadData, previewFile } = SubmitAndFetch({ lng: language, t });
  const lang = language.charAt(0).toUpperCase() + language.slice(1);

  const getSvg = (type) => {
    switch (type) {
      case "application/pdf":
        return <PdfSVG width={32} />;
      case "image/jpeg":
      case "image/png":
        return <JpgSVG width={32} />;
      case "text/csv":
        return <CSVIcon width={32} />;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return <XlsSVG width={32} />;
      case "video/mp4":
        return <VideoSVG width={32} />;
      default:
        return null;
    }
  };

  const callPreviewFileApi = async () => {
    await previewFile(data?.api, token);
  };

  const handleDownloadTemplate = async () => {
    await downloadData(
      downloadApiProps?.downloadApi,
      token,
      downloadApiProps?.appendData
    );
  };

  const isFile = data instanceof File;

  return data && Object.entries(data)?.length ? (
    <div
      className="flex gap-big items-center px-x-sm"
      key={data?.path || data?.fileName}
    >
      {getSvg(data?.type || data?.fileType)}
      <div className="flex flex-col gap-xx-sm">
        <span
          className=" body2_fw600 text-title text-start break-words cursor-pointer hover:underline max-w-[280px] min-w-[280px]"
          onClick={
            data?.api
              ? () => callPreviewFileApi()
              : () => {
                  const url = isFile
                    ? URL.createObjectURL(data)
                    : data?.path || "#";
                  if (url !== "#") {
                    window.open(url, "_blank");
                  } else if (downloadApiProps?.downloadApi) {
                    handleDownloadTemplate();
                  }
                }
          }
        >
          {data?.name || data?.fileName}
        </span>
        <div className="flex-col md:flex-row flex gap-x-sm text-placeholder w-fit text-nowrap label2 md:items-center md:text-center">
          <span>
            {typeof data?.size === "number"
              ? handleUnits(data?.size)
              : data?.size || data?.fileSize}
          </span>
          <span className="hidden md:block w-xx-sm h-xx-sm bg-surface-disabled rounded-full"></span>
          {data?.updated_at || data?.fileUploadDate ? (
            <span>
              {t("Uploaded at")} {data?.updated_at || data?.fileUploadDate}
            </span>
          ) : null}
        </div>
      </div>
      {document_type && (
        <div className="ml-big">
          <BasicTag
            label={document_type.label || document_type?.[`label${lang}`]}
            color={document_type.color}
          />
        </div>
      )}
      {!handleDeleteFile && !disabled ? (
        <DownloadButton t={t} href={data?.path} />
      ) : (!disabled && handleDeleteFile && (!data?.id || data?.import)) ||
        (disabled && handleDeleteFile && (!data?.id || data?.import)) ? (
        <RemoveButton
          t={t}
          handleRemove={(event) =>
            handleDeleteFile(
              event,
              data?.path ? data?.path : data?.id ? data?.id : null
            )
          }
          data={data}
        />
      ) : null}
    </div>
  ) : (
    "--"
  );
};

export default UploadFilePreview;
