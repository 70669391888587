import {Link} from "react-router-dom";
import SmallFunction from "../function/SmallFunction";

const ArticlesTemplate = ({data, lang, lng}) => {
    const { changeDate } = SmallFunction();
    return (
       data && data?.length ? data.map((item, index) => {
            return (
                <Link key={index} className='article_link flex flex-col gap-sm mb-xx-sm' title={item?.[`title${lang}`]} to={item?.[`externalLink${lang}`] ? item?.[`externalLink${lang}`] : `/${lng}/help-centre/articles/details/${item.id}`}>
                    <div className='article_link_image_container overflow-hidden rounded-sm'>
                        <img src={item?.[`thumbnail${lang}`]} alt={item?.[`title${lang}`]} className='rounded-sm'/>
                    </div>
                    <div className='flex flex-col gap-[6px]'>
                        <span className='label1'>{item?.[`title${lang}`]}</span>
                        <span className='label2 text-caption'>{changeDate(null, null, item.postDate)}</span>
                    </div>
                </Link>
            )
        }) : null
    )
}

export default ArticlesTemplate;