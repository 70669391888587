import React, { useEffect, useState } from "react";
import Popup from "../../components/popup/Popup";
import DataTable from "../../components/table/DataTable";
import CustomSearchBar from "../../components/search/CustomSearchBar";
import SubmitAndFetch from "../function/SubmitAndFetch";
import { useTranslation } from "react-i18next";
import { useUserLoginContext } from "../../context/UserLoginProvider";

const ImportPopup = ({
  searchList,
  setState,
  searchColumns,
  PopupProps,
  searchByOptions,
  multi,
  t,
  title,
  handleSubmit,
  disableSelection = false,
  apiProps,
}) => {
  const [searchLoading, setSearchLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const { token } = useUserLoginContext();
  const [searchBy, setSearchBy] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const {
    i18n: { language },
  } = useTranslation();
  const { postForm } = SubmitAndFetch({ lng: language, t });

  useEffect(() => {
    if (token || isFetching) {
      handleSearch();
    }
  }, [token]);

  const getAppendData = () => {
    let result = "";
    searchByOptions?.options?.forEach((item) => {
      if (searchList?.data?.[item.name]) {
        result = searchList?.data?.[item.name];
      }
    });
    return result;
  };
  const handleSearch = async () => {
    const formData = new FormData();
    formData.append("search_type", searchBy);
    if (searchByOptions) {
      formData.append("keyword", getAppendData());
    }
    formData.append("page_num", 0);
    formData.append("page_size", 10);
    formData.append(`sort_by[upload_date]`, "desc");
    await postForm(
      apiProps?.api_name,
      null,
      formData,
      null,
      null,
      null,
      handleSaveData,
      token,
      null,
      setSearchLoading,
      false
    );
  };

  const handleSaveData = (resData) => {
    if (resData) {
      searchList?.setSearchData(resData);
      setTotalRows(resData?.total);
      setTotalPages(resData?.pages);
    }
  };

  const tableDataProps = {
    columns: searchColumns,
    enableSelection: true,
    pageIndex: searchList?.searchData?.pageNum - 1 || 0,
    setRowSelection: searchList.setDocSearchSelection,
    rowSelection: searchList.docSearchSelection,
    loading: searchLoading,
    border: false,
    multi: multi,
    enablePageSizeOptions: false,
    disableSelection: disableSelection,
    apiProps: {
      isFetching,
      setIsFetching,
      data: searchList.searchData,
      setState: searchList.setSearchData,
      api: apiProps?.api_name,
      appandData: {
        keyword: getAppendData(),
        search_type: searchBy,
      },
      DefaultSorting: [{ id: "uploadTime", desc: true }],
    },
  };

  return (
    <Popup
      {...PopupProps(setState)}
      apiProps={apiProps}
      submit_loading={searchLoading}
      import_Popup={true}
      title={title}
    >
      <CustomSearchBar
        searchBy={searchBy}
        setSearchBy={setSearchBy}
        options={searchByOptions}
        t={t}
        searchbar={true}
        noShowAdvance={true}
        customSearchAction={handleSearch}
        searchList={searchList}
        resetAction={() => {
          searchByOptions?.options?.forEach((item) => {
            searchList?.updateFields({
              [item.name]: "",
            });
          });
          setIsFetching(true);
          setSearchBy(1);
        }}
      />
      <div className="flex-1 overflow-y-auto custom-scrollbar mt-xxx-big">
        <DataTable {...tableDataProps} />
      </div>
    </Popup>
  );
};

export default ImportPopup;
