import Tooltip from "../../components/tooltip/Tooltip";

const ProfileSection = ({ title=null, children, subtitle, button, tooltop, t, titleClass }) => {

    return (
        title ? (
            <div className="flex flex-col gap-x-big">
                {title &&
                    <div className="flex items-center justify-between">
                        <div className={titleClass ? titleClass : "subtitle field-card-title"}>
                            <div className="flex gap-sm items-center">
                                { t(title) }
                                { tooltop && <Tooltip title={tooltop} t={t} />}
                            </div>
                            { subtitle && <div className="mt-x-sm body2 text-title">{ t(subtitle) }</div> }

                        </div>
                            { button && button }
                    </div>
                }
                { children }
            </div>
        ) : children
    )
}

export default ProfileSection;
