import SectionTemplate from "../../components/proposal-submission-form/sectionTemplate";
import Radio from "../../components/input/Radio";
import React from "react";
import Input from "../../components/input/Input";
import FileDropzone from "../../components/upload/FileDropzone";
import UPLOAD_DOC_TYPE from "../../data/upload_doc_type.json";
import UploadFilePreview from "../../components/upload/UploadFilePreview";

const capacityOption = [
  {
    value: 1,
    label: "Owner",
  },
  {
    value: 2,
    label: "Occupier",
  },
  {
    value: 3,
    label: "Agent for owner",
  },
  {
    value: 4,
    label: "Agent for occupier",
  },
];

const StepTwo = ({
  inputProp,
  stepTwoOptions,
  data,
  t,
  setError,
  updateStepTwoFormData,
}) => {
  return (
    <>
      <div className="flex flex-col gap-xxx-big max-w-900px mx-auto">
        <SectionTemplate title="Capacity" t={t}>
          <Radio
            {...inputProp("capacity")}
            options={capacityOption}
            className="grid grid-cols-2 md:gap-big"
            labelClass="flex-1 w-full"
            action={() => {
              setError((preError) => ({ ...preError, agent_name_en: "" }));
              setError((preError) => ({ ...preError, agent_name_zh: "" }));
              updateStepTwoFormData({
                agent_name_en: "",
                agent_name_zh: "",
                auth_letter: "",
              });
            }}
          />
          {(Number(data["capacity"]) === 3 ||
            Number(data["capacity"]) === 4) && (
            <>
              <div className="label1 text-body">Owner Name</div>
              <div className="flex flex-col gap-x-sm">
                <Input
                  {...inputProp(`agent_name_en`, `English Name of Owner`)}
                />
                <Input
                  {...inputProp(`agent_name_zh`, `Chinese Name of Owner`)}
                />
              </div>
            </>
          )}
        </SectionTemplate>
        {(Number(data["capacity"]) === 3 || Number(data["capacity"]) === 4) && (
          <SectionTemplate t={t} title="Authorisation Letter">
            {stepTwoOptions?.data?.authLetterList?.length ? (
              Object.entries(stepTwoOptions?.data?.authLetterList).map(
                ([key, value]) => {
                  return (
                    <React.Fragment key={key}>
                      <UploadFilePreview
                        data={value}
                        t={t}
                        handleDeleteFile={false}
                        disabled={true}
                        // downloadApiProps={{
                        //   downloadApi: "property/document/download",
                        //   appendData: {
                        //     id: pid,
                        //     doc_id: value?.id,
                        //   },
                        // }}
                      />
                    </React.Fragment>
                  );
                }
              )
            ) : (
              <FileDropzone
                type={UPLOAD_DOC_TYPE.AUTHORISATION_LETTER}
                {...inputProp("auth_letter")}
              />
            )}
          </SectionTemplate>
        )}
        <SectionTemplate t={t} title="This proposal is made on the grounds">
          <Radio
            {...inputProp("ground_id")}
            options={stepTwoOptions?.data?.groundList || []}
            col={true}
          />
          {Number(data["ground_id"]) === 4 && (
            <div className="flex flex-col gap-x-sm">
              {Array.from({ length: 4 }, (_, i) => (
                <Input
                  {...inputProp(
                    `address_line_${i + 1}`,
                    `Address Line ${i + 1}`
                  )}
                  key={`address_line_${i + 1}`}
                />
              ))}
            </div>
          )}
        </SectionTemplate>
      </div>
    </>
  );
};

export default StepTwo;
